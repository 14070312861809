<script>
import AdditionalActions from "@/components/actions/AdditionalActions.vue";

export default {
  name: "UserAdditionalActions",
  components: { AdditionalActions },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["change"],
  methods: {
    async archive(onSuccess, onError) {
      const resp = await this.$api.users.archiveUser(this.user.id);
      if (resp.success) {
        onSuccess();
        this.$emit("change");
      } else {
        onError("The user can’t be archived due to reasons:", resp.data?.detail);
      }
    },
    async unarchive() {
      await this.$api.users.unarchiveUser(this.user.id);
      this.$emit("change");
    },
  },
};
</script>

<template>
  <additional-actions
    :archive-confirmation-text="'Are you sure want to send ' + user.name + '’s account  to archive?'"
    :is-archived="user.archived !== null"
    :unarchive-confirmation-text="'Are you sure want to return ' + user.name + '’s account from archive?'"
    archive-confirmation-title="Archive user"
    unarchive-confirmation-title="Unarchive user"
    @archive="archive"
    @unarchive="unarchive"
  ></additional-actions>
</template>

<style lang="scss" scoped></style>
