const TOKEN_KEY = "tms_token";

export class TokenStorage {
  static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static saveToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  static removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  }
}
