<script>
export default {
  name: "CheckMark",
  props: {
    value: Boolean,
    muted: Boolean,
  },
};
</script>

<template>
  <v-icon v-if="value" :color="muted ? 'grey-darken-2' : 'success'">mdi-check</v-icon>
  <span v-else>&mdash;</span>
</template>

<style lang="scss" scoped></style>
