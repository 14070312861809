import { ApiClient } from "@/api/client";
import { UsersApi } from "@/api/users";
import { GeoApi } from "@/api/geo";
import { AuthApi } from "@/api/auth";
import { TripsApi } from "@/api/trips";
import { TrucksApi } from "@/api/trucks";
import { ChatApi } from "@/api/chat";
import { DispatchersApi } from "@/api/dispatchers";
import { ServiceApi } from "@/api/service";
import { CompanyApi } from "@/api/company";
import { OrdersApi } from "@/api/orders";
import { FilesApi } from "@/api/files";
import { UtilsApi } from "@/api/utils";

class API {
  constructor() {
    this.client = new ApiClient();
    this.auth = new AuthApi(this.client);
    this.users = new UsersApi(this.client);
    this.dispatchers = new DispatchersApi(this.client);
    this.geo = new GeoApi(this.client);
    this.trips = new TripsApi(this.client);
    this.trucks = new TrucksApi(this.client);
    this.chat = new ChatApi(this.client);
    this.service = new ServiceApi(this.client);
    this.company = new CompanyApi(this.client);
    this.orders = new OrdersApi(this.client);
    this.files = new FilesApi(this.client);
    this.utils = new UtilsApi(this.client);
  }

  setRouter(router) {
    this.client.setRouter(router);
  }
}

export default {
  install(app) {
    app.config.globalProperties.$api = new API();
  },
};
