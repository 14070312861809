<script>
import { FacilityType } from "@/data/facility";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import LocationMixin from "@/mixins/location.mixin";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "DriverLocationBlock",
  mixins: [BaseView, GoogleMapsMixin, LocationMixin],
  props: {
    facility: null,
  },
  emits: ["update:state"],
  data() {
    return {
      reversedLocation: null,
    };
  },
  computed: {
    isStandard() {
      return this.facility.type === FacilityType.standard;
    },
    isPoint() {
      return this.facility.type === FacilityType.point;
    },
    isToBeDiscussed() {
      return this.facility.type === FacilityType.toBeDiscussed;
    },
    isManual() {
      return this.facility.type === FacilityType.manual;
    },
    hasPreciseCoordinates() {
      return (
        this.facility.precise_coordinates?.length === 2 &&
        this.facility.precise_coordinates[0] &&
        this.facility.precise_coordinates[1]
      );
    },
    reverseGeoCode() {
      return this.debounce(async () => {
        const response = await this.$api.geo.reverse(
          this.facility.precise_coordinates[0],
          this.facility.precise_coordinates[1],
        );
        if (response.success) {
          this.reversedLocation = response.data;
          this.$emit("update:state", this.reversedLocation.address.state);
        }
      }, 500);
    },
  },
  watch: {
    async "facility.precise_coordinates"() {
      if (!this.isPoint) return;
      this.reversedLocation = null;
      if (this.facility.precise_coordinates[0] && this.facility.precise_coordinates[1]) {
        this.reverseGeoCode();
      }
    },
    facility: {
      handler() {
        if (this.isManual && this.facility.address) {
          const location = this.expandLocation(this.facility.address);
          if (location) {
            this.$emit("update:state", location.state);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div class="bg-blue-grey-lighten-5 pa-5 rounded">
    <div class="caption text-grey-darken-1 mb-4">Location for driver:</div>
    <div v-if="isStandard || isManual">
      <div v-if="facility.address && facility.address_line" class="d-flex align-center">
        <div class="text-grey-darken-3 font-size-16">{{ facility.address_line }}, {{ facility.address }}</div>
        <a :href="getPlaceUrlByAddress(facility.address_line + ',' + facility.address)" class="ml-2" target="_blank">
          <v-icon color="success" icon="mdi-map-marker-radius-outline"></v-icon>
        </a>
        <div v-if="facility.$locationMatchError" class="text-error font-size-14 d-flex align-center">
          <v-icon class="mx-2" icon="mdi-alert-circle-outline" size="16"></v-icon>
          States must be the same
        </div>
      </div>
      <div v-else>-</div>
    </div>
    <div v-if="isPoint">
      <template v-if="hasPreciseCoordinates">
        <div class="d-flex align-center mb-2">
          <div class="text-grey-darken-3 font-size-16">{{ facility.precise_coordinates.join(", ") }}</div>
          <a :href="getPlaceUrl(facility.precise_coordinates.toReversed())" class="ml-2" target="_blank">
            <v-icon color="success" icon="mdi-map-marker-radius-outline"></v-icon>
          </a>
        </div>
        <div v-if="reversedLocation" class="font-size-14 text-grey-darken-1 d-flex align-center">
          {{ reversedLocation.full_address }}
          <div v-if="facility.$locationMatchError" class="text-error font-size-14 d-flex align-center">
            <v-icon class="mx-2" icon="mdi-alert-circle-outline" size="16"></v-icon>
            States must be the same
          </div>
        </div>
      </template>
      <div v-else>-</div>
    </div>
    <div v-if="isToBeDiscussed">
      <div v-if="facility.address" class="d-flex align-center">
        <div class="text-grey-darken-3 font-size-16">{{ facility.address }}</div>
        <a :href="getPlaceUrlByAddress(facility.address)" class="ml-2" target="_blank">
          <v-icon color="success" icon="mdi-map-marker-radius-outline"></v-icon>
        </a>
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
