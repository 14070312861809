const PopupMixin = {
  methods: {
    openPopup({ url, title, x, y, w, h }) {
      const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      width=${w},
      height=${h},
      top=${y},
      left=${x}
      `,
      );

      if (window.focus) newWindow.focus();
    },
    popupHeight() {
      return localStorage.getItem(PopupDimensionsKey.height) || 400;
    },
    popupWidth() {
      return localStorage.getItem(PopupDimensionsKey.width) || 600;
    },
    popupX() {
      return localStorage.getItem(PopupDimensionsKey.x) || 100;
    },
    popupY() {
      return localStorage.getItem(PopupDimensionsKey.y) || 100;
    },
  },
};

export const PopupDimensionsKey = {
  x: "popup-position-x",
  y: "popup-position-y",
  height: "popup-height",
  width: "popup-width",
};

export default PopupMixin;
