<script>
import { mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import { Role } from "@/const";

export default {
  name: "SelectDispatchers",
  mixins: [BaseView],
  props: {
    modelValue: Number,
    multiple: Boolean,
    displaySelected: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    ...mapState(["dispatchers", "dispatcher"]),
    operationsFirst() {
      const operations = [],
        others = [];

      for (const dispatcher of this.dispatchers) {
        if (dispatcher.id === this.dispatcher.id) continue; // skip myself

        if (dispatcher.roles.includes(Role.Operation)) {
          operations.push(dispatcher);
        } else {
          others.push(dispatcher);
        }
      }

      return operations.concat(others);
    },
  },
};
</script>

<template>
  <v-combobox
    v-model="model"
    :clearable="true"
    :items="operationsFirst"
    :label="$t('dispatchers.dispatchers')"
    :multiple="multiple"
    :return-object="false"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    variant="outlined"
  >
    <template #selection="{ item, index }">
      <span v-if="displaySelected && index < displaySelected">{{ item.raw.name }},</span>
      <span v-if="displaySelected && index === displaySelected">...</span>
      <span v-if="!displaySelected">{{ item.raw.name }},</span>
    </template>
  </v-combobox>
</template>

<style lang="scss" scoped>
.select-block .v-input__control {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
</style>
