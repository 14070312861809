export class User {
  constructor({
    id,
    name,
    email,
    phone,
    language,
    contact_person_id,
    emergency_contact_phone,
    is_driver,
    driver_status,
    trucks,
    mobile_user_has_password,
    mobile_user_last_login,
    created_time,
    companies,
    driver_id,
    address_country,
    address_state,
    address_city,
    owner_status,
    is_owner,
    emergency_contact_full_name,
    emergency_contact_relation,
    birthday,
    can_change_status,
  }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.language = language;
    this.contact_person_id = contact_person_id;
    this.emergency_contact_phone = emergency_contact_phone;
    this.is_driver = is_driver;
    this.is_owner = is_owner;
    this.driver_status = driver_status;
    this.trucks = trucks;
    this.mobile_user_has_password = mobile_user_has_password;
    this.mobile_user_last_login = mobile_user_last_login;
    this.created_time = created_time;
    this.companies = companies;
    this.driver_id = driver_id;
    this.address_country = address_country;
    this.address_state = address_state;
    this.address_city = address_city;
    this.owner_status = owner_status;
    this.emergency_contact_full_name = emergency_contact_full_name;
    this.emergency_contact_relation = emergency_contact_relation;
    this.birthday = birthday;
    this.user = can_change_status;
  }

  static empty() {
    return new User({
      id: 0,
      companies: [],
      coordinators: [],
      driver_id: null,
      address_country: "US",
      address_state: "",
      address_city: "",
      driver_status: "",
      owner_status: "",
    });
  }
}
