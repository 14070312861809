<script>
import { mapState } from "vuex";

export default {
  name: "BlankLayout",
  computed: {
    ...mapState(["snackbar"]),
  },
};
</script>

<template>
  <v-app id="app" theme="light">
    <router-view />
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" multi-line top>
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>
