<script>
import { mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import OmniTable from "@/components/Table.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import CustomersSearchForm from "@/views/companies/customers/CustomersSearchForm.vue";

export default {
  name: "CustomersPage",
  components: { CustomersSearchForm, PageHeader, LinkButton, OmniTable },
  mixins: [BaseView],
  data() {
    return {
      perm: "customers",
      headers: [
        { title: this.$t("general.name"), key: "name" },
        { title: this.$t("company.fmcsa"), key: "number" },
        { title: this.$t("company.physical_address"), key: "address" },
        { title: this.$t("company.billing_address"), key: "billing_address" },
        {
          title: "",
          key: "actions",
          align: "end",
          width: 58,
          sortable: false,
        },
      ],
      filter: {},
      data: { items: [] },
      loading: false,
    };
  },
  computed: {
    ...mapState(["mobile"]),
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  async created() {
    this.setTitle(this.$t("company.customers"));
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const filter = { ...this.filter, ...this.params };

      try {
        const resp = await this.$api.company.findCustomers(filter);
        this.data = resp.data;
      } catch (e) {
        console.error("Error load customers list: ", e);
      }

      this.loading = false;
    },
    async search(params) {
      this.params.page = 1;
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header :header="$t('company.customers')">
      <template #suffix>
        <link-button :to="{ name: 'customer-edit', params: { id: 'new' } }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <customers-search-form @search="search" />

    <omni-table
      id="customers"
      :footer-props="{ 'items-per-page-options': pagination.items_per_page }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total || 0"
      :items-per-page="params.page_size"
      :show-all-option="false"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.name`]="{ item }">
        <div>{{ item.name }}</div>
        <div class="caption">{{ item.branch_name }}</div>
      </template>
      <template #[`item.number`]="{ item }"> {{ item.fmcsa_type }} {{ item.fmcsa_number }}</template>
      <template #[`item.address`]="{ item }">
        {{ item.address_line_1 }}, {{ item.address_city }}, {{ item.address_zip_code }},
        {{ item.address_state }}
      </template>
      <template #[`item.billing_address`]="{ item }">
        {{ item.billing_address_line_1 }}, {{ item.billing_address_city }}, {{ item.billing_address_zip_code }},
        {{ item.billing_address_state }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="canEdit"
          color="primary"
          size="small"
          variant="flat"
          @click="$router.push({ name: 'customer-edit', params: { id: item.id } })"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </omni-table>
  </v-container>
</template>
