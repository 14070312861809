<script>
import { mapMutations } from "vuex";
import { OrderStopType } from "@/data/order";
import PickupInfoDialog from "@/views/trips/view/dialogs/PickupInfoDialog.vue";
import DeliveryInfoDialog from "@/views/trips/view/dialogs/DeliveryInfoDialog.vue";

export default {
  name: "ShowInfoAction",
  components: { DeliveryInfoDialog, PickupInfoDialog },
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["finish"],
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    isPickUp() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDelivery() {
      return this.stop.type === OrderStopType.delivery;
    },
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    showInfo() {
      this.showDialog = true;
    },
  },
};
</script>

<template>
  <v-btn
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="outlined"
    @click="showInfo"
  >
    View info
  </v-btn>

  <pickup-info-dialog
    v-if="isPickUp"
    v-model="showDialog"
    :route="route"
    :stop="stop"
    :trip="trip"
    preload
    readonly
    title="Pick up information"
  ></pickup-info-dialog>

  <delivery-info-dialog
    v-if="isDelivery"
    v-model="showDialog"
    :route="route"
    :stop="stop"
    :trip="trip"
    preload
    readonly
    title="Delivery information"
  ></delivery-info-dialog>
</template>

<style lang="scss" scoped></style>
