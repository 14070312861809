<script>
export default {
  name: "StepperItem",
  props: {
    number: Number,
    first: Boolean,
    last: Boolean,
    active: Boolean,
    complete: Boolean,
  },
};
</script>

<template>
  <div class="d-flex">
    <div class="align-stretch d-flex flex-column stepper-container">
      <div :class="{ hidden: first }" class="stepper-line start"></div>
      <div :class="{ 'bg-primary': active || complete }" class="stepper-point bg-grey text-white">
        <template v-if="!active && !complete">{{ number }}</template>
        <v-icon v-if="complete" class="font-weight-500" icon="mdi-check" size="10"></v-icon>
        <div v-if="active" class="white-point"></div>
      </div>
      <div v-if="!last" class="stepper-line flex-1"></div>
    </div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stepper-container {
  width: 28px;

  .stepper-line {
    width: 8px;
    border-right: 1px solid #e0e0e0;

    &.start {
      height: 20px;
    }

    &.hidden {
      border-right: none;
    }
  }

  .stepper-point {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;

    .white-point {
      width: 7px;
      height: 7px;
      background-color: #ffffff;
      border-radius: 50%;
      display: inline-block;
    }
  }
}
</style>
