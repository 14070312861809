import { mapMutations } from "vuex";

const NotFoundMixin = {
  data() {
    return {
      entityNotFound: false,
    };
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    notFound() {
      this.entityNotFound = true;
    },
    validateEntityResponse(response) {
      if (!response.success) {
        if (response.status === 404) {
          this.notFound();
        } else {
          this.showSnackbarError(response.error.message);
        }
        return false;
      }
      return true;
    },
  },
};

export default NotFoundMixin;
