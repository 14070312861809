export const FacilityType = {
  standard: "standard",
  point: "point",
  toBeDiscussed: "tbd",
  manual: "manual",
};

export const FacilityTypeMap = [
  {
    id: FacilityType.standard,
    name: "Standard",
  },
  {
    id: FacilityType.point,
    name: "Point",
  },
  {
    id: FacilityType.toBeDiscussed,
    name: "TBD",
  },
  {
    id: FacilityType.manual,
    name: "Manual",
  },
];
