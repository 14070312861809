<script>
import AttachTruckDialog from "@/views/trips/edit/dialogs/AttachTruckDialog.vue";
import DetachTruckDialog from "@/views/trips/edit/dialogs/DetachTruckDialog.vue";
import DispatchDialog from "@/views/trips/edit/dialogs/DispatchDialog.vue";
import OmniDialog from "@/components/Dialog.vue";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "RouteBlock",
  components: { AlertMessage, OmniDialog, DispatchDialog, DetachTruckDialog, AttachTruckDialog },
  props: {
    canEdit: Boolean,
    modelValue: { type: Object, required: true },
  },
  emits: ["update:modelValue", "change", "attach", "removeTruck", "removeRoute"],
  data() {
    return {
      attachDialog: false,
      dispatchDialog: false,
      removeTruckDialog: false,
      removeRouteDialog: false,
    };
  },
  computed: {
    route: {
      get() {
        return this.modelValue;
      },
      set(route) {
        this.$emit("update:modelValue", route);
        this.$emit("change", route);
      },
    },
    canDispatch() {
      const freights = this.route.travel_orders
        .map((travelOrder) => travelOrder.order.freights)
        .flat()
        .map((freight) => freight.id);
      const routeFreights = this.route.route_stops.flatMap((s) => s.freights);
      return (
        this.route.travel_orders.every((o) => o.rate !== undefined && o.rate !== "") &&
        freights.every((f) => routeFreights.includes(f))
      );
    },
    needRedispatch() {
      return this.route.redispatch === "needed";
    },
  },
  methods: {
    attach(payload) {
      this.attachDialog = false;
      this.$emit("attach", payload);
    },
    removeTruck() {
      this.removeTruckDialog = false;
      this.$emit("removeTruck", this.route.truck);
    },
  },
};
</script>
<template>
  <div class="trip-block" data-qa="route-block">
    <div class="trip-block__inner bg-white pa-4 rounded-lg">
      <div class="trip-block__header d-flex align-center justify-space-between mb-3">
        <span class="text-grey-darken-2 font-weight-medium">Route</span>
        <v-menu v-if="canEdit" offset-y>
          <template #activator="{ props }">
            <v-btn elevation="0" icon="mdi-dots-horizontal" size="x-small" v-bind="props" />
          </template>
          <v-list>
            <v-list-item class="cursor-pointer" @click="removeRouteDialog = true">
              <v-list-item-title>Delete route</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-if="route?.truck" class="d-flex align-center justify-space-between bg-grey-lighten-4 pa-4 rounded">
        <span class="font-weight-medium text-primary" data-qa="truck-number">Truck #{{ route.truck.number }}</span>
      </div>
      <v-btn
        v-if="route?.truck"
        :disabled="!canDispatch"
        block
        class="btn text-uppercase mt-3"
        color="primary"
        variant="flat"
        @click="dispatchDialog = true"
      >
        <template v-if="!route.$isDispatched">Dispatch</template>
        <template v-else>Dispatch settings</template>
      </v-btn>
      <v-btn
        v-else
        block
        class="text-uppercase font-weight-600"
        color="primary"
        variant="outlined"
        @click="attachDialog = true"
      >
        Attach truck/Carrier
      </v-btn>
      <alert-message v-if="needRedispatch" class="mt-3"> Please update information for driver</alert-message>
    </div>

    <omni-dialog v-model="attachDialog" title="Attach truck" width="850">
      <attach-truck-dialog @attach="attach" />
    </omni-dialog>

    <omni-dialog
      v-model="removeTruckDialog"
      :title="'Remove truck #' + route.truck?.number"
      primary-action-label="Remove"
      width="360"
      @primary-action:click="removeTruck"
    >
      <detach-truck-dialog :truck="route.truck" />
    </omni-dialog>

    <dispatch-dialog v-model="dispatchDialog" v-model:route="route" />

    <omni-dialog
      v-model="removeRouteDialog"
      primary-action-label="Remove"
      title="Remove route"
      width="360"
      @primary-action:click="$emit('removeRoute')"
    >
      <div class="text-grey-darken-1 py-4">Are you sure want to remove from trip?</div>
    </omni-dialog>
  </div>
</template>

<style scoped>
.trip-block__inner {
  min-height: 180px;
}
</style>
