import { UsStates } from "@/data/states";

const ValidationMixin = {
  methods: {
    requiredValidator: (v) =>
      (v !== "" && v !== null && v !== undefined && v.toString().trim() !== "") || "Field is required",
    emailValidator: (email) => {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || "Email is not valid";
    },
    digitsValidator: (v) => (v.toString() && !isNaN(v) && !isNaN(parseFloat(v))) || "Only digits allowed",
    selectValidator: (v) => (v && v.length > 0) || "Required at least one value",
    phoneValidator: (v) => {
      const len = (v || "").replaceAll(" ", "").length;
      if (len === 0) return true;
      return len === 11 || "Phone number is incorrect";
    },
    validateVIN: (v) => (v && v.length === 17) || "VIN code is incorrect",
    stateValidator: (v) => {
      if (v && v.length !== 2) return "Two letters state name";
      return (v && UsStates.includes(v.toUpperCase())) || "State not found";
    },
    dateInputValidator: (v) => {
      let re = /^[0-1]\d\/[0-3]\d\/\d{4}$/;
      return re.test(v) || "Date is not valid";
    },
    yearLengthValidator: (v) => !v || (v && v.length === 4) || "Incorrect year",
    positiveNumber: (v) => (v && !!Number(v) && v > 0) || "Only positive numbers allowed",
    fileRequiredValidator: (v) => {
      return (v[0] && !!v[0].name) || "File is required";
    },
  },
};

export default ValidationMixin;
