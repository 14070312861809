<script>
export default {
  name: "UserFormCoordinator",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      assignedCoordinator: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    async modelValue() {
      if (this.modelValue.is_coordinator && this.assignedCoordinator === null) {
        const resp = await this.$api.users.findOwners({ coordinator: this.modelValue.id });
        if (resp.success) {
          this.assignedCoordinator = resp.data.total > 0 ? resp.data.items[0] : false;
        }
      }
    },
  },
};
</script>

<template>
  <div class="pa-2 mb-6">
    <div class="subheading font-weight-medium mb-3">Coordinator information</div>
    <div class="d-flex align-center flex-wrap pa-5 bg-white rounded personal-block">
      <v-menu :disabled="!assignedCoordinator" location="top start" offset="4" open-on-hover>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-switch
              id="is_coordinator"
              v-model="user.is_coordinator"
              :disabled="!!(user.id && assignedCoordinator)"
              :inset="true"
              :label="$t('drivers.is-coordinator')"
              class="ma-0"
              color="primary"
              density="compact"
              hide-details
            />
          </div>
        </template>
        <v-card>
          <v-card-text v-if="assignedCoordinator">
            User assigned as coordinator for owner
            <a :href="`/users/` + assignedCoordinator.id + `/view`" target="_blank">{{ assignedCoordinator.name }}</a>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
