<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";

export default {
  name: "SidebarUserBlock",
  components: { LanguageTag, PhoneNumber },
  props: {
    isContactPerson: Boolean,
    fullAccess: Boolean,
    label: String,
    user: Object,
  },
};
</script>

<template>
  <div v-if="user" class="user-info__block d-flex align-center pt-5 pb-5">
    <div class="user-info__block-content">
      <div class="text-grey-darken-3 mb-2">{{ label }}</div>
      <div class="d-flex">
        <span v-if="isContactPerson" class="currency-icon mr-3"></span>
        <router-link
          :to="{ name: 'user-view', params: { id: user.id } }"
          class="text-primary text-decoration-none font-weight-500"
        >
          {{ user.name }}
        </router-link>
        <div v-if="fullAccess" class="text-green ml-3">
          <v-icon size="small">mdi-check</v-icon>
        </div>
      </div>
      <div class="d-flex align-center mt-2 mb-2">
        <phone-number :number="user.phone" class="text-primary mr-2" is-callable />
        <language-tag :language="user.language"></language-tag>
      </div>
      <div v-if="user.email" class="caption mb-2">
        <a v-clipboard="user.email" class="font-size-12 text-primary text-decoration-none">
          {{ user.email }}
        </a>
      </div>
      <div class="d-flex mt-2">
        <div v-if="user.is_owner" class="d-inline-block mb-2 bg-grey-lighten-4 rounded-lg px-2 mr-2">
          <span class="text-blue-lighten-2 caption text-uppercase text-green-lighten-2">Owner</span>
        </div>
        <div v-if="user.is_coordinator" class="d-inline-block mb-2 bg-grey-lighten-4 rounded-lg px-2">
          <span class="text-blue-lighten-2 caption text-uppercase text-blue-lighten-2">Coordinator</span>
        </div>
      </div>
    </div>
  </div>
</template>
