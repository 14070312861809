<script>
import OmniDialog from "@/components/Dialog.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import OmniSelect from "@/components/controls/Select.vue";
import { mapMutations } from "vuex";
import ValidationMixin from "@/mixins/validation.mixin";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { OrderStopType } from "@/data/order";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";

export default {
  name: "FinishAction",
  components: { OmniSelect, TimePicker, DatePicker, OmniDialog },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin, TrucksMixin],
  inheritAttrs: false,
  props: {
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["finish"],
  data() {
    return {
      showDialog: false,
      loading: false,
      finishData: {},
    };
  },
  computed: {
    stopType() {
      switch (this.stop.type) {
        case OrderStopType.pickup:
          return "Pick up";
        case OrderStopType.delivery:
          return "Delivery";
        default:
          return "";
      }
    },
  },
  watch: {
    async showDialog(value) {
      if (value) {
        this.finishData.timezone = this.stop.facility.timezone;
        const date = this.$dayjs().tz(this.finishData.timezone);
        this.finishData.date = date.format("YYYY-MM-DD");
        this.finishData.time = date.format("HH:mm");
      }
    },
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    showFinishDialog() {
      this.showDialog = true;
    },
    async finish() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const response = await this.$api.trips.checkOutRouteStop(this.trip.id, this.stop.id, {
        check_out_time: this.dateToUTC(this.finishData.date + " " + this.finishData.time, this.finishData.timezone),
        truck_status: this.finishData.truckStatus,
      });
      if (response.success) {
        this.$emit("finish");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error checking out at stop");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-btn
    block
    class="text-uppercase font-weight-600"
    color="primary"
    v-bind="$attrs"
    variant="flat"
    @click="showFinishDialog"
  >
    Finish
  </v-btn>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :title="'Finish on the ' + stopType"
    primary-action-label="Finish"
    size="large"
    @primary-action:click="finish"
  >
    <v-form ref="form" class="py-6">
      <div class="pb-6 text-grey-darken-1">
        You are about to check out
        <router-link
          :to="{ name: 'truck-view', params: { id: route.truck_id } }"
          class="text-primary text-decoration-none"
          target="_blank"
        >
          Truck #{{ route.$truck.number }}
        </router-link>
        at the {{ stopType }}:
      </div>
      <div class="rounded bg-grey-lighten-4 pa-2 mb-8">
        <router-link
          :to="{ name: 'facilities-edit', params: { id: stop.facility?.id } }"
          class="text-primary font-weight-500 text-decoration-none d-block"
          target="_blank"
        >
          {{ stop.facility?.name }}
        </router-link>
        <div class="mt-1 font-size-12 text-grey-darken-2">
          {{ stop.facility?.address_line }}, {{ stop.facility?.address }}
        </div>
      </div>
      <v-row>
        <v-col cols="4">
          <omni-select
            v-model="finishData.timezone"
            :items="timezonesShortList"
            :rules="[requiredValidator]"
            data-qa="timezone"
            label="Time zone"
            required
          />
        </v-col>
        <v-col cols="5">
          <date-picker v-model="finishData.date" label="Date" required></date-picker>
        </v-col>
        <v-col cols="3">
          <time-picker v-model="finishData.time" label="Time" required></time-picker>
        </v-col>
      </v-row>
      <div class="font-size-16 font-weight-500 mt-8 mb-6">
        <router-link
          :to="{ name: 'truck-view', params: { id: route.truck_id } }"
          class="text-primary text-decoration-none"
          target="_blank"
        >
          Truck #{{ route.$truck.number }}
        </router-link>
        status becomes available
      </div>
      <v-row>
        <v-col>
          <omni-select
            v-model="finishData.truckStatus"
            :items="truckStatuses.filter((s) => s.forTrip).map((s) => ({ ...s, disabled: false }))"
            :rules="[requiredValidator]"
            label="Truck status"
            required
            data-qa="truck-status-select-finish"
          ></omni-select>
        </v-col>
        <v-col v-if="finishData.truckStatus === truckStatusAvailable" class="d-flex align-center font-size-16">
          <div class="text-grey-darken-1 mr-1">Location:</div>
          <div class="text-grey-darken-3 font-weight-500">{{ route.$truck.available_location?.full_address }}</div>
        </v-col>
      </v-row>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
