import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { getMessaging, isSupported, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCopyVwYrcrkm6sF-3zDaderL1yfckE5Os",
  authDomain: "supportix.firebaseapp.com",
  projectId: "supportix",
  storageBucket: "supportix.appspot.com",
  messagingSenderId: "517547823438",
  appId: "1:517547823438:web:7579f11baac3edfcd20e1f",
  measurementId: "G-DR4WVJL7S9",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
isSupported().then((isSupported) => {
  if (isSupported) {
    initializeAnalytics(app);

    onMessage(messaging, function (payload) {
      console.log("[Firebase] Receiving foreground message", payload);

      const notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: "/favicon.ico",
        tag: payload.data.chat,
      });
      notification.onclick = function () {
        notification.close();
        if (payload.data.account_url) {
          window.location.href = payload.data.account_url + "/chats/" + payload.data.chat;
        }
      };
    });
  }
});

export default messaging;
