<script>
export default {
  name: "DispatchConfirmationStatus",
  props: {
    isConfirmed: Boolean,
  },
  computed: {
    classes() {
      return this.isConfirmed ? "bg-grey-lighten-3 text-grey-darken-3" : "bg-red-lighten-2 text-white";
    },
    icon() {
      return this.isConfirmed ? "mdi-check" : null;
    },
    status() {
      return this.isConfirmed ? "Confirmed" : "Not confirmed";
    },
  },
};
</script>

<template>
  <div :class="classes" class="d-flex align-center status-label rounded-lg caption px-2" v-bind="$props">
    <v-icon v-if="icon" :icon="icon" class="mr-1" size="12"></v-icon>
    {{ status }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  height: 16px;
  line-height: 16px !important;
  font-size: 11px !important;
}
</style>
