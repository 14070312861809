<script>
import OmniDialog from "@/components/Dialog.vue";

export default {
  name: "ArchiveDispatcherAction",
  components: { OmniDialog },
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  emits: ["change"],
  data() {
    return {
      showArchiveConfirmationDialog: false,
      showUnarchiveConfirmationDialog: false,
    };
  },
  methods: {
    showArchiveConfirmation() {
      this.showArchiveConfirmationDialog = true;
    },
    hideArchiveConfirmation() {
      this.showArchiveConfirmationDialog = false;
    },
    showUnarchiveConfirmation() {
      this.showUnarchiveConfirmationDialog = true;
    },
    hideUnarchiveConfirmation() {
      this.showUnarchiveConfirmationDialog = false;
    },
    async archive() {
      const resp = await this.$api.dispatchers.archiveDispatcher(this.dispatcher.id);
      if (resp.success) {
        this.hideArchiveConfirmation();
        this.$emit("change");
      }
    },
    async unarchive() {
      const resp = await this.$api.dispatchers.unarchiveDispatcher(this.dispatcher.id);
      if (resp.success) {
        this.hideUnarchiveConfirmation();
        this.$emit("change");
      }
    },
  },
};
</script>

<template>
  <v-btn
    v-if="!dispatcher.archived"
    color="primary"
    icon="mdi-archive-arrow-down-outline"
    size="x-small"
    variant="text"
    @click="showArchiveConfirmation"
  ></v-btn>
  <v-btn
    v-else
    color="primary"
    icon="mdi-archive-arrow-up-outline"
    size="x-small"
    variant="text"
    @click="showUnarchiveConfirmation"
  ></v-btn>

  <omni-dialog
    v-model="showArchiveConfirmationDialog"
    persistent
    primary-action-label="Archive"
    size="small"
    title="Archive dispatcher"
    @primary-action:click="archive"
  >
    <div class="my-4">Are you sure want to send {{ dispatcher.name }}’s account to archive?</div>
  </omni-dialog>
  <omni-dialog
    v-model="showUnarchiveConfirmationDialog"
    persistent
    primary-action-label="Unarchive"
    size="small"
    title="Unarchive dispatcher"
    @primary-action:click="unarchive"
  >
    <div class="my-4">Are you sure want to return {{ dispatcher.name }}’s account from archive?</div>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
