<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableFilterRow",
  inject: ["tableFilterSymbol"],
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (!this.tableFilterSymbol) {
      throw new Error("TableFilterRow must be a child of TableFilter");
    }
  },
});
</script>

<template>
  <v-row>
    <v-col :style="{ flex: '0 0 180px' }" class="d-flex align-center">
      <v-icon :icon="icon" class="mr-2" />
      {{ label }}
    </v-col>
    <slot></slot>
  </v-row>
  <v-divider v-if="divider" class="mt-4 mb-4" />
</template>

<style lang="scss" scoped></style>
