<script>
import { mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import TruckSelect from "@/components/inputs/TruckSelect.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";

export default {
  name: "TrucksHistorySearch",
  components: { TableFilterRow, TableFilter, TruckSelect, TimePicker, DatePicker },
  mixins: [BaseView, DateTimeMixin],
  emits: ["search"],
  data() {
    return {
      params: {},
      today: this.$dayjs().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
  mounted() {
    if (!this.params.dateFrom) {
      this.params.dateFrom = this.$dayjs().format();
    }
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      const params = {
        number: this.params.number,
      };
      if (this.params.dateFrom) {
        const dt = this.params.dateFrom + "T" + (this.params.timeFrom || "00:00");
        params.dateFrom = this.dateToUTC(dt, this.dispatcher.timezone).format(this.systemDateTimeFormat);
      }
      if (this.params.dateTo) {
        const dt = this.params.dateTo + "T" + (this.params.timeTo || "00:00");
        params.dateTo = this.dateToUTC(dt, this.dispatcher.timezone).format(this.systemDateTimeFormat);
      }
      this.$emit("search", params);
    },
  },
};
</script>

<template>
  <table-filter :expandable="false" @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col cols="2">
          <truck-select v-model="params.number" @change="search" />
        </v-col>
        <v-col cols="2">
          <date-picker v-model="params.dateFrom" :label="'Date from'" @search="search" />
        </v-col>
        <v-col cols="2">
          <time-picker v-model="params.timeFrom" :label="'Time from'" @search="search" />
        </v-col>
        <v-col cols="2">
          <date-picker v-model="params.dateTo" :label="'Date to'" @search="search" />
        </v-col>
        <v-col cols="2">
          <time-picker v-model="params.timeTo" :label="'Time to'" @search="search" />
        </v-col>
      </table-filter-row>
    </template>
  </table-filter>
</template>
