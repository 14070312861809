<script>
import { defineComponent } from "vue";
import ExpandButton from "@/components/controls/ExpandButton.vue";

const TABLE_FILTER_SYMBOL = Symbol.for("TableFilter");

export default defineComponent({
  name: "TableFilter",
  components: { ExpandButton },
  provide: {
    tableFilterSymbol: TABLE_FILTER_SYMBOL,
  },
  props: {
    expandable: {
      type: Boolean,
      default: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clear", "apply"],
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    clearFilters() {
      this.$emit("clear");
    },
    applyFilters() {
      this.$emit("apply");
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
});
</script>

<template>
  <v-card class="mb-4 rounded-0 table-filter" color="white" variant="flat">
    <v-card-text class="pa-0">
      <!-- https://bugs.chromium.org/p/chromium/issues/detail?id=1441807 autocomplete-->
      <form autocomplete="off" class="pt-4 pb-4 pr-6 pl-6" role="presentation">
        <v-row>
          <v-col>
            <slot name="header"></slot>
          </v-col>
          <v-col v-if="!noActions" :style="{ flex: '0 0 160px' }" class="d-flex">
            <v-btn
              id="search--reset-btn"
              class="mr-2 font-weight-600 table-filter--btn table-filter--btn--clear"
              color="primary"
              variant="outlined"
              @click="clearFilters"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              id="search--apply-btn"
              class="table-filter--btn mr-2 text-uppercase font-weight-600"
              color="primary"
              variant="flat"
              @click="applyFilters"
            >
              Apply
            </v-btn>
            <expand-button v-if="expandable" id="search--additional-btn" :expanded="expanded" @click="toggleExpand" />
          </v-col>
        </v-row>
        <div v-if="expanded">
          <v-divider class="mt-4 mb-4" />
          <slot></slot>
        </div>
      </form>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.table-filter {
  &--btn {
    height: 44px !important;

    &--clear {
      width: 44px !important;
      min-width: 44px !important;
    }
  }
}
</style>
