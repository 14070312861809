<script>
import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import YesNoSelect from "@/components/inputs/YesNoSelect.vue";
import DoorTypeSelect from "@/views/trucks/edit/DoorTypeSelect.vue";
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";
import UserSelect from "@/views/users/components/UserSelect.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import { defineComponent } from "vue";
import DriverSelect from "@/views/trucks/edit/DriverSelect.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import { mapMutations } from "vuex";
import OmniSelect from "@/components/controls/Select.vue";
import YearField from "@/components/inputs/YearField.vue";

export default defineComponent({
  name: "TruckEditForm",
  components: {
    YearField,
    OmniSelect,
    OmniTextarea,
    DriverSelect,
    UserSelect,
    DispatcherSelect,
    DoorTypeSelect,
    YesNoSelect,
    DatePicker,
  },
  mixins: [BaseView, UsersMixin, TrucksMixin, MasksMixin, ValidationMixin],
  props: {
    truck: Object,
  },
  data() {
    return {
      item: {},
      trailer: {
        owner_id: null,
        equipment: [],
        additional_equipment: [],
      },
      gross_weight: null,
      owner: {},
      drivers: [],
      numberError: "",
    };
  },
  computed: {
    contactPersons() {
      return [
        { id: "o", name: this.$t("drivers.owner") },
        { id: "d", name: this.$t("drivers.driver") },
      ];
    },
    availableTruckTypes() {
      if (!this.item.id) {
        return this.truckTypes;
      }
      return this.truckTypes.map((type) => {
        return {
          ...type,
          disabled: this.hasTrailer
            ? !this.trucksWithTrailer.includes(type.id)
            : this.trucksWithTrailer.includes(type.id),
        };
      });
    },
    firstDrivers() {
      return this.drivers.filter((d) => d.id !== this.item.driver2_id);
    },
    secondDrivers() {
      return this.drivers.filter((d) => d.id !== this.item.driver_id);
    },
    hasTrailer() {
      return this.trucksWithTrailer.includes(this.item.type);
    },
  },
  watch: {
    async truck(truck) {
      await this.setTruck(truck);
    },
    async "item.owner_id"(owner_id) {
      if (this.truck.owner_id !== owner_id) {
        this.item.driver_id = null;
        this.item.driver2_id = null;
        this.item.contact_person = null;

        await this.loadData();
      }
    },
    "trailer.height"() {
      this.trailer.min_height = Math.min(this.trailer.height || 0, this.trailer.door_height || 0);
    },
    "trailer.door_height"() {
      this.trailer.min_height = Math.min(this.trailer.height || 0, this.trailer.door_height || 0);
    },
    "trailer.width"() {
      this.trailer.min_width = Math.min(this.trailer.width || 0, this.trailer.door_width || 0);
    },
    "trailer.door_width"() {
      this.trailer.min_width = Math.min(this.trailer.width || 0, this.trailer.door_width || 0);
    },
  },
  async mounted() {
    await this.setTruck(this.truck);
  },
  methods: {
    ...mapMutations(["showSnackbarError", "showSnackbarSuccess"]),
    async loadData() {
      if (!this.item.owner_id) return;

      // load owner
      await this.loadOwner();

      // load drivers
      try {
        const resp = await this.$api.users.findDrivers({
          owner: this.item.owner_id,
          page_size: 1000,
        });
        this.drivers = resp.data.items.map((driver) => {
          driver.disabled = this.isDriverDisabled(driver);
          return driver;
        });
      } catch (error) {
        console.error("Error load drivers list: ", error);
      }
    },
    async loadOwner() {
      const resp = await this.$api.users.findUserById(this.item.owner_id);
      if (resp.success) {
        this.owner = resp.data;
      }
    },
    cancel() {
      this.$router.push(this.item.id ? { name: "truck-view", params: { id: this.item.id } } : { name: "trucks" });
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }

      const item = { ...this.item };

      if (item.gross_weight === 0 || item.gross_weight === "" || item.gross_weight === undefined) {
        item.gross_weight = null;
      }

      item.trailer = null;
      if (this.hasTrailer) {
        item.trailer = this.trailer;
        item.trailer.type = item.type;
      }
      delete item.available_date;

      const resp = await this.$api.trucks.upsertTruck(item);
      if (resp.success) {
        this.showSnackbarSuccess(this.$t("general.saved"));
        this.$router.push({ name: "truck-view", params: { id: resp.data.id } });
      } else {
        this.showSnackbarError(resp.data.detail);
      }
    },
    async validateNumber(e) {
      this.numberError = "";

      const resp = await this.$api.trucks.findTrucks({ number: e.target.value });
      if (resp.success && resp.data.items.filter((truck) => truck.id !== this.item.id).length > 0) {
        this.numberError = "Truck number already exists";
      }
    },
    isDriverDisabled(driver) {
      const allowedByStatus = [this.driverStatusOnSilentHold, this.driverStatusActive].includes(driver.driver_status);
      const allowedByTruck = !(driver.driver_truck && driver.driver_truck.id !== this.item.id);

      return !(allowedByStatus && allowedByTruck);
    },
    async setTruck(truck) {
      const hasTrailer = this.trucksWithTrailer.includes(truck.type);
      if (hasTrailer) {
        this.trailer = truck.trailer;
      }
      this.item = { ...truck };

      await this.loadData();
    },
  },
});
</script>

<template>
  <v-form ref="form" autocomplete="off1" lazy-validation>
    <div class="truck-edit__header d-flex align-center justify-space-between mb-3 flex-wrap">
      <div class="subheading font-weight-medium">Create truck</div>
      <div class="edit-truck__right d-flex align-center">
        <div class="d-flex align-center mr-6">
          <span class="text-grey-darken-1 mr-2">Created date:</span>
          <span class="text-grey-darken-3">{{ $filters.date(item.created_time || $dayjs()) }}</span>
        </div>

        <dispatcher-select v-model="item.hired_by_id" class="created-select" label="Hired by" />
      </div>
    </div>
    <v-card variant="flat">
      <div class="pa-5 bg-white rounded create-block mb-6">
        <div class="caption text-grey-darken-1 mb-4">General information</div>
        <v-row>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <v-text-field
              id="number"
              v-model="item.number"
              :error-messages="numberError"
              :label="$t('general.number')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              max-length="10"
              variant="outlined"
              @blur="validateNumber"
            />
          </v-col>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <omni-select
              id="type"
              v-model="item.type"
              :items="availableTruckTypes"
              :label="$t('general.type')"
              :rules="[requiredValidator]"
              class="required"
              item-title="name"
              item-value="id"
            />
          </v-col>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <omni-select
              id="status"
              v-model="item.status"
              :items="truckStatuses"
              :label="$t('general.status')"
              :rules="[requiredValidator]"
              class="required"
              item-title="name"
              item-value="id"
            />
          </v-col>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <user-select
              id="owner_id"
              v-model="item.owner_id"
              :label="$t('drivers.owner')"
              :rules="[requiredValidator]"
              owners
              required
            />
          </v-col>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <driver-select
              id="driver_id"
              v-model="item.driver_id"
              :items="firstDrivers"
              :label="$t('drivers.driver')"
              :truck="truck"
            ></driver-select>
          </v-col>
          <v-col lg="2" md="4" sm="6" xl="2" xs="12">
            <driver-select
              id="driver2_id"
              v-model="item.driver2_id"
              :items="secondDrivers"
              :label="$t('trucks.second-driver')"
              :truck="truck"
            ></driver-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <omni-textarea id="note" v-model="item.note" label="Truck note" rows="5"></omni-textarea>
          </v-col>
        </v-row>
      </div>

      <div class="pa-5 mb-6 bg-white rounded create-block">
        <div class="caption text-grey-darken-1 mb-4">Characteristics</div>
        <v-row>
          <v-col md="3" sm="6">
            <v-text-field
              id="make"
              v-model="item.make"
              :label="$t('trucks.make')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              variant="outlined"
            />
          </v-col>
          <v-col md="3" sm="6">
            <v-text-field
              id="model"
              v-model="item.model"
              :label="$t('trucks.model')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              variant="outlined"
            />
          </v-col>
          <v-col md="2" sm="6">
            <v-text-field
              id="gross_weight"
              v-model="item.gross_weight"
              :label="$t('trucks.gross-weight')"
              autocomplete="off1"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="lbs"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col md="4" sm="6">
            <v-text-field
              id="vin"
              v-model="item.vin"
              v-maska:[vinCodeMask]
              :rules="[requiredValidator, validateVIN]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              label="VIN code"
              variant="outlined"
              @keyup="transformUpperCase"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              id="licence_plate"
              v-model="item.licence_plate"
              :label="$t('trucks.licence-plate')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              max-length="10"
              variant="outlined"
            />
          </v-col>
          <v-col>
            <v-text-field
              id="licence_state"
              v-model="item.licence_state"
              :rules="[requiredValidator, stateValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              label="License state"
              max-length="2"
              variant="outlined"
            />
          </v-col>
          <v-col md="3" sm="6">
            <date-picker
              id="licence_expire"
              v-model="item.licence_expire"
              :label="$t('trucks.licence-expire')"
              :rules="[requiredValidator]"
              required
            />
          </v-col>
          <v-col md="3" sm="6">
            <year-field id="year" v-model="item.year" :rules="[requiredValidator]" class="required" />
          </v-col>
          <v-col>
            <v-text-field
              id="color"
              v-model="item.color"
              :label="$t('trucks.color')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              variant="outlined"
            />
          </v-col>
          <v-col data-qa="truck-signs">
            <yes-no-select id="signs" v-model="item.signs" :label="'Signs'" />
          </v-col>
        </v-row>
      </div>

      <div v-if="hasTrailer" class="pa-5 bg-white rounded create-block mb-6">
        <div class="caption text-grey-darken-1 mb-4">Trailer</div>
        <v-row>
          <v-col class="after-icon" lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_length"
              v-model="trailer.length"
              :label="$t('trucks.length')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col clas="after-icon" lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_width"
              v-model="trailer.width"
              :label="$t('trucks.width')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_height"
              v-model="trailer.height"
              :label="$t('trucks.height')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col lg="3" md="6" data-qa="trailer-equipment">
            <omni-select
              id="trailer_equipment"
              v-model="trailer.equipment"
              :items="equipment"
              :label="$t('trucks.equipment')"
              :multiple="true"
              item-title="name"
              item-value="id"
            />
          </v-col>
          <v-col lg="3" md="6" data-qa="trailer-additional-equipment">
            <omni-select
              id="trailer_additional_equipment"
              v-model="trailer.additional_equipment"
              :items="additionalEquipment"
              :label="$t('trucks.equipment-extra')"
              :multiple="true"
              item-title="name"
              item-value="id"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="4" xl="2">
            <year-field id="trailer_year" v-model="trailer.year" />
          </v-col>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_door_width"
              v-model="trailer.door_width"
              :label="$t('trucks.door-width')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_door_height"
              v-model="trailer.door_height"
              :label="$t('trucks.door-height')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col class="d-flex align-center" lg="6" md="12" xl="3">
            <door-type-select id="trailer_door_type" v-model="trailer.door_type" />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              id="trailer_payload"
              v-model="trailer.payload"
              :label="$t('trucks.payload')"
              :rules="[requiredValidator]"
              autocomplete="off1"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              v-model="trailer.min_width"
              :label="$t('trucks.min-width')"
              :readonly="true"
              autocomplete="off1"
              color="primary"
              density="compact"
              hide-details
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
          <v-col lg="2" md="4" xl="2">
            <v-text-field
              v-model="trailer.min_height"
              :label="$t('trucks.min-height')"
              :readonly="true"
              autocomplete="off1"
              color="primary"
              density="compact"
              hide-details
              suffix="inch"
              type="number"
              variant="outlined"
            />
          </v-col>
        </v-row>
      </div>

      <v-card-actions class="d-flex justify-end pa-5 bg-white truck-edit__buttons">
        <v-btn
          id="cancel-btn"
          class="pr-4 pl-4 mr-3 text-primary text-uppercase font-weight-600"
          height="36"
          variant="text"
          @click="cancel"
        >
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn
          id="save-btn"
          class="pr-6 pl-6 font-weight-600 text-uppercase"
          color="primary"
          height="36"
          variant="flat"
          @click="save"
        >
          {{ $t("general.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<style lang="scss" scoped>
.after-icon {
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -5px;
    content: "х";
    display: block;
    width: 8px;
    height: 25px;
    @media (max-width: 960px) {
      display: none;
    }
  }
}

.truck-notes {
  padding: 16px 12px;
  margin: 0;
  background: #fafafa;
  height: 100%;

  div {
    height: 100%;
  }

  textarea {
    resize: none;
  }
}

.create-block {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
}

.total-blocks {
  max-width: 190px;
  flex: 0 0 190px;
  padding: 8px;
}

.truck-edit__buttons {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  box-shadow: 0px -8px 20px -7px rgba(0, 0, 0, 0.1);
}

.vertical_collpsed {
  .truck-edit__buttons {
    width: 100%;
  }
}

.created-select {
  width: 240px;
  flex: 0 0 240px;
}

.v-card {
  background: transparent !important;
}
</style>
