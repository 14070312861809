<script>
import ChangeTruckStatusAction from "@/views/trucks/actions/ChangeTruckStatusAction.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import ReserveTruckAction from "@/views/trucks/actions/ReserveTruckAction.vue";
import TruckLocationInfo from "@/views/trucks/components/TruckLocationInfo.vue";
import TruckStatus from "@/components/status/TruckStatus.vue";
import { mapState } from "vuex";
import TruckContactInfo from "@/views/trucks/blocks/ContactInfo.vue";

export default {
  name: "TruckViewSidebar",
  components: {
    TruckContactInfo,
    TruckLocationInfo,
    ReserveTruckAction,
    ChangeTruckStatusAction,
    TruckStatus,
  },
  mixins: [TrucksMixin, DateTimeMixin],
  props: {
    modelValue: Boolean,
    truck: Object,
  },
  emits: ["update:modelValue", "update:truck"],
  computed: {
    ...mapState(["dispatcher"]),
    changeStatusDisabledMessage() {
      if (this.truck.archived) {
        return "The status cannot be changed when truck doesn't have a first driver";
      }
      if (this.truck.status === this.truckStatusInRoute) {
        return "The status cannot be changed when truck has active route";
      }
      return "The status cannot be changed";
    },
    isVisible: {
      set(value) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue || false;
      },
    },
  },
  methods: {
    close() {
      this.isVisible = false;
    },
    notifyTruckChange() {
      this.$emit("update:truck");
    },
  },
};
</script>

<template>
  <div class="flex-1 pa-6">
    <div class="text-grey-darken-3 mb-8 font-weight-medium text-subtitle-1">Information</div>
    <div class="mb-3">
      <div class="d-flex align-center flex-wrap mb-2">
        <span class="text-h6 font-weight-medium text--lighten-1 mr-4" data-qa="truck-number"
          >Truck: {{ truck.number }}</span
        >
        <truck-status :truck="truck"></truck-status>
      </div>
      <div class="text-grey-darken-1 font-weight-medium" data-qa="truck-type">
        {{ getTruckType(truck.type) }}
      </div>
      <div
        v-if="truck.archived"
        class="d-flex align-center px-3 py-2 bg-indigo-lighten-5 rounded text-grey-darken-3 mt-3"
      >
        <v-icon class="mr-2">mdi-archive-outline</v-icon>
        <div>Archived</div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-grey-lighten-4 rounded-lg pl-3 pr-3 pt-2 pb-2">
        <div class="mb-1 text-grey-darken-1 font-size-11 font-weight-black">Available location</div>
        <truck-location-info :location="truck.available_location" :show-tooltip="false" :time="truck.available_date" />
      </div>
      <div class="bg-grey-lighten-4 rounded-lg pl-3 pr-3 pt-2 pb-2 mt-3">
        <div class="mb-1 text-grey-darken-1 font-size-11 font-weight-black">Current location</div>
        <truck-location-info :location="truck.current_location" :time="truck.current_location_time" />
      </div>
    </div>

    <div class="truck-dop-blocks">
      <div v-if="truck.owner && ![truck.driver_id, truck.driver2_id].includes(truck.owner_id)" class="truck-dop-block">
        <truck-contact-info
          :contact-person="
            truck.driver && truck.driver.contact_person_id && truck.owner_id === truck.driver.contact_person_id
          "
          :label="$t('drivers.owner')"
          :user="truck.owner"
        />
      </div>
      <div v-if="truck.driver" class="truck-dop-block">
        <truck-contact-info
          :contact-person="truck.driver.contact_person_id && truck.driver_id === truck.driver.contact_person_id"
          :label="truck.driver.id === truck.owner.id ? $t('drivers.owner-driver') : $t('drivers.driver')"
          :user="truck.driver"
        />
      </div>
      <div v-if="truck.driver2" class="truck-dop-block">
        <truck-contact-info
          :contact-person="truck.driver2_id === truck.driver.contact_person_id"
          :label="truck.driver2.id === truck.owner.id ? $t('drivers.owner-driver') : $t('drivers.second-driver')"
          :user="truck.driver2"
        />
      </div>
      <div
        v-if="
          truck.driver &&
          truck.driver.contact_person_id &&
          ![truck.driver_id, truck.driver2_id, truck.owner_id].includes(truck.driver.contact_person_id)
        "
        class="truck-dop-block"
      >
        <truck-contact-info
          :contact-person="true"
          :label="$t('drivers.contact-person')"
          :user="truck.driver.contact_person"
        />
      </div>
      <div v-if="truck.coordinators" class="truck-dop-block">
        <truck-contact-info
          v-for="coordinator in truck.coordinators"
          :key="coordinator.user_id"
          :is-contact-person="coordinator.user_id === truck.driver?.contact_person_id"
          :show-status="false"
          :user="coordinator.user"
          label="Coordinator"
        />
      </div>
    </div>
  </div>

  <div class="truck-actions mt-8 pa-6">
    <v-tooltip v-if="truck.reserved_by_id && getReservationTime(truck) > -1" top>
      <template #activator="{ props }">
        <span v-bind="props">
          <reserve-truck-action :truck="truck" @success="notifyTruckChange">
            <v-btn class="font-weight-600 text-uppercase w-100" color="success" variant="flat">
              RESERVED, {{ getReservationTime(truck) }}m
            </v-btn>
          </reserve-truck-action>
        </span>
      </template>
      <span>
        Reserved by {{ getReservedBy(truck) }} until
        {{ dateTZ(truck.reserved_till, dispatcher.timezone) }}
      </span>
    </v-tooltip>

    <reserve-truck-action v-else :truck="truck" @success="notifyTruckChange">
      <v-btn
        :class="{ 'btn--disabled': truck.archived }"
        :disabled="truck.archived"
        class="text-uppercase font-weight-600 w-100"
        color="primary"
        variant="flat"
      >
        Reserve
      </v-btn>
    </reserve-truck-action>

    <change-truck-status-action :truck="truck" @success="notifyTruckChange">
      <v-btn
        :disabled="truck.archived || truck.status === 'r'"
        class="mt-3 text-uppercase font-weight-600 width-100"
        color="primary"
        variant="outlined"
      >
        CHANGE STATUS
      </v-btn>
      <template #disabled>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn class="mt-3 text-uppercase font-weight-600 width-100" color="grey" v-bind="props" variant="outlined">
              CHANGE STATUS
            </v-btn>
          </template>
          <span> {{ changeStatusDisabledMessage }} </span>
        </v-tooltip>
      </template>
    </change-truck-status-action>
  </div>
</template>

<style lang="scss" scoped>
.truck-dop-block {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
</style>
