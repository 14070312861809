<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "YesNoSelect",
  props: {
    modelValue: Boolean,
    label: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      items: [
        { value: true, title: "Yes" },
        { value: false, title: "No" },
      ],
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue || false;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<template>
  <v-select
    v-model="model"
    :items="items"
    :label="label"
    color="primary"
    density="compact"
    hide-details
    variant="outlined"
  />
</template>

<style scoped></style>
