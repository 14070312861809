<script>
export default {
  name: "OrderRefNumberInput",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      refNumber: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    addRefNumber() {
      if (this.refNumber?.trim().length > 0) {
        this.model.push(this.refNumber);
        this.refNumber = null;
      }
    },
    removeRefNumber(index) {
      this.model.splice(index, 1);
    },
    refNumbersValidator() {
      return this.model.length > 0 || "Field is required";
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="refNumber"
    :persistent-placeholder="modelValue.length > 0"
    :rules="[refNumbersValidator]"
    class="required"
    color="primary"
    density="compact"
    hide-details="auto"
    label="Ref numbers"
    variant="outlined"
    @blur="addRefNumber"
    @keyup.enter="addRefNumber"
  >
    <template #prepend-inner>
      <div class="d-flex flex-wrap row-gap-1 py-2">
        <v-chip
          v-for="(number, idx) in model"
          :key="number + idx"
          :closable="true"
          class="mr-1"
          close-icon="mdi-close"
          size="small"
          @click:close="removeRefNumber(idx)"
        >
          {{ number }}
        </v-chip>
      </div>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
:deep(.v-field) {
  display: flex;
  flex-wrap: wrap;
}
</style>
