<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  name: "ChatInfoUserInfo",
  components: { PhoneNumber },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isFinanciallyResponsible() {
      return this.user.is_driver && (!this.user.contact_person_id || this.user.contact_person_id === this.user.id);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div class="d-flex align-center mr-2 overflow-hidden">
        <router-link
          :to="{ name: 'user-view', params: { id: user.id } }"
          class="driver__name text-primary text-decoration-none font-weight-medium text-body-1"
          target="_blank"
        >
          {{ user.name }}
        </router-link>
        <span v-if="isFinanciallyResponsible" class="currency-icon ml-2"></span>
      </div>
    </div>

    <div class="user-contact mb-4">
      <div class="mb-2">
        <span v-clipboard="user.email" class="d-flex align-center text-decoration-none">
          <span class="user-contact__icon mr-3 d-flex align-center justify-center">
            <v-icon class="text-grey-darken-1" size="16">mdi-email-outline</v-icon>
          </span>
          <span class="text-grey-darken-1">{{ user.email }}</span>
        </span>
      </div>
      <div class="d-flex align-center">
        <a :href="`callto: ${user.phone}`" class="d-flex align-center text-decoration-none">
          <span class="user-contact__icon mr-3 d-flex align-center justify-center">
            <v-icon class="text-primary" size="16">mdi-phone-outline</v-icon>
          </span>
          <phone-number :number="user.phone" class="text-primary" />
        </a>

        <span class="caption pa-1 bg-grey-lighten-4 text-grey-darken-3 ml-3 rounded text-uppercase">
          {{ user.language }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.driver__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;
}

.user-contact__icon {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
}
</style>
