<script>
import BaseView from "@/views/BaseView.vue";
import CarrierEditForm from "./EditForm.vue";

export default {
  name: "CarrierEdit",
  components: { CarrierEditForm },
  mixins: [BaseView],
  data() {
    return {
      perm: "carriers",
      item: {},
      loading: false,
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  async created() {
    this.setTitle(this.$t("company.carrier"));
    await this.loadCarrier();
  },
  methods: {
    async loadCarrier() {
      if (this.$route.params.id === "new") return;

      this.loading = true;
      try {
        const resp = await this.$api.company.findCarrierById(this.$route.params.id);
        this.item = resp.data;
      } catch (e) {
        console.error("Error load carrier by id: ", e);
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-8 pb-10 pl-6 pr-6">
    <carrier-edit-form :carrier="item" @close="$router.push({ name: 'carriers' })" />
  </v-container>
</template>
