<script>
import { defineComponent } from "vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { VInput } from "vuetify/components";

export default defineComponent({
  name: "TimePicker",
  extends: VInput,
  mixins: [ValidationMixin],
  inheritAttrs: false,
  props: {
    id: String,
    modelValue: null,
    minuteStep: { type: Number, default: 5 },
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "search"],
  data() {
    return {
      model: "",
      defaultZeroValue: "00",
      hours: this.generateNumbStringArray(24),
      minutes: [],
      active: false,
    };
  },
  computed: {
    splitValue() {
      return this.modelValue ? this.modelValue.split(":") : [];
    },
    hourValue() {
      return this.splitValue[0] || this.defaultZeroValue;
    },
    minuteValue() {
      return this.splitValue[1] || this.defaultZeroValue;
    },
    preparedRules() {
      const rules = [];
      if (this.required) {
        rules.push(this.requiredValidator);
      }
      rules.push(...this.rules);

      return rules;
    },
    isComplete() {
      return /^[0-2][0-9]:[0-5][0-9]$/.test(this.model);
    },
  },
  watch: {
    modelValue() {
      this.model = this.modelValue;
      this.initMinutes();
    },
    model(value, oldValue) {
      if (value === null) {
        return this.$emit("update:modelValue", value);
      }
      if (this.validateTime(value)) {
        if (oldValue.length === 1 && value.length === 2) {
          this.model = value + ":";
        }
        if (this.isComplete) {
          this.$emit("update:modelValue", value);
        }
      } else {
        this.$nextTick(() => (this.model = oldValue));
      }
    },
  },
  created() {
    this.model = this.modelValue || "";
    this.initMinutes();
  },
  methods: {
    search() {
      this.$emit("search", this.modelValue);
    },
    initMinutes() {
      this.minutes = this.generateNumbStringArray(59, this.minuteStep);
      if (this.minutes.indexOf(this.minuteValue) === -1) {
        this.minutes.push(this.minuteValue);
        this.minutes.sort();
      }
    },
    generateNumbStringArray(length, step = 1) {
      return this.generateNumberStringSteppedArray(0, length, step);
    },
    generateNumberStringSteppedArray(start, stop, step) {
      return Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
        (start + i * step).toString().padStart(2, "0"),
      );
    },
    valueTemplate(hour, minute) {
      return `${hour}:${minute}`;
    },
    onChangeValue(value, type) {
      if (type === "hour") {
        this.$emit("update:modelValue", this.valueTemplate(value, this.minuteValue));
      } else if (type === "minute") {
        this.$emit("update:modelValue", this.valueTemplate(this.hourValue, value));
        this.active = false;
      }
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, /:/, ...minutes] : hours;
    },
    validateTime(value) {
      if (value.length > 5) {
        return false;
      }
      const mask = this.timeMask(value);
      for (let i = 0; i < value.length; i++) {
        if (!mask[i].test(value[i])) {
          return false;
        }
      }
      return true;
    },
  },
});
</script>

<template>
  <v-menu v-model="active" :close-on-content-click="false" :disabled="disabled" offset="10">
    <template #activator="{ props }">
      <v-text-field
        :id="id"
        v-model="model"
        :class="{ required: required }"
        :disabled="disabled"
        :label="label"
        :rules="preparedRules"
        class="_v-digital-time-picker"
        color="primary"
        density="compact"
        hide-details="auto"
        placeholder="00:00"
        v-bind="$attrs"
        variant="outlined"
        @keyup.enter="search"
      >
        <template #append-inner>
          <v-icon v-bind="props">mdi-clock-outline</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card class="d-flex align-center">
      <v-select
        :items="hours"
        :value="hourValue"
        class="_v-digital-time-picker__select"
        color="primary"
        hide-details
        rounded
        variant="plain"
        @update:model-value="onChangeValue($event, 'hour')"
      />
      <span class="_v-digital-time-picker__comma"> : </span>
      <v-select
        :items="minutes"
        :value="minuteValue"
        class="_v-digital-time-picker__select"
        color="primary"
        hide-details
        rounded
        variant="plain"
        @update:model-value="onChangeValue($event, 'minute')"
      />
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped>
$font-size: 20px !default;
._v-digital-time-picker {
  &__select {
    height: 60px;
    width: 100px;

    :deep(.v-input__control) {
      .v-field__append-inner,
      .v-label {
        display: none;
      }

      .v-field__input {
        padding-top: 16px;
        justify-content: center;

        input {
          text-align: center;
          font-size: 20px;
        }

        .v-select__selection {
          font-size: 20px;
        }
      }
    }
  }

  &__comma {
    font-size: $font-size;
    margin: 0 auto;
  }
}
</style>
