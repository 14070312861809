<script>
import ValidationMixin from "@/mixins/validation.mixin";
import { MasksMixin } from "@/mixins/masks.mixin";

export default {
  name: "CompanyNumber",
  mixins: [ValidationMixin, MasksMixin],
  props: {
    modelValue: String,
    type: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      data: "",
      error: false,
    };
  },
  computed: {
    cleanValue() {
      return this.clearValue(this.data);
    },
    documentNumberMask() {
      if (this.type === "ein") return this.einMask;
      return this.ssnMask;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.data = this.format(this.modelValue);
      }
    },
  },
  created() {
    if (this.modelValue) {
      this.data = this.format(this.modelValue);
    }
  },
  methods: {
    clearValue(value) {
      return value.replaceAll("-", "");
    },
    format(value) {
      this.error = false;

      value = this.clearValue(value);
      if (this.type === "ein") {
        const match10 = value.match(/^(\d{2})(\d{8})$/);
        if (match10) {
          value = match10[1] + "-" + match10[2];
        }
      } else if (this.type === "ssn") {
        const match9 = value.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match9) {
          value = match9[1] + "-" + match9[2] + "-" + match9[3];
        }
      } else {
        this.error = true;
      }

      return value;
    },
    emit() {
      this.data = this.format(this.data);
      this.$emit("update:modelValue", this.cleanValue);
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="data"
    v-maska:[documentNumberMask]
    :error="error"
    :rules="[requiredValidator]"
    class="required"
    color="primary"
    density="compact"
    hide-details="auto"
    label="Document number"
    required
    variant="outlined"
    @blur="emit"
    @change="emit"
  />
</template>
