<script>
import { OrderStatus, OrderStatusMap } from "@/data/order";

export default {
  name: "OrderStatus",
  props: {
    order: { type: Object, required: true },
  },
  computed: {
    classes() {
      switch (this.order.status) {
        case OrderStatus.planned:
          return "bg-indigo-lighten-2 text-white";
        case OrderStatus.unassigned:
          return "bg-green-lighten-1 text-white";
        case OrderStatus.inProgress:
          return "bg-amber-lighten-2 text-grey-darken-3";
        case OrderStatus.completed:
        case OrderStatus.tonu:
          return "bg-blue-lighten-2 text-white";
        case OrderStatus.waitingTonu:
        case OrderStatus.noTonu:
        case OrderStatus.cancelled:
          return "bg-red-lighten-2 text-white";
        default:
          return "bg-grey-lighten-4 text-grey-darken-3";
      }
    },
    label() {
      return OrderStatusMap.find((s) => s.id === this.order.status)?.name || "Unknown";
    },
  },
};
</script>

<template>
  <div :class="classes" class="status-label rounded-lg pr-2 pl-2">
    {{ label }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
  font-size: 11px;
}
</style>
