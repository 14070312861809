<script>
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "YearField",
  mixins: [MasksMixin, ValidationMixin],
  props: {
    id: String,
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: null,
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-text-field
    :id="id"
    v-model="model"
    v-maska:[yearMask]
    :label="label || 'Year'"
    :rules="[...rules, yearLengthValidator]"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    type="number"
    validate-on="blur"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
