<script>
import UsersMixin from "@/views/users/UsersMixin.vue";
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import UserSelect from "@/views/users/components/UserSelect.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import CitizenshipSelect from "@/components/inputs/CitizenshipSelect.vue";
import OmniSelect from "@/components/controls/Select.vue";
import DispatcherSelect from "@/components/inputs/DispatcherSelect.vue";

const searchParamsKey = "trucks";

export default {
  name: "TrucksListSearchForm",
  components: {
    DispatcherSelect,
    OmniSelect,
    CitizenshipSelect,
    TableFilterRow,
    TableFilter,
    DatePicker,
    SearchField,
    UserSelect,
  },
  mixins: [BaseView, UsersMixin, TrucksMixin],
  emits: ["search"],
  data() {
    return {
      params: {},
      showAdditionalFilters: false,
    };
  },
  computed: {
    statuses() {
      return this.truckStatuses.map((status) => {
        status.disabled = false;
        return status;
      });
    },
  },
  created() {
    this.params = this.getSearchParams(searchParamsKey);
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      if (this.params.phone) {
        this.params.phone = this.params.phone.replaceAll(/\D/g, "").replaceAll(" ", "");
      }
      if (this.params.year && this.params.year === "") {
        delete this.params.year;
      }

      this.setSearchParams(searchParamsKey, this.params);
      this.$emit("search", this.params);
    },
  },
};
</script>

<template>
  <table-filter @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col md="2">
          <search-field v-model="params.number" :label="$t('general.number')" data-qa="number" @search="search" />
        </v-col>
        <v-col md="4">
          <v-select
            v-model="params.type"
            :clearable="true"
            :items="truckTypes"
            :label="$t('general.type')"
            :multiple="true"
            color="primary"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            variant="outlined"
            data-qa="truck-type"
            @blur="search"
            @change="search"
          />
        </v-col>
        <v-col md="2">
          <v-select
            v-model="params.status"
            :clearable="true"
            :items="statuses"
            :label="$t('general.status')"
            color="primary"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            variant="outlined"
            data-qa="truck-status"
            @change="search"
          />
        </v-col>
        <v-col>
          <search-field
            v-model="params.phone"
            :label="$t('general.phone')"
            mask="1 ### ### ####"
            data-qa="truck-phone"
            @search="search"
          />
        </v-col>
      </table-filter-row>
    </template>
    <table-filter-row :label="$t('trucks.details')" icon="mdi-truck-outline">
      <v-col>
        <search-field v-model="params.vin" :label="$t('trucks.vin')" data-qa="truck-vin" @search="search" />
      </v-col>
      <v-col>
        <dispatcher-select
          v-model="params.reserved_by"
          :label="$t('trucks.reserved')"
          data-qa="truck-reserved-by"
          @change="search"
        />
      </v-col>
      <v-col>
        <dispatcher-select
          v-model="params.hired_by"
          :label="$t('trucks.hired')"
          data-qa="truck-hired-by"
          @change="search"
        />
      </v-col>
      <v-col :style="{ flex: '0 0 100px' }">
        <v-checkbox
          v-model="params.team"
          :label="$t('trucks.team')"
          color="primary"
          density="compact"
          hide-details
          data-qa="truck-team"
        />
      </v-col>
      <v-col :style="{ flex: '0 0 100px' }">
        <v-checkbox
          v-model="params.signs"
          :label="$t('trucks.signs')"
          color="primary"
          density="compact"
          hide-details
          data-qa="truck-signs"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :label="$t('trucks.registration-info')" icon="mdi-badge-account-horizontal-outline">
      <v-col>
        <search-field
          v-model="params.licence_plate"
          :label="$t('trucks.license-plate')"
          data-qa="truck-license-plate"
          @search="search"
        />
      </v-col>
      <v-col>
        <search-field
          v-model="params.licence_state"
          :label="$t('trucks.license-state')"
          data-qa="truck-license-state"
          @search="search"
        />
      </v-col>
      <v-col>
        <date-picker
          v-model="params.licence_expiration_date"
          :label="$t('trucks.license-expiration')"
          data-qa="truck-license-expiration-to"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :label="$t('trucks.trailer-details')" icon="mdi-truck-trailer">
      <v-col class="dimension-part">
        <search-field
          v-model="params.length"
          :label="$t('trucks.length')"
          type="number"
          data-qa="truck-length"
          @search="search"
        />
      </v-col>
      <v-col class="dimension-part">
        <search-field
          v-model="params.width"
          :label="$t('trucks.width')"
          type="number"
          data-qa="truck-width"
          @search="search"
        />
      </v-col>
      <v-col>
        <search-field
          v-model="params.height"
          :label="$t('trucks.height')"
          type="number"
          data-qa="truck-height"
          @search="search"
        />
      </v-col>
      <v-col>
        <search-field
          v-model="params.year"
          label="Year from"
          type="number"
          data-qa="truck-year-from"
          @search="search"
        />
      </v-col>
      <v-col>
        <search-field
          v-model="params.payload"
          :label="$t('trucks.payload')"
          type="number"
          data-qa="truck-payload"
          @search="search"
        />
      </v-col>
      <v-col>
        <omni-select
          v-model="params.equipment"
          :clearable="true"
          :items="equipment"
          :label="$t('trucks.equipment')"
          :multiple="true"
          item-title="name"
          item-value="id"
          data-qa="truck-equipment"
          @change="search"
        />
      </v-col>
      <v-col>
        <omni-select
          v-model="params.additional_equipment"
          :clearable="true"
          :items="additionalEquipment"
          :label="$t('trucks.equipment-extra')"
          :multiple="true"
          item-title="name"
          item-value="id"
          data-qa="truck-additional-equipment"
          @change="search"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :divider="false" :label="$t('trucks.owner-driver')" icon="mdi-account-multiple-outline">
      <v-col>
        <user-select v-model="params.owner" :label="$t('users.owner')" owners data-qa="truck-owner" @change="search" />
      </v-col>
      <v-col>
        <user-select
          v-model="params.driver"
          :label="$t('users.driver')"
          data-qa="truck-driver"
          drivers
          @change="search"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="params.driver_certificate"
          :clearable="true"
          :items="driverCertificates"
          :label="$t('drivers.certificates')"
          color="primary"
          density="compact"
          hide-details
          item-title="name"
          item-value="id"
          variant="outlined"
          data-qa="truck-driver-certificates"
          @change="search"
        />
      </v-col>
      <v-col>
        <citizenship-select v-model="params.driver_citizenship" :clearable="true" @change="search" />
      </v-col>
      <v-col>
        <v-select
          v-model="params.willing_cross_border"
          :clearable="true"
          :items="driverWillingsToCrossBorder"
          :label="$t('drivers.willing-cross-border')"
          color="primary"
          density="compact"
          hide-details
          item-title="name"
          item-value="id"
          variant="outlined"
          data-qa="truck-willing-cross-border"
          @change="search"
        />
      </v-col>
    </table-filter-row>
  </table-filter>
</template>

<style lang="scss" scoped>
.dimension-part {
  position: relative;

  &:after {
    content: "х";
    position: absolute;
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
  }
}
</style>
