import { BaseApi } from "@/api/base";

export class DispatchersApi extends BaseApi {
  findDispatchers(options = {}) {
    return this.client.get("dispatchers", options);
  }

  findDispatcherById(dispatcherId) {
    return this.client.get("dispatchers/" + dispatcherId);
  }

  upsertDispatcher(dispatcher) {
    return this.client.save("dispatchers", dispatcher);
  }

  removeDispatcher(dispatcherId) {
    return this.client.delete("dispatchers/" + dispatcherId);
  }

  getCurrentDispatcher() {
    return this.client.get("dispatchers/me");
  }

  archiveDispatcher(dispatcherId) {
    return this.client.post("/dispatchers/" + dispatcherId + "/archive");
  }

  unarchiveDispatcher(dispatcherId) {
    return this.client.post("/dispatchers/" + dispatcherId + "/unarchive");
  }
}
