<script>
import { mapState } from "vuex";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import TruckLocationTime from "./TruckLocationTime.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import LocationIconLink from "@/components/LocationIconLink.vue";

export default {
  name: "TruckLocationInfo",
  components: { LocationIconLink, TruckLocationTime },
  mixins: [DateTimeMixin, GoogleMapsMixin],
  props: {
    location: Object,
    time: String,
    showChangedBy: {
      type: Boolean,
      default: false,
    },
    showIndicator: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["dispatcher"]),
    copyLocation() {
      if (!this.location) return "";
      if (this.location.zip_code) return this.location.zip_code;
      return this.location.city + ", " + this.location.state;
    },
    fullAddress() {
      return this.location.full_address;
    },
    showChangedByTooltip() {
      return this.showChangedBy && this.location.changed_by;
    },
  },
};
</script>

<template>
  <div v-if="location">
    <v-menu v-if="showChangedByTooltip" :open-on-hover="true" location="top start" offset="4">
      <template #activator="{ props }">
        <div v-if="location.city" class="d-flex align-center">
          <span v-clipboard.uncolored="copyLocation" class="location" v-bind="props">
            {{ fullAddress }}
          </span>
          <location-icon-link :coordinates="location.coordinates"></location-icon-link>
        </div>
      </template>
      <v-card>
        <v-card-text>
          <div>
            Changed at:
            <span class="font-weight-500 text-primary">
              {{ dateTZ(location.changed_at, dispatcher.timezone) }}
            </span>
          </div>
          <div>
            Changed by: <span class="font-weight-500 text-primary">{{ location.changed_by }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <div v-if="!showChangedByTooltip && location.city" class="d-flex align-center">
      <span v-clipboard.uncolored="copyLocation" class="location">
        {{ fullAddress }}
      </span>
      <location-icon-link :coordinates="location.coordinates"></location-icon-link>
    </div>

    <truck-location-time
      :show-indicator="showIndicator"
      :show-tooltip="showTooltip"
      :time="time"
      :timezone="location.timezone"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.location {
  line-height: 17px;

  &:hover {
    color: $primary;
  }
}
</style>
