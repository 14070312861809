<script>
import { UtilsMixin } from "@/mixins/utils.mixin";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import { mapMutations } from "vuex";
import OmniDialog from "@/components/Dialog.vue";
import FacilityEditForm from "@/views/companies/facilities/EditForm.vue";

export default {
  name: "FacilitySelect",
  components: { FacilityEditForm, OmniDialog, OmniTextarea },
  mixins: [UtilsMixin, GoogleMapsMixin],
  props: {
    name: String,
    label: String,
    modelValue: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: Array,
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change", "update"],
  data() {
    return {
      model: null,
      addDialog: false,
      isLoading: false,
      search: null,
      items: [],
      facility: {},
      location: null,
    };
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      this.fetchData(val);
    },
    async modelValue() {
      this.model = this.modelValue;
      if (!this.items.some((u) => u.id === this.modelValue)) {
        this.preload(this.modelValue);
      }
    },
  },
  async mounted() {
    this.model = this.modelValue;
    this.preload(this.modelValue);
    this.fetchData(null);
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    openFacilityDialog() {
      this.addDialog = true;
    },
    closeFacilityDialog() {
      this.addDialog = false;
    },
    addFacility() {
      this.facility = {};
      this.openFacilityDialog();
    },
    changed(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
      const facility = this.items.find((f) => f.id === value);
      if (facility) {
        this.facility = facility;
        this.$emit("update", facility);
      }
    },
    fetchData(term) {
      const params = { search: term, page_size: 10 };

      this.isLoading = true;
      this.$api.company
        .findFacilities(params)
        .then((resp) => {
          const result = resp.data.items.map((f) => {
            f.full_address = f.address_line + " " + f.address;
            return f;
          });
          this.items = this.mergeById(this.items, result);
        })
        .catch((err) => {
          console.error("Error loading facilities", err);
        })
        .finally(() => (this.isLoading = false));
    },
    preload(id) {
      if (!id) return;

      this.isLoading = true;
      this.$api.company
        .findFacilityById(id)
        .then((resp) => {
          this.facility = resp.data;
          this.items = this.mergeById(this.items, [resp.data]);
        })
        .catch((err) => {
          console.error("Error loading facility data", err);
        })
        .finally(() => (this.isLoading = false));
    },
    async onSaved(facility) {
      this.closeFacilityDialog();

      this.facility = facility;
      this.items.push(facility);

      this.changed(facility.id);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center facility-block">
      <v-autocomplete
        v-model="model"
        v-model:search="search"
        :class="{ required: required }"
        :clearable="!readonly"
        :items="items"
        :label="label"
        :loading="isLoading"
        :name="name"
        :readonly="readonly"
        :disabled="readonly"
        :rules="rules"
        class="mb-0 width-100"
        color="primary"
        density="compact"
        hide-details="auto"
        hide-no-data
        item-title="name"
        item-value="id"
        placeholder="Type first letters for facility name"
        variant="outlined"
        @update:model-value="changed"
      >
        <template #item="{ props, item }">
          <v-list-item :subtitle="item?.raw?.address" :title="item?.raw?.name" v-bind="props">
            <template #append>
              <div class="font-size-12 text-grey-darken-3">
                {{ item?.raw?.type.charAt(0).toUpperCase() + item?.raw?.type.slice(1) }}
              </div>
            </template>
          </v-list-item>
        </template>
        <template #selection="{ item }"> {{ item?.raw?.name }}</template>
      </v-autocomplete>
      <v-btn
        v-if="editable"
        :disabled="readonly"
        class="ml-1"
        color="primary"
        height="44"
        icon="mdi-plus"
        variant="text"
        width="44"
        @click="addFacility"
      />
    </div>
    <div v-if="facility.id && !hideDetails" class="light rounded mt-3 pa-3 height-full">
      <div class="height-full__top pb-6">
        <div class="font-size-12 text-grey-darken-1 font-weight-500 mb-2">
          Type: {{ facility.type.charAt(0).toUpperCase() + facility.type.slice(1) }}
        </div>
        <div class="text-grey-darken-3 mb-2">{{ facility.address }}</div>
        <div class="d-flex align-center">
          <v-icon class="mr-2" color="green" small> mdi-map-marker-radius-outline</v-icon>
          <a
            :href="getPlaceUrl(facility.address_coordinates)"
            class="text-green caption text-decoration-underline"
            target="_blank"
          >
            Lat: {{ facility.address_coordinates[1] }} Lon: {{ facility.address_coordinates[0] }}
          </a>
        </div>
      </div>
      <div class="height-full__bottom">
        <omni-textarea v-model="facility.note" :readonly="true" label="Facility note" rows="4"></omni-textarea>
      </div>
    </div>

    <omni-dialog v-model="addDialog" size="large" title="Create facility">
      <facility-edit-form modal @change="onSaved" @close="closeFacilityDialog"></facility-edit-form>
    </omni-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog-btn {
  padding: 0 !important;
  margin-left: 12px;
  min-width: auto !important;
  height: auto !important;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
</style>
