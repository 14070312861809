<script>
export default {
  name: "InfoMessage",
};
</script>

<template>
  <div
    class="rounded bg-amber-lighten-5 text-grey-darken-3 border-warning pa-2 d-flex align-center font-size-12 font-weight-500"
  >
    <v-icon color="warning" icon="mdi-information-outline" size="20"></v-icon>
    <div class="ml-1">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
