<script>
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  directives: {
    "coordinates-mask": {
      mounted(el) {
        el.addEventListener("input", function (event) {
          const value = event.target.value;
          if (!/^-?(?:[0-9]+|[0-9]+\.|[0-9]+\.[0-9]+)?(?:,\s?-?(?:[0-9]+|[0-9]+\.|[0-9]+\.[0-9]+)?)?$/.test(value)) {
            event.target.value = value.slice(0, -1); // Remove the last entered character
          }
        });
      },
    },
  },
  mixins: [ValidationMixin],
  props: {
    modelValue: { type: Array },
    required: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      coordinatesString: "",
    };
  },
  computed: {
    coordinatesArray() {
      return this.coordinatesString.split(",").map((coord) => parseFloat(coord.trim()));
    },
  },
  mounted() {
    // Set initial value if provided through v-model
    if (Array.isArray(this.modelValue) && this.modelValue.length === 2) {
      this.coordinatesString = this.modelValue.join(",");
    }
  },
  methods: {
    updateCoordinates() {
      this.$emit("update:modelValue", this.coordinatesArray);
    },
    coordinatesRule(value) {
      if (!/^-?(\d+(\.\d+)?),\s?-?(\d+(\.\d+)?)$/.test(value)) {
        return "Please enter valid coordinates.";
      }
      const [latitude, longitude] = value.split(",").map((coord) => parseFloat(coord.trim()));
      if (isNaN(latitude) || isNaN(longitude)) {
        return "Please enter valid coordinates.";
      }
      if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
        return "Please enter valid coordinates.";
      }
      return true;
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="coordinatesString"
    v-coordinates-mask
    :class="{ required: required }"
    :rules="required ? [requiredValidator, coordinatesRule] : [coordinatesRule]"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    label="Coordinates (E.g: Lat:40.782720, Lon:-73.965604)"
    variant="outlined"
    @input="updateCoordinates"
  />
</template>

<style lang="scss" scoped></style>
