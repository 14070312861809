<script>
import { mapMutations, mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";

export default {
  name: "AccountProfile",
  mixins: [BaseView, DateTimeMixin],
  data() {
    return {
      perms: "account",
      timezone: null,
    };
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
  mounted() {
    this.timezone = this.dispatcher.timezone || null;
  },
  methods: {
    ...mapMutations(["showSnackbarSuccess"]),
    async save() {
      const dispatcher = { ...this.dispatcher, timezone: this.timezone };
      await this.$api.dispatchers.upsertDispatcher(dispatcher);
      this.showSnackbarSuccess(this.$t("general.saved"));
    },
  },
};
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title>Profile</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="timezone"
              :items="timezonesList"
              color="primary"
              density="compact"
              item-title="name"
              item-value="id"
              label="Timezone"
              variant="outlined"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" small @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
