<script>
import { customAlphabet } from "nanoid";
import { PERMISSIONS } from "@/const";

export default {
  data() {
    return {
      avatarColor(thread) {
        // in a chat modal window, while loading thread name is null.
        if (thread.resolved || !thread.name || thread.archived) {
          return "#BDBDBD";
        }

        const colors = ["#9575CD", "#EF5350", "#7986CB", "#4FC3F7", "#81C784", "#FFD54F", "#FF8A65"];
        return colors[
          thread.name
            .split("")
            .reduce((previousValue, currentLetter) => previousValue + currentLetter.charCodeAt(0), 0) % 7
        ];
      },
      roles: [
        { id: "ad", name: this.$t("dispatchers.role-admin") },
        { id: "tl", name: this.$t("dispatchers.role-team-lead") },
        { id: "bk", name: this.$t("dispatchers.role-booking") },
        { id: "hr", name: this.$t("dispatchers.role-hr") },
        { id: "op", name: this.$t("dispatchers.role-operation") },
        { id: "ac", name: this.$t("dispatchers.role-accounting") },
      ],
      params: { page: 1, page_size: 10 },
      pagination: { items_per_page: [10, 25, 50, 100] },
    };
  },
  methods: {
    getPreviewPath(file) {
      return "/api/v1/files/" + file.preview_path;
    },
    generateId() {
      const nanoid = customAlphabet("1234567890abcdef", 16);
      return nanoid();
    },
    hasStandardTimezone(value) {
      const dateChunks = value.split(" ");
      return ["EDT", "EST"].indexOf(dateChunks[1]) > -1;
    },
    getAssetUrl(path) {
      return "https://hugohunter.fra1.digitaloceanspaces.com/" + path;
    },
    getSearchParams(key) {
      let params = localStorage.getItem(key + "-params");
      if (!params) return {};
      return JSON.parse(params);
    },
    setSearchParams(key, params) {
      localStorage.setItem(key + "-params", JSON.stringify(params));
    },
    async loadDispatchers() {
      try {
        // page_size = 0 means we load all dispatchers
        const resp = await this.$api.dispatchers.findDispatchers({ page_size: 0 });
        this.$store.commit("setDispatchers", resp.data.items);
      } catch (err) {
        console.error("Error load account info", err);
      }
    },
    add() {
      this.edit({});
    },
    arrayToMap(arr) {
      const map = {};
      for (const item of arr) {
        map[item.id] = item.name;
      }
      return map;
    },
    hasAccess(section, action = "view") {
      if (!this.$store.state.dispatcher.roles) {
        return false;
      }

      const sectionRoles = (PERMISSIONS[section] || {})[action] || [];
      if (PERMISSIONS[section] === undefined) {
        console.error("No section " + section + "in permissions table");
      }
      if ((PERMISSIONS[section] || {})[action] === undefined) {
        console.error("No action " + action + "in permissions section " + section);
      }

      for (const role of this.$store.state.dispatcher.roles) {
        if (sectionRoles.includes(role)) return true;
      }
      return false;
    },
    hasRole(role) {
      if (typeof role == "string") role = [role];
      return role.some((r) => this.$store.state.dispatcher.roles.includes(r));
    },
    clearDigits(value) {
      return value.replace(/[^.\d]/g, "");
    },
    normalizePhone(value) {
      return this.clearDigits(value);
    },
    setTitle(title, title2) {
      this.$store.commit("setTitle", title);

      if (title2) {
        title = title + " – " + title2;
      }
      let account = "";
      if (this.$store.state.dispatcher.account_name) {
        account = " – " + this.$store.state.dispatcher.account_name;
      }
      document.title = title + account + " – TMS";
    },
    showChat(user) {
      this.$store.commit("setChatUser", user);
    },
    transformUpperCase(e) {
      e.target.value = e.target.value.toUpperCase();
    },
    transformLowerCase(e) {
      e.target.value = e.target.value.toLowerCase();
    },
    async updatePage(page) {
      this.params.page = page;
      await this.refresh();
    },
    async updatePageSize(size) {
      this.params.page_size = size;
      await this.refresh();
    },
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    scrollToError() {
      const element = document.querySelector(".v-input--error:first-of-type");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    uniqueArray(objects, uniqueBy, keepFirst = true) {
      return Array.from(
        objects
          .reduce((map, e) => {
            let key = uniqueBy
              .map((key) => [e[key], typeof e[key]])
              .flat()
              .join("-");
            if (keepFirst && map.has(key)) return map;
            return map.set(key, e);
          }, new Map())
          .values(),
      );
    },
  },
};
</script>
