<script>
export default {
  name: "FormHeader",
  inject: ["formSymbol"],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (!this.formSymbol) {
      throw new Error("FormHeader must be a child of OmniForm");
    }
  },
};
</script>

<template>
  <div class="d-flex align-center justify-space-between mb-3 flex-wrap">
    <div class="d-flex align-center">
      <div class="subheading font-weight-medium">{{ title }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
