<script>
import BaseView from "@/views/BaseView.vue";
import DriversList from "@/views/drivers/DriversList.vue";
import DriversSearchForm from "@/views/drivers/DriversSearchForm.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "DriversPage",
  components: { PageHeader, LinkButton, DriversSearchForm, DriversList },
  mixins: [BaseView],
  data() {
    return {
      perm: "drivers",
      searchParams: {},
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  created() {
    this.setTitle(this.$t("drivers.drivers"));
  },
  methods: {
    async search(params) {
      this.searchParams = { ...params };
      if (this.searchParams.phone) this.searchParams.phone = this.normalizePhone(this.searchParams.phone);
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 drivers-page">
    <page-header :header="$t('users.drivers')">
      <template #suffix>
        <link-button id="create-user-btn" :to="{ name: 'user-create' }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <drivers-search-form @search="search" />
    <drivers-list :can-edit="canEdit" :search-params="searchParams" show-owners />
  </v-container>
</template>
