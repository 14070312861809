<script>
import { TravelOrderStatusMap } from "@/data/trip";

export default {
  name: "TravelOrderStatusLabel",
  props: {
    travelOrder: Object,
  },
  computed: {
    status() {
      return TravelOrderStatusMap.find((s) => s.id === this.travelOrder.status)?.name;
    },
    classes() {
      return TravelOrderStatusMap.find((s) => s.id === this.travelOrder.status)?.forCancellation
        ? "text-white bg-red-lighten-2"
        : "text-grey-darken-1";
    },
  },
};
</script>

<template>
  <div :class="classes" class="status-label rounded-lg caption px-2" v-bind="$props">
    {{ status }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  height: 16px;
  line-height: 16px !important;
  font-size: 11px !important;
}
</style>
