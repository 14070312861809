<script>
import { PopupDimensionsKey } from "@/mixins/popup-mixin";

export default {
  name: "MapPosition",
  data() {
    return {
      positionX: window.screenX,
      positionY: window.screenY,
      height: window.innerHeight,
      width: window.innerWidth,
      listenInterval: null,
    };
  },
  watch: {
    positionX(value) {
      localStorage.setItem(PopupDimensionsKey.x, value);
    },
    positionY(value) {
      localStorage.setItem(PopupDimensionsKey.y, value);
    },
    height(value) {
      localStorage.setItem(PopupDimensionsKey.height, value);
    },
    width(value) {
      localStorage.setItem(PopupDimensionsKey.width, value);
    },
  },
  created() {
    this.listenWindowMoved();
    this.listenWindowResized();
  },
  unmounted() {
    clearInterval(this.listenInterval);
  },
  methods: {
    listenWindowMoved() {
      this.listenInterval = setInterval(() => {
        if (this.positionX !== window.screenX || this.positionY !== window.screenY) {
          this.positionX = window.screenX;
          this.positionY = window.screenY;
        }
      }, 500);
    },
    listenWindowResized() {
      window.addEventListener("resize", () => {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
      });
    },
  },
};
</script>

<template>
  <div class="position-relative fill-height">
    <div class="position-hint absolute-center text-center">
      <div class="mb-4">
        <v-icon color="primary" size="48">mdi-resize</v-icon>
      </div>
      <div>
        Place and resize current window to position and size you want map popup to be opened and close current window.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.position-hint {
  width: 300px;
}
</style>
