<script>
import DatePicker from "@/components/pickers/DatePicker.vue";
import CompanyNumber from "@/views/users/CompanyNumber.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import Countries from "@/data/countries";
import OmniSelect from "@/components/controls/Select.vue";
import AddressField from "@/components/AddressField.vue";

export default {
  name: "CompaniesField",
  components: { AddressField, DatePicker, CompanyNumber, OmniSelect },
  mixins: [MasksMixin, ValidationMixin],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      countries: Countries,
      documentTypes: [
        { id: "ein", name: "EIN" },
        { id: "ssn", name: "SSN" },
      ],
    };
  },
  computed: {
    companies() {
      return this.modelValue;
    },
  },
  methods: {
    removeCompany(index) {
      const companies = [...this.modelValue];
      companies.splice(index, 1);
      this.$emit("update:modelValue", companies);
    },
  },
};
</script>

<template>
  <div>
    <div v-for="(company, index) in modelValue" :key="company.id">
      <div class="pt-6">
        <div class="pa-5 bg-white rounded personal-block">
          <div class="d-flex align-center justify-space-between mb-3">
            <div class="caption text-grey-darken-1">{{ $t("companies.company") }}</div>
            <v-btn
              v-if="!company.id && index > 0"
              :id="'company_delete_' + index"
              class="text-red text--accent-2 pt-0 pb-0 pr-2 pl-2 caption font-weight-600 text-uppercase"
              color="white"
              height="20"
              size="small"
              @click="removeCompany(index)"
            >
              <v-icon class="text-red text--accent-2 mr-2" size="14">mdi-delete-outline</v-icon>
              {{ $t("companies.delete") }}
            </v-btn>
          </div>
          <v-row>
            <v-col lg="6">
              <v-text-field
                :id="'company_name_' + index"
                v-model="company.name"
                :label="$t('general.name')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                required
                variant="outlined"
                @change="company.changed = true"
              />
            </v-col>
            <v-col lg="2">
              <omni-select
                :id="'company_document_type_' + index"
                v-model="company.document_type"
                :items="documentTypes"
                :rules="[requiredValidator]"
                label="Document type"
                required
                @change="company.changed = true"
              />
            </v-col>
            <v-col lg="2">
              <company-number
                :id="'company_document_number_' + index"
                v-model="company.document_number"
                :type="company.document_type"
                required
                @change="company.changed = true"
              />
            </v-col>
            <v-col lg="2">
              <date-picker
                :id="'company_insurance_expire_date_' + index"
                v-model="company.insurance_expire_date"
                :label="$t('companies.expiration-date')"
                :min="$dayjs().format('YYYY-MM-DD')"
                required
                @change="company.changed = true"
              />
            </v-col>
          </v-row>
          <address-field v-model="companies[index]" :data-qa="'company_address_' + index" hide-location></address-field>
        </div>
      </div>
    </div>
  </div>
</template>
