<script>
import UsersMixin from "@/views/users/UsersMixin.vue";

export default {
  name: "UserStatus",
  mixins: [UsersMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      if (this.muted) {
        return "bg-grey-darken-2 text-white";
      }
      switch (this.status) {
        case this.driverStatusActive:
        case this.ownerStatusActive:
          return !this.light ? "bg-green-lighten-2 text-white" : "bg-white text-green-lighten-2";
        case this.driverStatusNotActive:
          return "bg-grey-lighten-4 text-grey-darken-3";
        case this.driverStatusOnHold:
        case this.driverStatusOnSilentHold:
          return !this.light ? "bg-red-lighten-2 text-white" : "bg-white text-red-lighten-2";
        default:
          return "bg-grey-lighten-4 text-grey-darken-3";
      }
    },
    isNonOwner() {
      return this.status === this.ownerStatusNONOwner;
    },
  },
};
</script>

<template>
  <div v-if="!isNonOwner" :class="classes" class="status-label rounded-lg caption px-2" v-bind="$props">
    {{ $t("drivers.status-" + status) }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  height: 16px;
  line-height: 16px !important;
  font-size: 11px !important;
}
</style>
