<script>
import UserCard from "@/views/chats/UserCard.vue";
import ChatUserTrucks from "@/views/chats/user-trucks/UserTrucks.vue";
import UserStatus from "@/components/status/UserStatus.vue";

export default {
  name: "ChatInfoOwnerPanel",
  components: { UserStatus, ChatUserTrucks, UserCard },
  props: {
    user: { type: Object, required: true },
  },
};
</script>

<template>
  <div v-if="user.is_owner" class="panel-info__block rounded" data-qa="owner-info-block">
    <div
      class="panel-info__block-header d-flex justify-space-between align-center bg-green-lighten-2 pt-2 pb-2 pl-5 pr-5 rounded-b-0 rounded"
    >
      <span class="font-size-12 font-weight-600 text-uppercase text-white">{{ $t("drivers.owner") }}</span>
      <user-status :status="user.owner_status" light />
    </div>
    <div class="panel-info__block-content pa-3">
      <chat-user-trucks :user="user" />

      <user-card
        v-for="(coordinator, index) in user.coordinators"
        :key="index"
        :full-access="coordinator.full_access"
        :role="$t('drivers.coordinator')"
        :user="coordinator.user"
        role-color="text-blue"
      />
    </div>
  </div>
</template>

<style scoped>
.panel-info__block {
  margin-bottom: 16px;
  background: #f8f9fa;
  border-bottom: none;
}
</style>
