<script>
export default {
  name: "UserRole",
  props: {
    user: Object,
    isDriver: Boolean,
    isOwner: Boolean,
    isCoordinator: Boolean,
    light: Boolean,
  },
};
</script>

<template>
  <div class="status-items text-right">
    <v-tooltip v-if="user.is_driver || isDriver" location="top">
      <template #activator="{ props }">
        <v-chip
          :class="light ? 'text-indigo-lighten-2' : 'text-white'"
          :color="light ? 'grey-lighten-4' : 'indigo-lighten-2'"
          class="status-item text-uppercase font-weight-600"
          v-bind="props"
          variant="flat"
        >
          {{ $t("users.dr") }}
        </v-chip>
      </template>
      <span class="text-body-2">{{ $t("users.driver") }}</span>
    </v-tooltip>
    <v-tooltip v-if="user.is_owner || isOwner" location="top">
      <template #activator="{ props }">
        <v-chip
          :class="light ? 'text-green-lighten-1' : 'text-white'"
          :color="light ? 'grey-lighten-4' : 'green-lighten-1'"
          class="status-item text-uppercase font-weight-600"
          v-bind="props"
          variant="flat"
        >
          {{ $t("users.ow") }}
        </v-chip>
      </template>
      <span class="text-body-2">{{ $t("users.owner") }}</span>
    </v-tooltip>
    <v-tooltip v-if="user.is_coordinator || isCoordinator" location="top">
      <template #activator="{ props }">
        <v-chip
          :class="light ? 'text-blue-lighten-2' : 'text-white'"
          :color="light ? 'grey-lighten-4' : 'blue-lighten-2'"
          class="status-item text-uppercase font-weight-600"
          v-bind="props"
          variant="flat"
        >
          {{ $t("users.cr") }}
        </v-chip>
      </template>
      <span class="text-body-2">{{ $t("users.coordinator") }}</span>
    </v-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.status-items {
  line-height: 1;
  .status-item {
    font-size: 9px !important;
    line-height: 16px;
    height: 16px !important;
    border-radius: 100px;
    padding: 0 2px !important;
    min-width: auto !important;
  }
}
</style>
