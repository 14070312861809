<script>
import { BehaviorSubject } from "rxjs";

const SIDEBAR_LAYOUT_SYMBOL = Symbol.for("TableFilter");
export default {
  name: "SidebarLayout",
  provide() {
    return {
      sidebarLayoutSymbol: SIDEBAR_LAYOUT_SYMBOL,
      controller: this.controller,
    };
  },
  props: {
    sidebarOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      controller: new BehaviorSubject(this.sidebarOpened),
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.controller.getValue();
      },
      set(value) {
        this.controller.next(value);
      },
    },
  },
  methods: {
    closeSidebar() {
      this.isVisible = false;
    },
    toggleSidebar() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<template>
  <div class="d-flex position-relative">
    <slot></slot>
    <div v-if="isVisible" class="sidebar">
      <div class="box-shadow bg-white rounded h-100 d-flex flex-column position-relative">
        <v-btn
          class="sidebar--close"
          density="comfortable"
          icon="mdi-close"
          size="small"
          variant="flat"
          @click="closeSidebar"
        ></v-btn>
        <slot name="sidebar"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  max-width: 405px;
  flex: 0 0 405px;
  margin-left: 24px;
  @media (max-width: 1350px) {
    max-width: 305px;
    flex: 0 0 auto;
  }
  @media (max-width: 1250px) {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sidebar--close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 9;
  }
}
</style>
