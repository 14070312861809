<script>
export default {
  name: "OmniTabs",
  props: {
    modelValue: null,
    alignTabs: {
      type: String,
      default: "title",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-tabs v-model="model" :align-tabs="alignTabs" bg-color="white" color="primary" height="56">
    <slot></slot>
  </v-tabs>
</template>

<style lang="scss" scoped>
.v-tabs-items {
  background-color: transparent !important;
}

:deep(.v-tab) {
  font-weight: 400;
  text-transform: uppercase;

  &.v-tab--selected .v-tab__slider {
    height: 4px;
  }
}
</style>
