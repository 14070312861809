<script>
export default {
  name: "OmniTextField",
  props: {
    id: String,
    modelValue: String,
    label: String,
    rules: Array,
    required: Boolean,
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-text-field
    :id="id"
    v-model="model"
    :class="{ required }"
    :disabled="disabled"
    :label="label"
    :rules="rules"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
