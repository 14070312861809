<script>
import { DateTimeMixin } from "@/mixins/date-time.mixin";

export default {
  name: "TrackingStatus",
  mixins: [DateTimeMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      if (this.user.archived) {
        return "grey-darken-2";
      }
      switch (this.user.mobile_user_tracking_status) {
        case "available":
          return "success";
        case "not_available":
        case "denied":
        case "not_provided_in_use":
        case "not_provided_always":
          return "error";
        default:
          return null;
      }
    },
    label() {
      switch (this.user.mobile_user_tracking_status) {
        case "available":
          return "Active";
        case "not_available":
        case "denied":
        case "not_provided_in_use":
        case "not_provided_always":
          return "Not active";
        default:
          return null;
      }
    },
    tooltip() {
      switch (this.user.mobile_user_tracking_status) {
        case "available":
          return null;
        case "not_available":
          return "Unable to get location (Turned off GPS or bad connection with satellite)";
        case "denied":
          return "Permission for GPS is forbidden";
        case "not_provided_in_use":
          return "Permission for GPS is not granted while using app";
        case "not_provided_always":
          return "Permission for GPS is not granted always";
        default:
          return null;
      }
    },
  },
};
</script>

<template>
  <v-tooltip v-if="label" :disabled="!tooltip" location="top">
    <template #activator="{ props }">
      <v-chip :color="color" size="x-small" v-bind="props" variant="flat">{{ label }}</v-chip>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
  <div v-else>&mdash;</div>

  <span v-if="user.mobile_user_tracking_status_dt" class="ml-3 caption">
    {{ timeAgo(user.mobile_user_tracking_status_dt) }}
  </span>
</template>

<style lang="scss" scoped></style>
