<script>
import { mapState } from "vuex";

export default {
  name: "DispatcherSelect",
  props: {
    id: String,
    modelValue: Number,
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(["dispatchers"]),
    isLoading() {
      return !this.dispatchers || this.dispatchers.length === 0;
    },
    value: {
      get() {
        return this.isLoading ? "Loading..." : this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-autocomplete
    :id="id"
    v-model="value"
    :class="{ required }"
    :clearable="true"
    :items="dispatchers"
    :label="label || $t('dispatchers.dispatcher')"
    :loading="isLoading"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    variant="outlined"
  />
</template>

<style scoped></style>
