<script>
export default {
  name: "DriversMixin",
  methods: {
    getStatusClass(status) {
      let color = "bg-grey-lighten-4 text-grey-darken-3";
      switch (status) {
        case "a":
          color = "bg-green-lighten-2 text-white";
          break;
        case "h":
          color = "bg-red-lighten-2 text-white";
          break;
        case "s":
          color = "bg-red-lighten-2 text-white";
          break;
      }
      return color;
    },
    getUserDocuments(user) {
      const docs = [];

      if (user.willing_cross_border_canada) docs.push({ code: "CA", name: this.$t("drivers.cross-border-canada") });
      if (user.willing_cross_border_mexico) docs.push({ code: "MX", name: this.$t("drivers.cross-border-mexico") });

      if (user.certificate_hazmat_expiration_date) {
        docs.push({
          code: "HAZ",
          name: this.$t("drivers.hazmat-certificate"),
          date: user.certificate_hazmat_expiration_date,
        });
      }

      if (user.certificate_twic_expiration_date) {
        docs.push({
          code: "TWIC",
          name: this.$t("drivers.twic-certificate"),
          date: user.certificate_twic_expiration_date,
        });
      }

      if (user.certificate_tsa_expiration_date) {
        docs.push({
          code: "TSA",
          name: this.$t("drivers.tsa-certificate"),
          date: user.certificate_tsa_expiration_date,
        });
      }

      if (user.certificate_tanker_endorsement_expiration_date) {
        docs.push({
          code: "TE",
          name: this.$t("drivers.tanker-certificate"),
          date: user.certificate_tanker_endorsement_expiration_date,
        });
      }

      return docs;
    },
  },
};
</script>
