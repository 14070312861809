<script>
import FileMixin from "@/mixins/file.mixin";
import GalleryFrame from "@/components/gallery/GalleryFrame.vue";
import { ViewType } from "@/components/gallery/Gallery";

export default {
  name: "GalleryView",
  components: { GalleryFrame },
  mixins: [FileMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    activeId: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      isLoading: false,
      activeIndex: 0,
    };
  },
  computed: {
    dataItems() {
      const dataItems = [];
      for (const mediaObject of this.items) {
        const dataItem = this.getDataItemFromMedia(mediaObject);
        if (dataItem) {
          dataItems.push(dataItem);
        }
      }
      return dataItems;
    },
    loadingProgress() {
      return Math.floor((this.dataItems.length * 100) / this.items.length);
    },
    activeItem() {
      return this.items[this.activeIndex];
    },
  },
  watch: {
    show(value) {
      if (value) {
        // find media index by messageId
        for (const n in this.dataItems) {
          if (this.dataItems[n].id === this.activeId) {
            this.activeIndex = n;
          }
        }

        this.registerListeners();
      }
    },
  },
  methods: {
    registerListeners() {
      document.onkeyup = this.keyActions;
    },
    unregisterListeners() {
      document.onkeyup = null;
    },
    keyActions(event) {
      event = event || window.event;

      if (event.keyCode === 37) {
        this.previousFrame();
      } else if (event.keyCode === 39) {
        this.nextFrame();
      } else if (event.keyCode === 27) {
        this.close();
      }
    },
    close() {
      this.unregisterListeners();
      this.$emit("close");
    },
    nextFrame() {
      if (this.activeIndex < this.dataItems.length - 1) {
        this.activeIndex++;
      }
    },
    previousFrame() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
    getDataItemFromMedia(mediaObject) {
      const dataItem = { ...mediaObject };

      if (this.isImageUrl(mediaObject.url) || (mediaObject.file && this.isImage(mediaObject.file))) {
        dataItem.type = ViewType.image;
      } else if (this.isPdfUrl(mediaObject.url) || (mediaObject.file && this.isPdf(mediaObject.file))) {
        dataItem.type = ViewType.pdf;
      } else {
        console.error("[GalleryView] URL " + mediaObject.url + " was not recognized");
        return null;
      }

      return dataItem;
    },
    download() {
      this.downloadUrl(this.activeItem.url);
    },
  },
};
</script>

<template>
  <transition class="gallery" name="fade">
    <div v-if="show" class="gallery__overlay">
      <div ref="viewport" class="gallery__viewport">
        <div class="gallery__action-btn close" @click="close">
          <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"
            />
          </svg>
        </div>
        <div v-if="activeItem?.url" class="gallery__action-btn media-download" @click="download">
          <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22,16 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,16 L4,16 L4,20 L20,20 L20,16 L22,16 Z M13,12.5857864 L16.2928932,9.29289322 L17.7071068,10.7071068 L12,16.4142136 L6.29289322,10.7071068 L7.70710678,9.29289322 L11,12.5857864 L11,2 L13,2 L13,12.5857864 Z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <template v-if="isLoading">
          <div class="gallery__progress">
            <v-progress-circular color="#ccc" indeterminate size="100"> {{ loadingProgress }}%</v-progress-circular>
          </div>
        </template>
        <template v-if="dataItems.length > 0 && !isLoading">
          <div v-if="activeIndex > 0" class="gallery__edge-action left" @click="previousFrame">
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <polygon
                fill-rule="evenodd"
                points="9.414 12 16.707 19.293 15.293 20.707 6.586 12 15.293 3.293 16.707 4.707"
              />
            </svg>
          </div>
          <div v-if="activeIndex < dataItems.length - 1" class="gallery__edge-action right" @click="nextFrame">
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <polygon
                fill-rule="evenodd"
                points="14.586 12 7.293 4.707 8.707 3.293 17.414 12 8.707 20.707 7.293 19.293"
              />
            </svg>
          </div>
          <template v-for="(dataItem, index) in dataItems">
            <gallery-frame v-if="parseInt(index) === parseInt(activeIndex)" :key="index" :frame="dataItem" />
          </template>
        </template>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.gallery {
  $control-size: 96px;
  $edge-padding: 16px;

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.9);
    z-index: 9999;
  }

  &__viewport {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  &__progress {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__action-btn {
    position: fixed;
    width: $control-size;
    height: $control-size;
    padding: 32px;
    cursor: pointer;
    color: #ccc;
    transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
    z-index: 1;

    &:hover {
      color: #fff;
      background-color: rgba(30, 30, 30, 0.6);
    }

    &.close {
      top: $edge-padding;
      right: $edge-padding;
    }

    &.media-download {
      bottom: $edge-padding;
      right: $edge-padding;
    }
  }

  &__edge-action {
    position: fixed;
    top: $control-size + $edge-padding;
    bottom: $control-size + $edge-padding;
    width: $control-size;
    transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
    cursor: pointer;
    padding: 32px;
    color: #ccc;
    display: flex;
    align-items: center;
    z-index: 1;

    &.right {
      right: $edge-padding;
    }

    &.left {
      left: $edge-padding;
    }

    &:hover {
      background-color: rgba(30, 30, 30, 0.6);
      color: #fff;
    }

    svg {
      display: block;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
