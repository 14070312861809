<script>
import ChatUserTrucks from "@/views/chats/user-trucks/UserTrucks.vue";
import UserCard from "@/views/chats/UserCard.vue";

export default {
  name: "ChatInfoCoordinatorPanel",
  components: { UserCard, ChatUserTrucks },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      trucks: {},
    };
  },
  watch: {
    async user() {
      await this.loadCoordinatedTrucks();
    },
  },
  async mounted() {
    await this.loadCoordinatedTrucks();
  },
  methods: {
    async loadCoordinatedTrucks() {
      if (!this.user.is_coordinator) return;
      const resp = await this.$api.trucks.findTrucks({ contact_person: this.user.id, page_size: 100 });
      if (resp.success) {
        this.trucks = Object.groupBy(resp.data.items, ({ owner_id }) => owner_id);
      }
    },
  },
};
</script>

<template>
  <div v-if="user.is_coordinator" class="panel-info__block rounded" data-qa="coordinator-info-block">
    <div
      class="panel-info__block-header d-flex justify-space-between bg-blue-lighten-2 pt-2 pb-2 pl-5 pr-5 rounded-b-0 rounded"
    >
      <span class="font-size-12 font-weight-600 text-uppercase text-white">Coordinator</span>
    </div>
    <div v-for="(coordinator, index) in user.owners" :key="index" class="panel-info__block-content pa-3">
      <user-card
        :role="$t('drivers.owner')"
        :status="coordinator.owner.owner_status"
        :user="coordinator.owner"
        role-color="text-green-lighten-1"
      />
      <chat-user-trucks :initial-trucks="trucks[coordinator.owner_id] || []" :user="coordinator.user" />
      <div v-if="index < user.owners.length - 1" class="short-line" />
    </div>
  </div>
</template>

<style scoped>
.panel-info__block {
  margin-bottom: 16px;
  background: #f8f9fa;
  border-bottom: none;
}
</style>
