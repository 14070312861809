import { BaseApi } from "@/api/base";

export class GeoApi extends BaseApi {
  async searchGeneral(query = "", limit = 5) {
    const url = "/geo/coder/search_general";
    return await this._search(url, { q: query, limit: limit });
  }

  async searchPostalCode(code = "", limit = 15) {
    const url = "/geo/coder/search_general";
    return await this._search(url, { q: code, limit: limit });
  }

  async _search(url, params) {
    params.addressdetails = 1;
    return this.client.get(url, params);
  }

  async reverse(lat, lon, limit = 5) {
    return this.client.get("/geo/coder/reverse", {
      lat: lat,
      lon: lon,
      limit: limit,
    });
  }
}
