<script>
import { mapState } from "vuex";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import OmniDialog from "@/components/Dialog.vue";

export default {
  name: "ResolveChatAction",
  components: { OmniDialog },
  mixins: [ChatMixin],
  props: {
    thread: Object,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
  methods: {
    async clickResolveThread() {
      if (this.thread.dispatcher_id && this.thread.dispatcher_id !== this.dispatcher.id && !this.thread.resolved) {
        this.dialogVisible = true;
      } else {
        await this.resolveThread();
      }
    },
    async doResolveThread() {
      await this.resolveThread();
      this.dialogVisible = false;
    },
  },
};
</script>

<template>
  <v-btn
    v-if="!thread.resolved"
    class="mr-2"
    color="success"
    size="small"
    variant="outlined"
    @click="clickResolveThread"
  >
    <v-icon class="mr-1">mdi-check</v-icon>
    <span class="font-weight-600 text-uppercase text-success">
      {{ $t("dispatchers.resolve") }}
    </span>
  </v-btn>
  <omni-dialog
    v-model="dialogVisible"
    primary-action-label="Resolve"
    size="small"
    title="Be attention!"
    @primary-action:click="doResolveThread"
  >
    <div class="text-body-2 text-left text-grey text-grey-darken-1">
      If you want to resolve this chat from
      <span v-if="thread.dispatcher">{{ thread.dispatcher.name }}</span>
      please press “Resolve”!
    </div>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
