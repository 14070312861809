<script>
export default {
  name: "LoadingOverlay",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-if="loading" class="loading-container">
      <div class="loader">
        <v-progress-circular color="#556ee6" indeterminate size="64"></v-progress-circular>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 79;

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
