<script>
import OmniDialog from "@/components/Dialog.vue";
import { TravelOrderCancellationStatusMap, TravelOrderStatus } from "@/data/trip";
import { OrderCancellationStatusMap } from "@/data/order";
import OmniSelect from "@/components/controls/Select.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { mapMutations } from "vuex";
import TravelOrderStatusLabel from "@/views/trips/components/TravelOrderStatus.vue";
import OrderFile from "@/views/orders/components/edit/OrderFile.vue";

const tonuOrderStatus = "tonu";

export default {
  name: "TravelOrderBlock",
  components: { OrderFile, TravelOrderStatusLabel, OmniSelect, OmniDialog },
  mixins: [ValidationMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    tripId: null,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      rate: null,
      dryRunDialog: false,
      travelOrderStatus: null,
      dryRunRate: null,
      orderStatus: null,
      loading: false,
      tonuFile: [],
    };
  },
  computed: {
    travelOrder() {
      return this.modelValue;
    },
    travelOrderStatuses() {
      return TravelOrderCancellationStatusMap;
    },
    orderStatuses() {
      return OrderCancellationStatusMap;
    },
    isCancelled() {
      return this.travelOrderStatuses.some((s) => s.id === this.travelOrder.status);
    },
    isDryRun() {
      return this.travelOrderStatus === TravelOrderStatus.dryRun;
    },
  },
  watch: {
    modelValue(travelOrder) {
      this.prefillData(travelOrder);
    },
  },
  created() {
    this.prefillData(this.modelValue);
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    prefillData(travelOrder) {
      this.dryRunRate = travelOrder.dry_run_rate || 0;
      this.rate = travelOrder.rate;
      if (this.travelOrderStatuses.findIndex((s) => s.id === travelOrder.status) > -1) {
        this.travelOrderStatus = travelOrder.status;
      }
      if (this.orderStatuses.findIndex((s) => s.id === travelOrder.order.status) > -1) {
        this.orderStatus = travelOrder.order.status;
      }
    },
    async saveDryRun() {
      const result = await this.$refs.cancelForm.validate();
      if (!result.valid) {
        return;
      }

      let canSaveCancellation = true;

      this.loading = true;
      // upload TONU file
      if (this.orderStatus === tonuOrderStatus) {
        if (this.tonuFile.length > 0) {
          const fileResp = await this.$api.files.uploadFile("orders", this.tonuFile[0], [
            "order:" + this.travelOrder.order_id,
            tonuOrderStatus, // add "tonu" tag
          ]);
          if (!fileResp.success) {
            canSaveCancellation = false;
            this.showSnackbarError("Error uploading TONU file");
          }
        } else {
          this.showSnackbarError("Missing TONU file");
          canSaveCancellation = false;
        }
      }

      if (canSaveCancellation) {
        const response = await this.$api.trips.cancelTravelOrder(this.tripId, this.travelOrder.id, {
          travel_order_status: this.travelOrderStatus,
          dry_run_rate: parseInt(this.dryRunRate),
          order_status: this.orderStatus,
        });
        if (response.success) {
          this.dryRunDialog = false;
          this.$emit("change");
        } else {
          this.showSnackbarError("Error cancelling travel order");
        }
      }
      this.loading = false;
    },
    update() {
      const travelOrder = { ...this.modelValue, rate: this.rate };
      this.$emit("update:modelValue", travelOrder);
    },
  },
};
</script>
<template>
  <div class="trip-block" data-qa="travel-order-block">
    <div :class="isCancelled ? 'bg-red-lighten-5' : 'bg-white'" class="trip-block__inner pa-4 rounded-lg">
      <div class="trip-block__header d-flex align-center justify-space-between mb-3">
        <div class="d-flex align-center">
          <span class="text-grey-darken-2 font-weight-medium">Travel order #{{ travelOrder.number }}</span>
          <travel-order-status-label :travel-order="travelOrder" class="ml-2"></travel-order-status-label>
        </div>
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn elevation="0" icon="mdi-dots-horizontal" size="x-small" variant="text" v-bind="props" />
          </template>
          <v-list dense>
            <v-list-item v-if="!isCancelled" @click="dryRunDialog = true">
              <v-list-item-title data-qa="cancel-travel-order">Cancel travel order</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div>
        <v-text-field
          v-if="isCancelled"
          v-model="dryRunRate"
          class="required"
          color="primary"
          density="compact"
          disabled
          hide-details="auto"
          label="Driver rate"
          variant="outlined"
        >
          <template #append-inner>
            <span class="font-size-16 text-grey-darken-1">$</span>
          </template>
        </v-text-field>
        <v-text-field
          v-else
          v-model="rate"
          :rules="[requiredValidator]"
          class="required"
          color="primary"
          density="compact"
          hide-details="auto"
          label="Driver rate"
          variant="outlined"
          data-qa="driver-rate"
          @input="update"
        >
          <template #append-inner>
            <span class="font-size-16 text-grey-darken-1">$</span>
          </template>
        </v-text-field>
      </div>
    </div>

    <omni-dialog
      v-model="dryRunDialog"
      :loading="loading"
      primary-action-label="Save"
      title="Cancel travel order"
      @primary-action:click="saveDryRun"
    >
      <v-form ref="cancelForm" class="py-4">
        <p class="mb-4 text-grey-darken-1">Please select status for TO#{{ travelOrder.number }}</p>
        <v-row>
          <v-col>
            <omni-select
              v-model="travelOrderStatus"
              :items="travelOrderStatuses"
              :rules="[requiredValidator]"
              label="Travel order status"
              data-qa="travel-order-status"
              required
            />
          </v-col>
          <v-col v-if="isDryRun">
            <v-text-field
              v-model="dryRunRate"
              :rules="[requiredValidator]"
              append-inner-icon="mdi-currency-usd"
              class="required"
              color="primary"
              density="compact"
              hide-details="auto"
              label="Rate"
              variant="outlined"
              data-qa="modal-order-rate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="mb-4 text-grey-darken-1">
              Please select status of order
              <router-link
                :to="{ name: 'order-view', params: { id: travelOrder.order_id } }"
                target="_blank"
                class="text-decoration-none"
              >
                #{{ travelOrder.order.number }}
              </router-link>
              after cancel
            </p>
            <omni-select
              v-model="orderStatus"
              :items="orderStatuses"
              clearable
              label="Order status"
              data-qa="order-status-select"
            />
            <order-file v-if="orderStatus === 'tonu'" v-model="tonuFile" label="TONU file" class="mt-3"></order-file>
          </v-col>
        </v-row>
      </v-form>
    </omni-dialog>
  </div>
</template>

<style scoped>
.trip-block__inner {
  min-height: 180px;
  display: flex;
  flex-direction: column;
}
</style>
