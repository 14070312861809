<script>
import BaseView from "@/views/BaseView.vue";
import DriversList from "@/views/drivers/DriversList.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import TrucksList from "@/views/trucks/TrucksList.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import UserFiles from "@/views/users/UserFiles.vue";
import { mapState } from "vuex";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import SidebarLayout from "@/components/layout/SidebarLayout.vue";
import SidebarToggle from "@/components/layout/SidebarToggle.vue";
import UserViewUserInfo from "@/views/users/components/view/UserViewUserInfo.vue";
import UserViewDriverInfo from "@/views/users/components/view/UserViewDriverInfo.vue";
import UserViewDispatcherNote from "@/views/users/components/view/UserViewDispatcherNote.vue";
import UserViewDriverNote from "@/views/users/components/view/UserViewDriverNote.vue";
import UserViewOwnerInfo from "@/views/users/components/view/UserViewOwnerInfo.vue";
import UserViewSidebar from "@/views/users/components/view/UserViewSidebar.vue";
import NotFoundMixin from "@/mixins/not-found.mixin";
import NotFound from "@/views/misc/NotFound.vue";
import UserAdditionalActions from "@/views/users/components/UserAdditionalActions.vue";
import UserViewCoordinatorInfo from "@/views/users/components/view/UserViewCoordinatorInfo.vue";
import OmniTabs from "@/components/controls/Tabs.vue";

export default {
  name: "UserView",
  components: {
    OmniTabs,
    UserAdditionalActions,
    NotFound,
    UserViewSidebar,
    UserViewCoordinatorInfo,
    UserViewOwnerInfo,
    UserViewDriverNote,
    UserViewDispatcherNote,
    UserViewDriverInfo,
    UserViewUserInfo,
    SidebarToggle,
    SidebarLayout,
    DriversList,
    TrucksList,
    UserFiles,
  },
  mixins: [BaseView, UsersMixin, TrucksMixin, DateTimeMixin, NotFoundMixin],
  data() {
    return {
      user: { companies: [] },
      trucksParams: { owner: this.$route.params.id },
      driversParams: { owner: this.$route.params.id },

      loading: false,
      tab: 0,
    };
  },
  computed: {
    ...mapState(["dispatchers", "dispatcher"]),
    editLink() {
      return {
        name: this.$route.name.replace("view", "edit"),
        params: { id: this.user.id },
      };
    },
  },
  async mounted() {
    await this.loadInfo();
  },
  methods: {
    async loadDrivers() {
      if (!this.user.is_owner) return;

      try {
        const resp = await this.$api.users.findDrivers({
          owner: this.$route.params.id,
        });
        this.drivers = resp.data.filter((d) => d.id !== this.user.driver_id);
      } catch (error) {
        console.error("Error load owner drivers: ", error);
      }
    },
    async loadUser() {
      let user = { coordinators: [], owners: [] };

      const resp = await this.$api.users.findUserById(this.$route.params.id);
      if (!this.validateEntityResponse(resp)) return;
      user = { ...user, ...resp.data };

      if (user.is_driver) {
        const driverResponse = await this.$api.users.findDriverById(user.id);
        if (driverResponse.success) {
          user = { ...user, ...driverResponse.data };
        }

        // load driver owner coordinators
        const coordinatorsResponse = await this.$api.users.findOwnerCoordinators(user.owner_id);
        if (coordinatorsResponse.success) {
          user.coordinators = coordinatorsResponse.data;
        }
      }
      this.setTitle("User " + user.name, "Users");

      // load coordinators & companies
      if (user.is_owner) {
        const companiesResponse = await this.$api.users.findCompanies(user.id);
        if (companiesResponse.success) {
          user.companies = companiesResponse.data;
        }

        const coordinatorsResponse = await this.$api.users.findOwnerCoordinators(user.id);
        if (coordinatorsResponse.success) {
          user.coordinators = coordinatorsResponse.data;
        }
      }

      // load coordinator owners
      if (user.is_coordinator) {
        const ownersResponse = await this.$api.users.findCoordinatorOwners(user.id);
        if (ownersResponse.success) {
          user.owners = ownersResponse.data;
        }
      }

      this.user = user;
    },
    async loadTrucks() {
      if (!this.user.is_owner) return;

      try {
        const resp = await this.$api.trucks.findTrucks({
          owner: this.$route.params.id,
        });
        this.trucks = resp.data;
      } catch (error) {
        console.error("Error load owner trucks: ", error);
      }
    },
    async loadInfo() {
      this.loading = true;

      await Promise.all([this.loadUser(), this.loadDrivers(), this.loadTrucks()]);

      this.loading = false;
    },
    isSidebarOpenDefault() {
      let windowWidth = window.innerWidth;
      return windowWidth > 1250;
    },
  },
};
</script>

<template>
  <v-container v-if="!entityNotFound" :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 users-page">
    <sidebar-layout :sidebar-opened="isSidebarOpenDefault()">
      <div class="w-100">
        <div class="users-page__header d-flex justify-space-between align-center flex-wrap mb-4">
          <div class="users-page__name text-grey-darken-4 title font-weight-medium font-size-24">
            {{ user.name }}
            <v-chip v-if="user.archived !== null" :label="true" class="ml-4" color="grey-darken-2" variant="flat">
              Archived
            </v-chip>
          </div>
          <div v-if="user.id">
            <v-btn
              v-if="hasAccess('owners', 'edit')"
              :class="{ 'btn--disabled': user.archived !== null }"
              :disabled="user.archived !== null"
              :to="editLink"
              class="text-uppercase font-weight-600 text-white mr-2"
              color="primary"
              variant="flat"
            >
              <v-icon class="mr-2" size="20"> mdi-pencil-outline</v-icon>
              {{ $t("general.edit") }} profile
            </v-btn>
            <sidebar-toggle class="mr-2"></sidebar-toggle>
            <user-additional-actions :user="user" @change="loadUser"></user-additional-actions>
          </div>
        </div>

        <v-card variant="flat">
          <omni-tabs v-model="tab" class="rounded">
            <v-tab key="overview" class="text-uppercase">Overview</v-tab>
            <v-tab v-if="user.is_owner" value="drivers"> Drivers</v-tab>
            <v-tab v-if="user.is_owner" value="trucks"> Trucks</v-tab>
            <v-tab class="text-uppercase" value="files">Files</v-tab>
          </omni-tabs>

          <v-window v-model="tab" :touch="false">
            <v-window-item value="overview">
              <user-view-user-info :user="user"></user-view-user-info>
              <user-view-driver-info :user="user"></user-view-driver-info>
              <user-view-dispatcher-note :user="user"></user-view-dispatcher-note>
              <user-view-driver-note :user="user"></user-view-driver-note>
              <user-view-owner-info :user="user"></user-view-owner-info>
              <user-view-coordinator-info :user="user"></user-view-coordinator-info>
            </v-window-item>

            <v-window-item v-if="user.is_owner" value="drivers">
              <div class="mt-4">
                <drivers-list :compact="true" :search-params="driversParams" />
              </div>
            </v-window-item>

            <v-window-item v-if="user.is_owner" value="trucks">
              <div class="mt-4">
                <trucks-list
                  :available-columns="['name', 'status', 'driver', 'size', 'available', 'current']"
                  :compact="true"
                  :search-params="trucksParams"
                />
              </div>
            </v-window-item>

            <v-window-item class="pa-3" value="files">
              <user-files />
            </v-window-item>
          </v-window>
        </v-card>
      </div>
      <template #sidebar>
        <user-view-sidebar :user="user"></user-view-sidebar>
      </template>
    </sidebar-layout>
  </v-container>
  <not-found v-else icon="mdi-account-remove-outline" text="User not found"></not-found>
</template>

<style lang="scss" scoped>
b {
  color: black;
}

h3 {
  margin: 0 0 3px;
  color: black;
}

h4 {
  margin: 0 0 2px;
  color: black;
}

.v-card {
  background-color: transparent !important;
}
</style>
