<script>
import BaseView from "@/views/BaseView.vue";
import FacilityEditForm from "@/views/companies/facilities/EditForm.vue";

export default {
  name: "FacilityEdit",
  components: { FacilityEditForm },
  mixins: [BaseView],
  data() {
    return {
      perm: "facilities",
      item: {},
      loading: false,
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  async created() {
    this.setTitle(this.$t("company.facility"));
    await this.loadFacility();
  },
  methods: {
    async loadFacility() {
      if (!this.$route.params.id) return;

      this.loading = true;
      try {
        const resp = await this.$api.company.findFacilityById(this.$route.params.id);
        this.item = resp.data;
      } catch (e) {
        console.error("Error load facility by id: ", e);
      }

      this.loading = false;
    },
    redirectToList() {
      this.$router.push({ name: "facilities" });
    },
  },
};
</script>

<template>
  <facility-edit-form :facility="item" @change="redirectToList" @close="redirectToList" />
</template>
