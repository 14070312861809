import { TruckAdditionalEquipment, TruckDoorType, TruckEquipment } from "@/data/truck";

export default class TruckHelper {
  static equipmentToString(truck) {
    if (!truck.trailer?.equipment || truck.trailer?.equipment.length === 0) {
      return "-";
    }
    return truck.trailer.equipment.map((e) => this.getEquipmentName(e)).join(", ");
  }

  static additionalEquipmentToString(truck) {
    if (!truck.trailer?.additional_equipment || truck.trailer?.additional_equipment.length === 0) {
      return "-";
    }
    return truck.trailer.additional_equipment.map((e) => this.getAdditionalEquipmentName(e)).join(", ");
  }

  static getEquipmentName(id) {
    return TruckEquipment.find((e) => e.id === id)?.name || "-";
  }

  static getAdditionalEquipmentName(id) {
    return TruckAdditionalEquipment.find((e) => e.id === id)?.name || "-";
  }

  static doorTypeToString(truck) {
    if (!truck.trailer?.door_type) {
      return "-";
    }
    return TruckDoorType.find((dt) => dt.id === truck.trailer?.door_type)?.name;
  }
}
