<script>
import { ViewType } from "@/components/gallery/Gallery";
import ImageViewer from "@/components/gallery/viewer/ImageViewer.vue";
import PdfViewer from "@/components/gallery/viewer/PdfViewer.vue";

export default {
  name: "GalleryFrame",
  components: { PdfViewer, ImageViewer },
  props: {
    frame: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  computed: {
    isImage() {
      return this.frame.type === ViewType.image;
    },
    isPdf() {
      return this.frame.type === ViewType.pdf;
    },
  },
  created() {
    if (this.frame.url) {
      this.data = this.frame.url;
    } else if (this.frame.file) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        this.data = reader.result;
      });
      reader.readAsDataURL(this.frame.file);
    }
  },
};
</script>

<template>
  <div v-if="data" class="gallery__frame">
    <image-viewer v-if="isImage" :data="data" />
    <pdf-viewer v-if="isPdf" :data="data" />
  </div>
</template>

<style lang="scss" scoped>
.gallery__frame {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
</style>
