<script>
import ValidationMixin from "@/mixins/validation.mixin";
import LocationMixin from "@/mixins/location.mixin";

export default {
  name: "LocationField",
  mixins: [ValidationMixin, LocationMixin],
  props: {
    modelValue: null,
    required: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="model"
    :class="{ required: required }"
    :rules="required ? [requiredValidator, locationValidator] : [locationValidator]"
    autocomplete="off1"
    color="primary"
    density="compact"
    hide-details="auto"
    label="Location"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
