const SnackbarStore = {
  state: {
    snackbar: {},
  },
  mutations: {
    showSnackbar(state, snackbar) {
      snackbar = snackbar || {};
      snackbar.show = true;
      state.snackbar = snackbar;
    },
    closeSnackBar(state) {
      state.snackbar = { ...state.snackbar, show: false };
    },
    showSnackbarError(state, message) {
      const snackbar = {
        color: "#FF5252",
        text: message,
        timeout: -1,
      };
      this.commit("showSnackbar", snackbar);
    },
    showSnackbarSuccess(state, message) {
      const snackbar = {
        color: "success",
        text: message,
        timeout: 4000,
      };
      this.commit("showSnackbar", snackbar);
    },
  },
};

export default SnackbarStore;
