<script>
import BaseView from "@/views/BaseView.vue";
import TrucksList from "@/views/trucks/TrucksList.vue";
import TrucksSearchForm from "@/views/trucks/TrucksSearchForm.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "TrucksPage",
  components: { PageHeader, TrucksSearchForm, TrucksList },
  mixins: [BaseView],
  data() {
    return {
      perm: "trucks",
      searchParams: {},
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  created() {
    this.setTitle(this.$t("trucks.search"));
  },
  methods: {
    search(params) {
      this.searchParams = { ...params };
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 trucks-search">
    <page-header :header="$t('trucks.trucks')"></page-header>
    <trucks-search-form @search="search" />
    <trucks-list
      :can-edit="canEdit"
      :available-columns="['name', 'status', 'size', 'contact', 'distance', 'available', 'notes']"
      :search-params="searchParams"
    />
  </v-container>
</template>

<style lang="scss">
.trucks-search {
  & > .v-card {
    background: transparent;
  }

  .col-status {
    .quote-btn {
      // dont show notes for status column in truck search
      display: none;
    }
  }

  .location-btn {
    display: block;
  }
}
</style>
