<script>
import UserStatus from "@/components/status/UserStatus.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import CheckMark from "@/components/CheckMark.vue";

export default {
  name: "UserViewDriverInfo",
  components: { CheckMark, UserStatus },
  mixins: [UsersMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    citizenship() {
      return this.citizenships.find((c) => c.id === this.user.citizenship)?.name;
    },
  },
};
</script>

<template>
  <div v-if="user.is_driver" class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <v-row>
      <v-col lg="6">
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Driver info</div>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Status</span>
          </div>
          <div class="description">
            <user-status :muted="user.archived !== null" :status="user.driver_status"></user-status>
          </div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">License</span>
          </div>
          <div class="description">{{ user.license_type }} {{ user.license_number }} ({{ user.license_class }})</div>
        </v-row>
        <v-row>
          <div class="label">
            <span class="text-grey-darken-1">Citizenship</span>
          </div>
          <div class="description">
            {{ citizenship }}
            <template v-if="user.citizenship_document_expiration_date">
              until
              {{ $filters.date(user.citizenship_document_expiration_date) }}
            </template>
          </div>
        </v-row>
        <v-row v-if="user.owner">
          <div class="label">
            <span class="text-grey-darken-1">Owner</span>
          </div>
          <div class="description">
            <router-link
              :to="{ name: 'user-view', params: { id: user.owner.id } }"
              class="text-primary text-decoration-none"
            >
              {{ user.owner.name }}
            </router-link>
          </div>
        </v-row>
        <v-row align="center">
          <div class="label">
            <span class="text-grey-darken-1">Can change status</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.can_change_status"></check-mark>
          </div>
        </v-row>
      </v-col>
      <v-col lg="6">
        <div class="text-grey-darken-3 mb-6 font-weight-medium">Certificates</div>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Hazmat Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificate_hazmat_expiration_date) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">TWIC Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificate_twic_expiration_date) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">Tanker Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificate_tanker_endorsement_expiration_date) }}
          </div>
        </v-row>
        <v-row>
          <div class="label long">
            <span class="text-grey-darken-1">TSA Expiration Date</span>
          </div>
          <div class="description">
            {{ $filters.date(user.certificate_tsa_expiration_date) }}
          </div>
        </v-row>
        <v-row align="center">
          <div class="label long">
            <span class="text-grey-darken-1">Willing to go to Canada</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.willing_cross_border_canada"></check-mark>
          </div>
        </v-row>
        <v-row align="center">
          <div class="label long">
            <span class="text-grey-darken-1">Willing to go to Mexico</span>
          </div>
          <div class="description">
            <check-mark :muted="user.archived !== null" :value="user.willing_cross_border_mexico"></check-mark>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
