const style = document.createElement("style");
style.innerHTML = `
.clipboard--tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.clipboard--tooltip.active {
  opacity: 1;
}
`;
document.head.appendChild(style);

function copyToClipboard(el, value) {
  const tooltip = document.createElement("div");
  tooltip.classList.add("clipboard--tooltip");
  tooltip.innerHTML = "Copied to clipboard";
  navigator.clipboard.writeText(value).then(() => {
    el.appendChild(tooltip);
    setTimeout(() => tooltip.classList.add("active"), 5);
    setTimeout(() => tooltip.classList.remove("active"), 2000);
    setTimeout(() => tooltip.remove(), 2300);
  });
}

export default {
  mounted: function (el, binding) {
    if (!binding.modifiers.uncolored) {
      el.classList.add("text-primary");
    }
    el.style.cursor = "pointer";
    el.style.position = "relative";
    el.addEventListener("click", () => {
      copyToClipboard(el, binding.value);
    });
  },
  updated: function (el, binding) {
    el.removeEventListener("click", copyToClipboard);
    el.addEventListener("click", () => {
      copyToClipboard(el, binding.value);
    });
  },
  unmounted: function (el) {
    el.removeEventListener("click", copyToClipboard);
  },
};
