<script>
import { defineComponent } from "vue";
import { VInput } from "vuetify/components";
import ValidationMixin from "@/mixins/validation.mixin";

const humanFormat = "MM/DD/YYYY",
  withoutPaddingZero = "M/D/YYYY",
  dbFormat = "YYYY-MM-DD";

export default defineComponent({
  name: "DateRangePicker",
  extends: VInput,
  mixins: [ValidationMixin],
  inheritAttrs: false,
  props: {
    id: String,
    label: String,
    modelValue: Array,
    max: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "search"],
  data() {
    return {
      show: false,
      dateMask: {
        mask: "##/##/#### - ##/##/####",
        eager: true,
      },
      formattedDate: null,
    };
  },
  computed: {
    date: {
      get() {
        if (!this.modelValue) {
          return null;
        }
        return [this.toOffsetDate(new Date(this.modelValue[0])), this.toOffsetDate(new Date(this.modelValue[1]))];
      },
      set(value) {
        this.formattedDate = [this.stringify(value[0]), this.stringify(value[1])].join(" - ");
        this.$emit("update:modelValue", [
          this.$dayjs(value[0]).format(dbFormat),
          this.$dayjs(value[1]).format(dbFormat),
        ]);
      },
    },
    preparedRules() {
      const rules = [];
      if (this.required) {
        rules.push(this.requiredValidator);
      }
      rules.push(...this.rules);
      rules.push(this.dateFormatValidator, this.maxDateValidator, this.minDateValidator);

      return rules;
    },
  },
  watch: {
    formattedDate(value) {
      if (!value) {
        this.clearDate();
      }
      if (this.dateInputValidator(value) === true && this.dateFormatValidator(value) === true) {
        this.$emit("update:modelValue", this.toDate(value).format(dbFormat));
      }
    },
    modelValue(value) {
      if (!value) {
        this.formattedDate = null;
        return;
      }
      this.formattedDate = [this.stringify(value[0]), this.stringify(value[1])].join(" - ");
    },
    date() {
      this.show = false;
    },
  },
  mounted() {
    if (this.modelValue) {
      this.formattedDate = [this.stringify(this.modelValue[0]), this.stringify(this.modelValue[1])].join(" - ");
    }
  },
  methods: {
    toOffsetDate(date) {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() + userTimezoneOffset);
    },
    search() {
      this.$emit("search", this.$dayjs(this.modelValue).format(dbFormat));
    },
    toDate(value) {
      return this.$dayjs(value, [dbFormat, humanFormat, withoutPaddingZero], true);
    },
    stringify(date) {
      return this.$dayjs(date).format(humanFormat);
    },
    dateFormatValidator(value) {
      if (!value) return true;

      return (
        value
          .split("-")
          .map((d) => this.toDate(d).isValid())
          .every((d) => d) || "Wrong date format"
      );
    },
    maxDateValidator(value) {
      if (!this.max) return true;
      return (
        this.toDate(value.split("-")[1]).isBefore(this.$dayjs(this.max)) ||
        "Date can not be after " + this.$dayjs(this.max).format(humanFormat)
      );
    },
    minDateValidator(value) {
      if (!this.min) return true;
      return (
        this.toDate(value.split("-")[0]).isAfter(this.$dayjs(this.min)) ||
        "Date can not be before " + this.$dayjs(this.min).format(humanFormat)
      );
    },
    clearDate() {
      this.$emit("update:modelValue", null);
    },
  },
});
</script>

<template>
  <v-menu
    ref="menu"
    v-model="show"
    :close-on-content-click="false"
    min-width="auto"
    offset="10"
    transition="scale-transition"
  >
    <template #activator="{ props }">
      <div class="d-flex">
        <v-text-field
          :id="id"
          v-model="formattedDate"
          v-maska:[dateMask]
          :class="{ required: required }"
          :clearable="true"
          :disabled="disabled"
          :label="label"
          :rules="preparedRules"
          append-inner-icon="mdi-calendar-blank-outline"
          aria-autocomplete="none"
          autocomplete="off"
          color="primary"
          density="compact"
          hide-details="auto"
          placeholder="MM/DD/YYYY - MM/DD/YYYY"
          v-bind="{ ...$attrs, ...props }"
          variant="outlined"
          @click:clear="clearDate"
          @keyup.enter="search"
        />
      </div>
    </template>
    <vue-date-picker
      v-model="date"
      :enable-time-picker="false"
      :max-date="max"
      :min-date="min"
      auto-apply
      inline
      no-title
      range
    />
  </v-menu>
</template>
