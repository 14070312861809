<script>
import { TripStatus, TripStatusMap } from "@/data/trip";

export default {
  name: "TripStatus",
  props: {
    trip: Object,
  },
  computed: {
    classes() {
      switch (this.trip.status) {
        case TripStatus.notStarted:
          return "bg-grey-lighten-3 text-grey-darken-3";
        case TripStatus.inProgress:
          return "bg-amber-lighten-2 text-grey-darken-3";
        case TripStatus.completed:
          return "bg-blue-lighten-2 text-white";
        default:
          return "";
      }
    },
    status() {
      return TripStatusMap.find((s) => s.id === this.trip.status)?.name;
    },
  },
};
</script>

<template>
  <div :class="classes" class="status-label rounded-lg caption px-2" v-bind="$props">
    {{ status }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  height: 16px;
  line-height: 16px !important;
  font-size: 11px !important;
}
</style>
