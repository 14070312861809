<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageHeader",
  props: {
    header: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <div class="page-header d-flex align-center justify-space-between mb-2">
    <div>
      <div class="title font-weight-medium">{{ header }}</div>
      <slot></slot>
    </div>
    <div>
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  height: 44px;

  .title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
  }
}
</style>
