<script>
import { mapState } from "vuex";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DeviceInfo from "@/views/users/components/DeviceInfo.vue";

export default {
  name: "MobileStatus",
  components: { DeviceInfo },
  mixins: [DateTimeMixin],
  inheritAttrs: false,
  props: {
    user: Object,
  },
  computed: {
    ...mapState(["dispatcher"]),
    trackingStatus() {
      switch (this.user.mobile_user_tracking_status) {
        case "available":
          return "Active";
        case "not_available":
          return "Not available";
        case "denied":
          return "Denied";
        case "not_provided_in_use":
        case "not_provided_always":
          return "Permissions required";
        default:
          return "—";
      }
    },
    lastTrackingTime() {
      return this.user.mobile_user_tracking_status_dt ? this.timeAgo(this.user.mobile_user_tracking_status_dt) : "—";
    },
    lastOnline() {
      return this.user.mobile_user_last_online ? this.timeAgo(this.user.mobile_user_last_online) : "—";
    },
  },
};
</script>

<template>
  <v-tooltip v-if="user.mobile_user_has_password" :disabled="!user.mobile_user_last_online" location="top start">
    <template #activator="{ props }">
      <v-icon
        :class="$attrs.class"
        :color="user.mobile_user_last_online ? 'success' : 'grey'"
        icon="mdi-cellphone"
        v-bind="props"
      ></v-icon>
    </template>
    <div>
      Device:
      <device-info :user="user"></device-info>
    </div>
    <div>Last online: {{ lastOnline }}</div>
    <div>GPS tracking: {{ trackingStatus }}, {{ lastTrackingTime }}</div>
  </v-tooltip>
</template>

<style lang="scss" scoped></style>
