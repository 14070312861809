<script>
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CoordinatorsSearchForm",
  components: { TableFilter, TableFilterRow, SearchField },
  mixins: [BaseView],
  emits: ["search"],
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params = this.getSearchParams("coordinators");
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams("coordinators", this.params);
      this.$emit("search", this.params);
    },
  },
});
</script>

<template>
  <table-filter :expandable="false" @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col>
          <search-field
            id="search--name-field"
            v-model="params.name"
            :label="$t('general.full-name')"
            @search="search"
          />
        </v-col>
        <v-col>
          <search-field
            id="search--phone-field"
            v-model="params.phone"
            :label="$t('general.phone')"
            mask="1 ### ### ####"
            @search="search"
          />
        </v-col>
        <v-col>
          <search-field
            id="search--email-field"
            v-model="params.email"
            :label="$t('general.email')"
            type="email"
            @search="search"
          />
        </v-col>
      </table-filter-row>
    </template>
  </table-filter>
</template>
