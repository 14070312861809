import { BaseApi } from "@/api/base";

export class TripsApi extends BaseApi {
  getTripById(tripId) {
    return this.client.get("/trips/" + tripId);
  }

  upsertTrip(trip) {
    return this.client.save("/trips", trip);
  }

  confirmRouteDispatch(tripId, routeId, data) {
    return this.client.post("/trips/" + tripId + "/routes/" + routeId + "/confirm-dispatch", data);
  }

  checkInRouteStop(tripId, routeStopId, data) {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/check-in", data);
  }

  getRouteStop(tripId, routeStopId) {
    return this.client.get("/trips/" + tripId + "/route-stops/" + routeStopId);
  }

  loadRouteStop(tripId, routeStopId, data) {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/load", data);
  }

  uploadRouteStopFile(tripId, routeStopId, fileType, file, bol) {
    const data = new FormData();
    data.append("file", file);
    if (bol) {
      data.append("bol", bol);
    }

    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/files/" + fileType, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  unloadRouteStop(tripId, routeStopId, data) {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/unload", data);
  }

  checkOutRouteStop(tripId, routeStopId, data) {
    return this.client.post("/trips/" + tripId + "/route-stops/" + routeStopId + "/good-to-go", data);
  }

  cancelDispatchConfirmation(tripId, routeId) {
    return this.client.delete("/trips/" + tripId + "/routes/" + routeId + "/confirm-dispatch");
  }

  cancelCheckIn(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/check-in");
  }

  cancelLoad(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/load");
  }

  cancelLoadVerification(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/load-verify");
  }

  cancelUnload(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/unload");
  }

  cancelUnloadVerification(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/unload-verify");
  }

  cancelGoodToGo(tripId, stopId) {
    return this.client.delete("/trips/" + tripId + "/route-stops/" + stopId + "/good-to-go");
  }

  cancelTravelOrder(tripId, travelOrderId, data) {
    return this.client.post("/trips/" + tripId + "/travel-orders/" + travelOrderId + "/cancel", data);
  }

  getTripFiles(tripId) {
    return this.client.get("/trips/" + tripId + "/files");
  }
}
