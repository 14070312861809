const GalleryStore = {
  state: {
    media: [],
    mediaId: null,
  },
  mutations: {
    setMedia(state, payload) {
      state.media = payload;
    },
    setMediaId(state, id) {
      state.mediaId = id;
    },
  },
};

export default GalleryStore;
