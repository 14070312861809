<script>
import DispatchConfirmationStatus from "@/views/trips/components/DispatchConfirmationStatus.vue";
import DispatchConfirmationAction from "@/views/trips/view/actions/DispatchConfirmationAction.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import StepperItem from "@/views/trips/components/StepperItem.vue";
import DistanceLine from "@/views/trips/components/DistanceLine.vue";
import RouteStopActions from "@/views/trips/components/RouteStopActions.vue";

export default {
  name: "DeadheadStop",
  components: { RouteStopActions, DistanceLine, StepperItem, DispatchConfirmationAction, DispatchConfirmationStatus },
  mixins: [GoogleMapsMixin],
  props: {
    route: Object,
    trip: Object,
    undoable: Boolean,
  },
  emits: ["change"],
  methods: {
    onChange() {
      this.$emit("change");
    },
  },
};
</script>

<template>
  <stepper-item :active="!route.dispatch_confirmed" :complete="route.dispatch_confirmed" :number="0" first>
    <div class="rounded bg-grey-lighten-4 pa-4 flex-1 mb-2">
      <div class="d-flex align-center justify-space-between">
        <div class="text-blue-grey-darken-1 font-size-12 font-weight-600">
          <v-icon class="mr-3" icon="mdi-truck-outline"></v-icon>
          Deadhead
        </div>
        <div class="d-flex align-center" data-qa="deadhead-dispatch-status">
          <dispatch-confirmation-status :is-confirmed="route.dispatch_confirmed"></dispatch-confirmation-status>
          <route-stop-actions
            v-if="undoable"
            :route="route"
            :trip="trip"
            :stop="{}"
            class="ml-3"
            @change="onChange"
          ></route-stop-actions>
        </div>
      </div>
      <div v-if="route.dispatch_address" class="d-flex align-center mt-3 mb-4">
        <div class="mr-2" data-qa="deadhead-start-location-route">{{ route.dispatch_address }}</div>
        <a :href="getPlaceUrl([route.dispatch_coordinates.lon, route.dispatch_coordinates.lat])" target="_blank">
          <v-icon color="success" icon="mdi-map-marker-radius-outline" size="16"></v-icon>
        </a>
      </div>
      <dispatch-confirmation-action
        :route="route"
        :trip="trip"
        class="mt-3"
        @dispatched="onChange"
      ></dispatch-confirmation-action>
    </div>
    <distance-line :value="route.empty_miles"></distance-line>
  </stepper-item>
</template>

<style lang="scss" scoped></style>
