const GoogleMapsMixin = {
  methods: {
    getPlaceUrl(location, zoom = -1) {
      let lat = location[1] || location.lat;
      let lon = location[0] || location.lon;
      let url = `https://www.google.com/maps/place/${lat},${lon}`;
      if (zoom > 0) {
        url += `/@${lat},${lon},${zoom}z`;
      }
      return url;
    },
    getRouteUrl(location1, location2) {
      if (!location1 || !Array.isArray(location1)) return "#";
      if (!location2 || !Array.isArray(location2)) return "#";

      const baseUrl = "https://www.google.com/maps/dir/?api=1&travelmode=driving";
      // use lat/lng for Google
      return (
        baseUrl + "&origin=" + location1[1] + "," + location1[0] + "&destination=" + location2[1] + "," + location2[0]
      );
    },
    getPlaceUrlByAddress(address) {
      return encodeURI(`https://www.google.com/maps/search/?api=1&query=${address}`);
    },
  },
};

export default GoogleMapsMixin;
