<script>
export default {
  name: "OmniTextarea",
  props: {
    id: String,
    modelValue: String,
    disabled: Boolean,
    loading: Boolean,
    readonly: Boolean,
    rows: {
      type: String,
      default: "1",
    },
    maxRows: {
      type: String,
      default: "7",
    },
    placeholder: String,
    label: String,
    customNewLine: Boolean,
  },
  emits: ["update:modelValue", "change", "submit", "clear", "paste"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    valueChanged(value) {
      this.$emit("change", value);
    },
    clear() {
      this.model = "";
      this.$emit("clear");
    },
    async handleEnterKey(e) {
      if (!this.customNewLine) {
        return;
      }
      e.preventDefault();
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        const cursorPosition = e.target.selectionStart;
        this.model = (this.model || "").slice(0, cursorPosition) + "\n" + (this.model || "").slice(cursorPosition);
        this.$nextTick(() => {
          e.target.selectionEnd = cursorPosition + 1;
          // Hack for scrolling to cursor position.
          e.target.blur();
          e.target.focus();
        });
      } else {
        this.$emit("submit", this.model);
      }
    },
    onPaste(event) {
      this.$emit("paste", event);
    },
  },
};
</script>

<template>
  <div class="position-relative">
    <v-textarea
      :id="id"
      v-model="model"
      :auto-grow="true"
      :class="{ readonly: readonly }"
      :clearable="false"
      :disabled="disabled"
      :label="label"
      :loading="loading"
      :max-rows="maxRows"
      :placeholder="placeholder"
      :readonly="readonly"
      :rows="rows"
      color="primary"
      density="compact"
      hide-details
      variant="outlined"
      @change="valueChanged"
      @paste="onPaste"
      @keydown.enter="handleEnterKey"
    />
    <div class="textarea__buttons">
      <div v-if="model && model.length > 0 && !readonly && !disabled" class="textarea__control" @click="clear()">
        <v-icon>mdi-close</v-icon>
      </div>
      <div class="d-flex">
        <div class="textarea__control">
          <slot name="button:suffix"></slot>
        </div>
        <div class="textarea__control">
          <slot name="button:suffix2"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.textarea__buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  height: calc(100% - 15px);
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  line-height: 1.8;

  .textarea__control {
    cursor: pointer;
    transition: all ease-in-out 200ms;

    &:hover {
      color: $grey;
    }
  }
}

.v-input.readonly {
  background-color: $grey-input-bg;
}

:deep(.v-textarea .v-field__input) {
  overflow-y: auto !important;
  padding-right: 48px;
  margin-right: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  // check how to get rid of it.
  -webkit-mask-image: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.5);
    border-radius: 10px;
    margin-right: 2px;
  }
}
</style>
