<script>
import BaseView from "@/views/BaseView.vue";

export default {
  name: "TruckSelect",
  mixins: [BaseView],
  props: {
    modelValue: Number,
    label: String,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      numberQuery: "",
      trucks: [],
      isLoading: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    debounceRequest() {
      return this.debounce(async () => {
        this.isLoading = true;
        try {
          const resp = await this.$api.trucks.findTrucks({ number: this.numberQuery });
          this.trucks = resp.data.items;
        } catch (error) {
          console.error("Error load trucks list: ", error);
        } finally {
          this.isLoading = false;
        }
      });
    },
  },
  watch: {
    async numberQuery(query) {
      if (!query || query.length < 2) return;
      this.debounceRequest();
    },
  },
};
</script>

<template>
  <v-autocomplete
    v-model="model"
    v-model:search="numberQuery"
    :clearable="true"
    :items="trucks"
    :label="label || $t('trucks.number')"
    :loading="isLoading"
    color="primary"
    density="compact"
    hide-details
    item-title="number"
    item-value="id"
    variant="outlined"
  />
</template>

<style scoped></style>
