import Axios from "axios";
import { TokenStorage } from "@/services/tokenStorage";

const BASE_URL = location.protocol + "//" + location.host + "/api/v1";

export class Response {
  data = null;
  status = null;
  error = null;
  success = false;

  constructor(axiosResponse, error, success) {
    this.data = axiosResponse.data;
    this.status = axiosResponse.status;
    this.error = error;
    this.success = success;
  }

  static unknownError() {
    return new Response({ data: null, status: null }, null, false);
  }
}

export class ApiClient {
  constructor() {
    this.api = Axios.create({
      baseURL: BASE_URL,
      timeout: 30000,
      headers: { "Content-Type": "application/json" },
    });

    this.api.interceptors.request.use((request) => {
      if (request.data) {
        this.removeVirtualProps(request.data);
      }
      return request;
    });

    // load saved token
    let token = this.getToken();
    if (token) {
      this.setToken(token);
    }
  }

  buildParams(params) {
    const searchParams = new URLSearchParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null) return;

        if (Array.isArray(value)) {
          value.forEach((value) => searchParams.append(key, value.toString()));
        } else {
          searchParams.append(key, value.toString());
        }
      });
    }

    return searchParams ? "?" + searchParams.toString() : "";
  }

  async delete(url) {
    try {
      const response = await this.api.delete(url);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "DELETE");
    }
  }

  async get(url, params = {}, config = {}) {
    try {
      const response = await this.api.get(url + this.buildParams(params), config);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "GET");
    }
  }

  async patch(url, data) {
    try {
      const response = await this.api.patch(url, data);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "PATCH");
    }
  }

  async post(url, data, options) {
    try {
      const response = await this.api.post(url, data, options);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "POST");
    }
  }

  async put(url, data) {
    try {
      const response = await this.api.put(url, data);
      return new Response(response, null, true);
    } catch (error) {
      return this.handleRequestError(error, "PUT");
    }
  }

  async save(url, obj) {
    if (obj.id) {
      return await this.put(url + "/" + obj.id, obj);
    } else {
      return await this.post(url, obj);
    }
  }

  saveToken(token) {
    if (token) {
      TokenStorage.saveToken(token);
    } else {
      TokenStorage.removeToken();
    }
    this.setToken(token);
  }

  setRouter(router) {
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          return router.replace({ name: "login" });
        }
        return Promise.reject(error);
      },
    );
  }

  setToken(token) {
    if (token) {
      this.api.defaults.headers.common["Authorization"] = token;
    } else {
      delete this.api.defaults.headers.common["Authorization"];
    }
  }

  getToken() {
    return TokenStorage.getToken();
  }

  getWSChatUrl() {
    return BASE_URL.replace("http", "ws") + "/chat/ws";
  }

  removeVirtualProps(obj) {
    for (let k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        this.removeVirtualProps(obj[k]);
      } else {
        if (k[0] === "$") {
          delete obj[k];
        }
      }
    }
  }

  handleRequestError(error, requestType) {
    if (error.request && error.response) {
      console.error(
        `Error processing ${requestType} ${error.request.responseURL} with status ${error.response.status}`,
      );
      return new Response(error.response, error, false);
    }
    return Response.unknownError();
  }
}
