<script>
export default {
  name: "UserViewDriverNote",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div v-if="user.is_driver" class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4">
    <div class="text-grey-darken-3 mb-3 font-weight-medium">Driver note</div>
    <div class="driver-note__text bg-grey-lighten-4 pt-4 pb-4 pr-3 pl-3">
      {{ $filters.mdash(user.driver_note) }}
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
