import { BaseApi } from "@/api/base";

export class FilesApi extends BaseApi {
  getFileById(fileId) {
    return this.client.get("files/" + fileId);
  }

  uploadFile(folder, file, tags = []) {
    const data = new FormData();
    data.append("file", file);
    data.append("folder", folder);
    for (const tag of tags) {
      data.append("tags", tag);
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return this.client.post("files", data, options);
  }

  deleteFile(fileId) {
    return this.client.delete("files/" + fileId);
  }
}
