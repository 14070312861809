<script>
export default {
  name: "FormActions",
  inject: ["formSymbol"],
  emits: ["cancel", "submit"],
  mounted() {
    if (!this.formSymbol) {
      throw new Error("FormActions must be a child of OmniForm");
    }
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<template>
  <div class="d-flex justify-end pa-5 bg-white actions">
    <v-btn
      id="cancel-btn"
      class="mr-3 text-primary text-uppercase font-weight-600"
      height="36"
      variant="flat"
      @click="cancel"
    >
      {{ $t("general.cancel") }}
    </v-btn>
    <v-btn
      id="save-btn"
      class="pr-6 pl-6 font-weight-600 text-uppercase"
      color="primary"
      height="36"
      variant="flat"
      @click="submit"
    >
      {{ $t("general.save") }}
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.actions {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  box-shadow: 0 -8px 20px -7px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
</style>
