import dayjs from "dayjs";

export default {
  date(value, showTime = false, timezone = null) {
    if (!value) return "—";
    let format = "MM/DD/YYYY";
    let date = dayjs(value);
    if (showTime === true) {
      format += " HH:mm";
    }
    let result = date.format(format);
    if (timezone !== null) {
      date = date.tz(timezone);
      result = date.format(format) + " " + date.offsetName();
    }
    return result;
  },
  dateWithTimezone(value, toStandardTimezone = false) {
    if (!value) return "—";
    let format = "MM/DD/YYYY HH:mm";
    const dateChunks = value.split(" ");
    let date = dayjs(dateChunks[0]);
    let result = "";
    if (toStandardTimezone) {
      date = date.tz("America/New_York");
      result = date.format(format) + " " + date.offsetName();
    } else {
      result = date.format(format) + " " + dateChunks[1];
    }
    return result;
  },
  format(value, fractionDigits = 2) {
    let num = parseFloat(value);
    if (num !== Math.floor(num)) num = num.toFixed(fractionDigits);
    return num;
  },
  price(value) {
    let num = parseFloat(value);
    return "$" + num.toFixed(2);
  },
  initials(name) {
    if (!name) return "";
    if (name.indexOf(" ") > -1) {
      let names = name.split(" ");
      name = names[0].substring(0, 1).toUpperCase() + names[1].substring(0, 1).toUpperCase();
    } else {
      name = name.substring(0, 2).toUpperCase();
    }
    return name;
  },
  miles(value) {
    if (value === null) return "";
    return (parseFloat(value) / 1609.344).toFixed(2);
  },
  hours(value) {
    if (!value) return "";
    return (parseFloat(value) / 60 / 60).toFixed(2);
  },
  mdash(value) {
    return value ? value : "-";
  },
  roundFloat(value, count) {
    if (isNaN(value)) return 0;
    let num = parseFloat(value);
    if (num !== Math.ceil(num)) num = num.toFixed(count);
    return num;
  },
  round(n) {
    return this.roundFloat(n, 2);
  },
  upper(s) {
    return s ? s.toUpperCase() : "";
  },
  ellipsis(string, length = 40, type = "end", ellipsis = "...") {
    if (string.length <= length) {
      return string;
    }
    const cropLength = length - ellipsis.length;
    switch (type) {
      case "begin":
        return ellipsis + string.slice(-cropLength);
      case "middle":
        return string.slice(0, Math.floor(cropLength / 2)) + ellipsis + string.slice(-Math.floor(cropLength / 2));
      case "end":
        return string.slice(0, cropLength) + ellipsis;
    }
  },
  bytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },
  booleanValue(value, trueValue = "true", falseValue = "false") {
    return value ? trueValue : falseValue;
  },
};
