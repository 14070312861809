<script>
import UserRole from "@/views/users/components/UserRole.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import LanguageTag from "@/components/status/LanguageTag.vue";

export default {
  name: "TruckDriverBlock",
  components: { LanguageTag, PhoneNumber, UserStatus, UserRole },
  props: {
    driver: {
      type: Object,
      required: true,
    },
    isFinanciallyResponsible: Boolean,
  },
};
</script>
<template>
  <div class="bg-grey-lighten-4 rounded-lg pa-3">
    <div class="d-flex align-center mb-3">
      <user-role :user="driver" class="mr-2"></user-role>
      <span v-if="isFinanciallyResponsible" class="currency-icon mr-2"></span>
      <router-link
        :to="{ name: 'user-view', params: { id: driver.id } }"
        class="text-primary mr-2 text-decoration-none"
        target="_blank"
      >
        {{ driver.name }}
      </router-link>
      <user-status :status="driver.is_owner ? driver.owner_status : driver.driver_status"></user-status>
    </div>
    <div class="d-flex align-center">
      <phone-number :number="driver.phone" class="text-primary mr-2"></phone-number>
      <language-tag :language="driver.language"></language-tag>
    </div>
  </div>
</template>
