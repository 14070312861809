<script>
export default {
  name: "SidebarToggle",
  inject: ["sidebarLayoutSymbol", "controller"],
  props: {
    size: String,
  },
  computed: {
    isVisible: {
      get() {
        return this.controller.getValue() || false;
      },
      set(value) {
        this.controller.next(value);
      },
    },
  },
  mounted() {
    if (!this.sidebarLayoutSymbol) {
      throw new Error("SidebarToggle must be a child of SidebarLayout");
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<template>
  <v-btn
    :size="size"
    :variant="isVisible ? 'flat' : 'outlined'"
    class="toggle--button"
    color="primary"
    @click="toggleVisibility"
  >
    <v-icon> mdi-dock-right</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.toggle--button {
  width: 36px;
  min-width: 0;
}
</style>
