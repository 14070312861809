<script>
import { mapMutations, mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import OmniTable from "@/components/Table.vue";
import PageHeader from "@/components/PageHeader.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import ArchiveDispatcherAction from "@/views/dispatchers/components/ArchiveDispatcherAction.vue";
import DispatcherEditDialog from "@/views/dispatchers/components/DispatcherEditDialog.vue";
import DispatcherRemoveDialog from "@/views/dispatchers/components/DispatcherRemoveDialog.vue";

export default {
  name: "DispatchersPage",
  components: {
    DispatcherRemoveDialog,
    DispatcherEditDialog,
    ArchiveDispatcherAction,
    SearchField,
    TableFilterRow,
    TableFilter,
    PageHeader,
    OmniTable,
  },
  mixins: [BaseView, ValidationMixin],
  data() {
    return {
      perm: "dispatchers",
      headers: [
        { title: this.$t("general.full-name"), key: "name" },
        { title: this.$t("dispatchers.roles"), key: "roles" },
        { title: this.$t("general.email"), key: "email" },
        { title: this.$t("general.phone"), key: "phone" },
        { title: this.$t("dispatchers.phone-ext"), key: "phone_ext" },
        {
          title: "Actions",
          key: "actions",
          align: "center",
          width: 96,
          sortable: false,
        },
      ],
      editDialog: false,
      removeDialog: false,
      filter: { search: "" },
      data: { total: 0 },
      selectedDispatcher: {},
      loading: false,
      archived: false,
    };
  },
  computed: {
    ...mapState(["mobile", "dispatchers"]),
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
    rolesMap() {
      return this.arrayToMap(this.roles);
    },
  },
  watch: {
    archived() {
      this.refresh();
    },
  },
  created() {
    this.setTitle("Dispatchers");
    this.refresh();
  },
  methods: {
    ...mapMutations(["showSnackbarError", "showSnackbarSuccess"]),
    async refresh() {
      const params = { ...this.filter, ...this.params };
      params.archived = this.archived;

      this.loading = true;
      try {
        const resp = await this.$api.dispatchers.findDispatchers(params);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load dispatchers list: ", error);
      }
      this.loading = false;
    },
    add() {
      this.selectedDispatcher = {};
      this.editDialog = true;
    },
    getRoles(roles) {
      return roles.map((r) => this.rolesMap[r]).join(", ");
    },
    edit(item) {
      this.selectedDispatcher = item;
      this.editDialog = true;
    },
    showRemoveConfirmation(item) {
      this.selectedDispatcher = item;
      this.removeDialog = true;
    },
    search() {
      this.setSearchParams("dispatchers", this.filter);
      this.refresh();
    },
    clearSearch() {
      this.filter = {};
      this.search();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header header="Dispatchers">
      <template v-if="canEdit" #suffix>
        <v-btn id="create-dispatcher-btn" class="text-uppercase" color="success" variant="flat" @click="add">
          {{ $t("general.create-new") }}
        </v-btn>
      </template>
    </page-header>
    <table-filter :expandable="false" @apply="search" @clear="clearSearch">
      <template #header>
        <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
          <v-col cols="6" sm="2">
            <search-field v-model="filter.name" label="Name" @search="search" />
          </v-col>
          <v-col cols="6" sm="2">
            <search-field v-model="filter.email" label="Email" @search="search" />
          </v-col>
        </table-filter-row>
      </template>
    </table-filter>

    <omni-table
      v-model:archived="archived"
      :footer-props="{ 'items-per-page-options': pagination.items_per_page }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total"
      :items-per-page="params.page_size"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      class="dispatchers-table"
      show-archive
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.name`]="{ item }"> {{ item.first_name }} {{ item.last_name }}</template>
      <template #[`item.email`]="{ item }">
        <span :class="{ 'text-error': !item.email_confirmed }">{{ item.email }}</span>
      </template>
      <template #[`item.roles`]="{ item }">
        {{ getRoles(item.roles) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <div v-if="canEdit" class="d-flex justify-space-evenly">
          <v-btn
            v-if="!item.archived"
            color="primary"
            icon="mdi-pencil-outline"
            size="x-small"
            variant="text"
            @click="edit(item)"
          ></v-btn>
          <archive-dispatcher-action
            v-if="item.email_confirmed"
            :dispatcher="item"
            @change="refresh"
          ></archive-dispatcher-action>
          <v-btn
            v-else
            color="primary"
            icon="mdi-delete-outline"
            size="x-small"
            variant="text"
            @click="showRemoveConfirmation(item)"
          ></v-btn>
        </div>
      </template>
    </omni-table>
    <dispatcher-edit-dialog
      v-model="editDialog"
      :dispatcher="selectedDispatcher"
      @change="refresh"
    ></dispatcher-edit-dialog>
    <dispatcher-remove-dialog
      v-model="removeDialog"
      :dispatcher="selectedDispatcher"
      @remove="refresh"
    ></dispatcher-remove-dialog>
  </v-container>
</template>
