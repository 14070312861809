<script>
export default {
  name: "DetachTruckDialog",
  props: {
    truck: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <div class="text-grey-darken-1 py-4">
    Are you sure want to remove <span class="text-primary">Truck #{{ truck?.number }}</span> from this route?
  </div>
</template>
