import { BaseApi } from "@/api/base";

export class ChatApi extends BaseApi {
  updateThread(threadId, data) {
    return this.client.patch("chat/threads/" + threadId, data);
  }

  noResponseThread(threadId) {
    return this.client.post("chat/threads/" + threadId + "/no-response");
  }

  getThreads(offset, limit = 100, timeFrom = null) {
    return this.client.get("chat/threads", { offset, limit, time_from: timeFrom });
  }

  findThreadByUserId(userId) {
    return this.client.get("chat/threads/user/" + userId);
  }

  getThreadMessages(threadId, limit = 100, offset = 0) {
    return this.client.get("chat/threads/" + threadId + "/messages", {
      limit,
      offset,
    });
  }

  sendMessage(threadId, message) {
    return this.client.post("chat/threads/" + threadId + "/messages", message);
  }

  sendFile(threadId, data) {
    return this.client.post("chat/threads/" + threadId + "/file", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
