<script>
import BaseView from "@/views/BaseView.vue";
import { UtilsMixin } from "@/mixins/utils.mixin";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import countries from "@/data/countries";
import { mapMutations } from "vuex";
import FmcsaNumberInput from "@/views/companies/components/FmcsaNumberInput.vue";
import OmniDialog from "@/components/Dialog.vue";
import AddressField from "@/components/AddressField.vue";

export default {
  name: "CustomerSelect",
  components: { AddressField, OmniDialog, FmcsaNumberInput },
  mixins: [BaseView, UtilsMixin, MasksMixin, ValidationMixin],
  props: {
    name: String,
    label: String,
    modelValue: Number,
    rules: Array,
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change", "update"],
  data() {
    return {
      model: null,
      addDialog: false,
      fmcsaTypes: ["MC", "FF", "MX"],
      sameAddress: false,
      isLoading: false,
      search: null,
      items: [],
      item: {},
    };
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      this.fetchData(val);
    },
    async modelValue() {
      this.model = this.modelValue;
      const item = this.items.find((u) => u.id === this.modelValue);
      if (!item) {
        this.preload(this.modelValue);
      } else {
        this.updateItem(item);
      }
    },
    item: {
      deep: true,
      handler() {
        if (this.sameAddress) {
          this.syncAddress();
        }
      },
    },
    sameAddress() {
      if (this.sameAddress) {
        this.syncAddress();
      }
    },
  },
  async mounted() {
    this.model = this.modelValue;
    this.preload(this.modelValue);
    this.fetchData(null);
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    countries() {
      return countries;
    },
    addCustomer() {
      this.item = {
        status: "a",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        address_country: "US",
        billing_address_city: "",
        billing_address_state: "",
        billing_address_zip_code: "",
        billing_address_country: "US",
      };
      this.addDialog = true;
    },
    changed(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
      const customer = this.items.find((c) => c.id === value);
      if (customer) {
        this.item = customer;
        this.$emit("update", customer);
      }
    },
    fetchData(term) {
      const params = { name: term, page_size: 10 };

      this.isLoading = true;
      this.$api.company
        .findCustomers(params)
        .then((resp) => {
          this.items = this.mergeById(this.items, resp.data.items);
        })
        .catch((err) => {
          console.error("Error loading customers", err);
        })
        .finally(() => (this.isLoading = false));
    },
    preload(id) {
      if (!id) return;

      this.isLoading = true;
      this.$api.company
        .findCustomerById(id)
        .then((resp) => {
          this.updateItem(resp.data);
        })
        .catch((err) => {
          console.error("Error loading customer", err);
        })
        .finally(() => (this.isLoading = false));
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) return;

      if (this.sameAddress) {
        this.syncAddress();
      }

      const resp = await this.$api.company.upsertCustomer(this.item);
      if (resp.success) {
        this.addDialog = false;
        this.updateItem(resp.data);
        this.changed(this.item.id);
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    syncAddress() {
      this.item.billing_address_line_1 = this.item.address_line_1;
      this.item.billing_address_line_2 = this.item.address_line_2;
      this.item.billing_address_city = this.item.address_city;
      this.item.billing_address_state = this.item.address_state;
      this.item.billing_address_zip_code = this.item.address_zip_code;
      this.item.billing_address_country = this.item.address_country;
    },
    updateItem(item) {
      this.item = item;
      this.items = this.mergeById(this.items, [item]);
      this.$emit("update", item);
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <v-autocomplete
      v-model="model"
      v-model:search="search"
      :class="{ required: required }"
      :clearable="true"
      :items="items"
      :label="label"
      :loading="isLoading"
      :name="name"
      :rules="rules"
      class="mb-0 width-100 flex-shrink-1"
      color="primary"
      density="compact"
      hide-details="auto"
      hide-no-data
      hide-selected
      item-title="name"
      item-value="id"
      variant="outlined"
      @update:model-value="changed"
    >
    </v-autocomplete>

    <v-btn v-if="editable" class="ml-1" color="primary" icon="mdi-plus" variant="text" @click="addCustomer"></v-btn>

    <omni-dialog
      v-model="addDialog"
      primary-action-label="Create"
      size="large"
      title="Create company"
      @primary-action:click="save"
    >
      <div class="my-4">
        <v-form id="add-customer" ref="form" lazy-validation>
          <div class="mb-6">
            <div class="caption text-grey-darken-1 mb-4">Company information</div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.name"
                  class="required"
                  color="primary"
                  density="compact"
                  hide-details
                  label="Customer Name"
                  required
                  variant="outlined"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="item.fmcsa_type"
                  :items="fmcsaTypes"
                  :label="$t('company.fmcsa_type')"
                  :rules="[requiredValidator]"
                  autocomplete="off1"
                  class="required"
                  color="primary"
                  density="compact"
                  hide-details="auto"
                  name="fmcsa_type"
                  variant="outlined"
                />
              </v-col>
              <v-col>
                <fmcsa-number-input
                  v-model="item.fmcsa_number"
                  :rules="[requiredValidator]"
                  class="required"
                  name="fmcsa_number"
                />
              </v-col>
            </v-row>
          </div>
          <div class="mb-6">
            <address-field v-model="item" hide-location label="Physical Address"></address-field>
          </div>

          <div class="mb-6" data-qa="billing-address">
            <address-field
              v-model="item"
              :disabled="sameAddress"
              hide-location
              label="Billing Address"
              model-prefix="billing_"
            >
              <template #label:suffix>
                <v-checkbox
                  v-model="sameAddress"
                  class="text-grey-darken-2 mt-0 pt-0 billing-checkbox"
                  color="primary"
                  hide-details=""
                  label="Billing address same as physical"
                />
              </template>
            </address-field>
          </div>
        </v-form>
      </div>
    </omni-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog-btn {
  padding: 0 !important;
  margin-left: 12px;
  min-width: auto !important;
  height: auto !important;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
</style>
