<script>
import ValidationMixin from "@/mixins/validation.mixin";
import { FacilityType, FacilityTypeMap } from "@/data/facility";

export default {
  name: "SelectFacilityType",
  mixins: [ValidationMixin],
  props: {
    modelValue: null,
    required: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      options: FacilityTypeMap,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    if (!this.model) {
      this.model = FacilityType.standard;
    }
  },
};
</script>

<template>
  <v-select
    v-model="model"
    :class="{ required: required }"
    :items="options"
    :rules="required ? [requiredValidator] : []"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    label="Type"
    variant="outlined"
  />
</template>

<style lang="scss" scoped></style>
