export const TruckDoorType = [
  { id: "s", name: "Swing" },
  { id: "r", name: "Roll" },
];

export const TruckEquipment = [
  { id: "ar", name: "Air Ride" },
  { id: "lg", name: "Lift Gate" },
  { id: "icc_bar", name: "ICC Bar" },
  { id: "vet", name: "Vertical E-Track" },
  { id: "het", name: "Horizontal E-Track" },
];

export const TruckAdditionalEquipment = [
  { id: "pj", name: "Pallet Jack" },
  { id: "ppe", name: "PPE" },
  { id: "straps", name: "Straps" },
  { id: "lg", name: "Landing Gears" },
  { id: "dhr", name: "Dock Height Wheel Risers (Plastic)" },
  { id: "dhm", name: "Dock Height Wheel Risers (Metal)" },
  { id: "ramps", name: "Portable Loading Ramps" },
];

export const TruckType = [
  { id: "sts", name: "Semi truck (Sleeper)", hasTrailer: false },
  { id: "std", name: "Semi truck (Day cab)", hasTrailer: false },
  { id: "cv", name: "Cargo Van", hasTrailer: true },
  { id: "sv", name: "Sprinter Van", hasTrailer: true },
  { id: "bt", name: "Box Truck", hasTrailer: true },
  { id: "st", name: "Straight Truck", hasTrailer: true },
  { id: "cs", name: "Curtain side", hasTrailer: true },
  { id: "rsv", name: "Sprinter van reefer", hasTrailer: true },
  { id: "rbt", name: "Box truck reefer", hasTrailer: true },
  { id: "rcv", name: "Cargo van reefer", hasTrailer: true },
  { id: "rst", name: "Straight truck reefer", hasTrailer: true },
];

export const TrailerType = [
  { id: "dv", name: "Dry van" },
  { id: "r", name: "Reefer" },
  { id: "sd", name: "Step deck" },
  { id: "f", name: "Flatbed" },
];
