<script>
import { mapMutations } from "vuex";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import ChatMessage from "@/views/chats/Message.vue";
import FileMixin from "@/mixins/file.mixin";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const showDownButtonOffset = 350;

export default {
  name: "MessagesList",
  components: { ChatMessage },
  mixins: [ChatMixin, FileMixin],
  props: {
    thread: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      scrollTop: 0,
      doNotDownloadNewMessagesOnScroll: false,
      canLoadMore: true,
      oldScrollBottom: 0,
      initialized: false,
      showDownButton: false,
      lastMessage: null,
      newMessagesCount: 0,
    };
  },
  computed: {
    messages() {
      const thread = this.$store.getters.getThread(this.thread.id);
      return thread ? thread.messages : [];
    },
    mediaMessages() {
      return this.messages
        .filter((m) => m.type === "i")
        .map((m) => {
          return { id: m.id, url: m.url };
        });
    },
    showBottomLine() {
      return this.oldScrollBottom > 32;
    },
  },
  watch: {
    thread() {
      this.lastMessage = null;
      this.showDownButton = false;
      this.newMessagesCount = 0;
      this.initialized = false;
      this.canLoadMore = true;
    },
    messages: {
      handler() {
        this.doNotDownloadNewMessagesOnScroll = true;
        // when thread changed, but messages still haven't updated, we don't need to initialize it:
        // scroll to the last one
        if (this.messages.length === 0) {
          this.removeLoading();
          return;
        }
        if (!this.initialized) {
          this.setLoading();
          this.scrollBottom();
          this.removeLoading();
          this.initialized = true;
        }

        this.handleNewMessage();
        this.doNotDownloadNewMessagesOnScroll = false;
      },
      deep: true,
    },
    scrollTop(value, oldValue) {
      if (this.doNotDownloadNewMessagesOnScroll) return;
      if (value <= 200 && oldValue > 200) {
        this.loadNextMessages();
      }
    },
  },
  methods: {
    ...mapMutations(["addOlderMessages"]),
    clickOnMediaMessage(messageId) {
      this.$store.commit("setMedia", this.mediaMessages);
      this.$store.commit("setMediaId", messageId);
    },

    handleChatScroll(event) {
      this.oldScrollBottom = this.getChatContainerScrollBottom();
      this.scrollTop = event.target.scrollTop;
      this.showDownButton = this.getChatContainerScrollBottom() > showDownButtonOffset;
      if (!this.showDownButton) {
        this.newMessagesCount = 0;
      }
    },

    getChatContainerScrollBottom() {
      if (!this.$refs.chatContainer) {
        return 0;
      }
      return (
        this.$refs.chatContainer.scrollHeight -
        this.$refs.chatContainer.scrollTop -
        this.$refs.chatContainer.clientHeight
      );
    },
    loadNextMessages() {
      if (this.loading || !this.canLoadMore) return;
      this.loading = true;
      this.$api.chat.getThreadMessages(this.thread.id, 50, this.messages.length).then((response) => {
        if (!response.data || response.data.length === 0) {
          this.canLoadMore = false;
        } else {
          const message = response.data[0];
          if (message.thread_id !== this.thread.id) {
            return;
          }
          this.oldScrollBottom = this.getChatContainerScrollBottom();
          this.addOlderMessages({ threadId: this.thread.id, messages: response.data });
          this.$nextTick(() => {
            this.restoreScroll();
          });
        }
        this.loading = false;
      });
    },
    restoreScroll() {
      this.doNotDownloadNewMessagesOnScroll = true;
      this.$refs.chatContainer.scrollTop =
        this.$refs.chatContainer.scrollHeight - this.oldScrollBottom - this.$refs.chatContainer.clientHeight;
      this.allowDownloadMessagesOnScroll();
    },

    handleNewMessage() {
      const lastMessage = this.messages[this.messages.length - 1];
      if (!this.lastMessage) {
        this.lastMessage = lastMessage;
        return;
      }
      // A new message from a user, that dispatcher haven't seen.
      if (this.lastMessage.id !== lastMessage.id) {
        this.newMessagesCount++;
        this.lastMessage = lastMessage;
        // right now message is not in html, so we can calculate what is ScrollBottom.
        if (this.getChatContainerScrollBottom() <= showDownButtonOffset) {
          // because elem is not in html, we need to wait to render it.
          this.$nextTick(() => {
            this.smoothScrollBottom();
          });
        }
      }
    },

    allowDownloadMessagesOnScroll() {
      setTimeout(() => (this.doNotDownloadNewMessagesOnScroll = false), 50);
    },

    smoothScrollBottom() {
      this.$refs.messagesList.lastElementChild?.scrollIntoView({ block: "end", behavior: "smooth" });
    },
    scrollBottom() {
      this.doNotDownloadNewMessagesOnScroll = true;
      this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;

      this.$nextTick(() => {
        if (!this.$refs.chatContainer) return;
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
        this.allowDownloadMessagesOnScroll();
      });
    },

    setLoading() {
      this.loading = true;
      this.$refs.messagesList.classList.add("hidden");
      this.$refs.chatContainer.style.overflowY = "hidden";
    },

    removeLoading() {
      this.$nextTick(() => {
        this.$refs.messagesList.classList.remove("hidden");
        this.$refs.chatContainer.style.overflowY = "auto";
        this.loading = false;
      });
    },
  },
};
</script>
<template>
  <div :class="{ 'bottom-line': showBottomLine }" class="messages-wrapper position-relative overflow-hidden h-100 px-4">
    <div ref="chatContainer" class="messages scroll-style" @scroll="handleChatScroll">
      <!-- Чаще всего ответы приходят за миллисекунды и просто мигает, чем красиво кружиться лоадер. -->
      <!--            <div class="text-center  pa-2 loader" v-if="messages === null" >-->
      <!--              <v-progress-circular indeterminate color="primary" />-->
      <!--            </div>-->
      <div ref="messagesList" class="messages-list animate-opacity">
        <chat-message
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :thread="thread"
          class="chat-message"
          @media="clickOnMediaMessage"
        />
      </div>
      <!--      не нравиться, что текст показывается, даже если ждем ответа с вебсокета с меседжами.-->
      <div v-if="messages.length === 0 && !loading" class="d-flex justify-center align-center">
        <h5>{{ $t("chats.no-messages") }}</h5>
      </div>
    </div>
    <div class="messages--bottom-actions d-flex flex-column justify-end">
      <v-btn
        v-if="showDownButton && newMessagesCount > 0"
        class="rounded-circle"
        color="primary"
        dark
        depressed="true"
        fab
        height="36px"
        size="x-small"
      >
        {{ newMessagesCount }}
      </v-btn>
      <v-btn
        v-if="showDownButton"
        class="rounded-circle mt-2"
        color="white"
        dark
        fab
        height="36px"
        size="x-small"
        @click="scrollBottom"
      >
        <v-icon color="grey" size="20"> mdi-chevron-down</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/color.scss";

.messages-wrapper {
  &.bottom-line:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 8px;
    border-bottom: 1px solid $grey-300;
  }

  .messages {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .messages--bottom-actions {
    position: absolute;
    bottom: 10px;
    right: 16px;
    z-index: 10;
  }

  .messages-list {
    .chat-message:first-child {
      margin-top: 20px;
    }
  }
}
</style>
