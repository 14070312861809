<script>
import BaseView from "@/views/BaseView.vue";
import OrdersList from "./OrdersList.vue";
import OrdersListSearchForm from "./OrdersListSearchForm.vue";
import PageHeader from "@/components/PageHeader.vue";
import LinkButton from "@/components/controls/LinkButton.vue";

export default {
  name: "OrdersPage",
  components: { LinkButton, PageHeader, OrdersListSearchForm, OrdersList },
  mixins: [BaseView],
  data() {
    return {
      perm: "orders",
      searchParams: {},
    };
  },
  computed: {
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  created() {
    this.setTitle(this.$t("orders.orders"));
  },
  methods: {
    search(params) {
      this.searchParams = { ...params };
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100 orders-page">
    <page-header :header="$t('orders.orders')">
      <template #suffix>
        <link-button :to="{ name: 'order-create' }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <orders-list-search-form @search="search" />
    <orders-list :can-edit="canEdit" :search-params="searchParams" />
  </v-container>
</template>

<style lang="scss" scoped>
.orders-page {
  & > .v-card {
    background: transparent;
  }
}
</style>
