<script>
export default {
  name: "DistanceLine",
  props: {
    value: Number,
  },
};
</script>

<template>
  <div class="distance-line-container">
    <div class="distance-line"></div>
    <div v-if="value" class="distance-value text-grey-darken-1 font-size-12 font-weight-500 bg-white px-3">
      {{ $filters.miles(value) }}
      miles
    </div>
  </div>
</template>

<style lang="scss" scoped>
.distance-line-container {
  height: 12px;
  position: relative;

  .distance-line {
    height: 6px;
    border-bottom: 1px solid #e0e0e0;
  }

  .distance-value {
    position: absolute;
    left: 40px;
    top: -4px;
  }
}
</style>
