<script>
export default {
  props: {
    count: { type: Number, default: 2 },
    field: { type: String, default: "name" },
    label: String,
    items: Array,
    route: String,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    moreItemsCount() {
      return this.items.length - this.count;
    },
    previewItems() {
      return this.items.slice(0, this.count);
    },
  },
};
</script>

<template>
  <div v-if="items.length > 0">
    <div v-if="items.length > count">
      <router-link
        v-for="(item, i) in previewItems"
        :key="item.id"
        :to="{ name: route, params: { id: item.id } }"
        class="mr-1 text-decoration-none text-primary"
      >
        {{ item[field] }}<span v-if="previewItems.length - 1 > i">,</span>
      </router-link>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :min-width="150"
        :nudge-width="150"
        :z-index="2"
        offset="10"
      >
        <template #activator="{ props }">
          <v-btn class="text-primary font-weight-600 pa-0 menu-counter" v-bind="props" variant="text">
            ({{ moreItemsCount }} more)
          </v-btn>
        </template>

        <v-card class="pa-4 rounded all-trucks">
          <div class="trucks__menu-header mb-3">
            <div class="trucks__menu-title text-grey-darken-3 subheading font-weight-medium">
              {{ label }}
            </div>
            <v-btn class="menu-counter__close pa-0" @click="menu = false"></v-btn>
          </div>
          <router-link
            v-for="item in items"
            :key="item.id"
            :to="{ name: route, params: { id: item.id } }"
            class="d-block mb-1 mt-1 text-decoration-none text-primary"
          >
            {{ item[field] }}
          </router-link>
        </v-card>
      </v-menu>
    </div>
    <div v-else>
      <router-link
        v-for="(item, i) in items"
        :key="item.id"
        :to="{ name: route, params: { id: item.id } }"
        class="mr-1 text-decoration-none text-primary"
      >
        {{ item[field] }}<span v-if="items.length - 1 > i">,</span>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.menu-counter {
  min-width: auto !important;
  height: auto !important;
}

.menu-counter__close {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 10px;
  height: 10px !important;
  min-width: 10px !important;
  background: url(@/assets/images/close.svg) no-repeat;
}

.all-trucks {
  position: relative;
}
</style>
