<script>
import AdditionalActions from "@/components/actions/AdditionalActions.vue";

export default {
  name: "TruckAdditionalActions",
  components: { AdditionalActions },
  props: {
    truck: {
      type: Object,
      required: true,
    },
  },
  emits: ["change"],
  methods: {
    async archive(onSuccess, onError) {
      const resp = await this.$api.trucks.archiveTruck(this.truck.id);
      if (resp.success) {
        onSuccess();
        this.$emit("change");
      } else {
        onError("The truck can’t be archived due to reasons:", resp.data?.detail);
      }
    },
    async unarchive() {
      await this.$api.trucks.unarchiveTruck(this.truck.id);
      this.$emit("change");
    },
  },
};
</script>

<template>
  <additional-actions
    :archive-confirmation-text="'Are you sure want to send truck ' + truck.number + ' to archive?'"
    :is-archived="truck.archived"
    :unarchive-confirmation-text="'Are you sure want to return truck ' + truck.number + ' from archive?'"
    archive-confirmation-title="Archive truck"
    unarchive-confirmation-title="Unarchive truck"
    @archive="archive"
    @unarchive="unarchive"
  ></additional-actions>
</template>

<style lang="scss" scoped></style>
