export const DateTimeMixin = {
  computed: {
    timezonesList() {
      return this.timezones.map((tz) => {
        return {
          id: tz,
          name: this.getLongTZForDate(tz),
        };
      });
    },
    timezonesShortList() {
      return this.timezones.map((tz) => {
        return {
          id: tz,
          name: this.getTZNames(tz),
        };
      });
    },
  },
  data() {
    return {
      // supported timezones
      systemDateTimeFormat: "YYYY-MM-DDTHH:mm:ss",
      timezones: [
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Anchorage",
      ],
    };
  },
  methods: {
    getTZNames(tz) {
      const startDate = new Date(2000, 1, 1);
      const endDate = new Date(2000, 7, 1);
      return `${this.getShortTZForDate(startDate, tz)}/${this.getShortTZForDate(endDate, tz)}`;
    },

    getLongTZForDate(tz) {
      return `${this.getTZNames(tz)} (${tz.replace("_", " ")})`;
    },

    getShortTZForDate(date, tz) {
      if (!tz) return "";

      let locale;
      if (tz.includes("Europe")) {
        locale = "en-GB";
      } else if (tz.includes("America")) {
        locale = "en-US";
      } else {
        locale = "en";
      }

      let [, tzName] = /.*\s(.+)/.exec(
        date.toLocaleString(locale, {
          timeZone: tz,
          timeZoneName: "short",
        }),
      );
      return tzName;
    },
    timeAgo(date) {
      let diff = this.$dayjs().diff(this.$dayjs(date), "minutes");
      if (diff === 0) {
        return "recently";
      }
      const chunks = [];
      const days = Math.floor(diff / (60 * 24));
      if (days !== 0) {
        chunks.push(days + " " + (days === 1 ? "day" : "days"));
        diff -= days * 24 * 60;
      }
      const hours = Math.floor(diff / 60);
      if (hours !== 0) {
        chunks.push(hours + " " + (hours === 1 ? "hour" : "hours"));
        diff -= hours * 60;
      }
      if (diff !== 0) {
        chunks.push(diff + " " + (diff === 1 ? "minute" : "minutes"));
      }
      return chunks.join(" ") + " ago";
    },
    dateTZ(dt, tz) {
      if (!dt) return "";
      const dm = tz ? this.$dayjs.utc(dt).tz(tz) : this.$dayjs.utc(dt);
      return dm ? dm.format("MM/DD/YYYY HH:mm z") : "";
    },
    dateToUTC(dt, tz) {
      return this.$dayjs.tz(dt, tz).utc();
    },
    dateToUTCSystemFormat(dt, tz) {
      this.dateToUTC(dt, tz).format(this.systemDateTimeFormat);
    },
  },
};
