<script>
import BaseView from "@/views/BaseView.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import DriverNotes from "@/views/drivers/DriverNotes.vue";
import UserRole from "@/views/users/components/UserRole.vue";
import DriverCoordinator from "@/views/drivers/DriverCoordinator.vue";
import DriverCertificates from "@/views/drivers/DriverCertificates.vue";
import DriversMixin from "@/views/drivers/DriversMixin.vue";
import OmniTable from "@/components/Table.vue";
import MobileStatus from "@/components/status/MobileStatus.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";

export default {
  name: "DriversList",
  components: {
    OpenChatButton,
    MobileStatus,
    OmniTable,
    DriverCertificates,
    DriverCoordinator,
    UserRole,
    DriverNotes,
    PhoneNumber,
  },
  mixins: [BaseView, DriversMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    searchParams: Object,
    showOwners: {
      type: Boolean,
      default: false,
    },
    compact: Boolean,
  },
  data() {
    return {
      data: { items: [], total: 0 },
      loading: true,
      archived: false,
    };
  },
  computed: {
    headers() {
      const headers = [
        { title: this.$t("general.full-name"), key: "name", class: "col-name" },
        {
          title: this.$t("general.status"),
          key: "status",
          sortable: false,
          align: "center",
          class: "col-status",
        },
        {
          title: this.$t("general.phone"),
          key: "phone",
          sortable: false,
          align: "left",
          class: "col-phone",
        },
        {
          title: this.$t("drivers.contacts"),
          key: "contacts",
          sortable: false,
          class: "col-contacts",
        },
        {
          title: this.$t("drivers.truck"),
          key: "truck",
          sortable: false,
          class: "col-trucks",
          align: "left",
        },
        {
          title: this.$t("general.email"),
          key: "email",
          sortable: false,
          align: "left",
          class: "col-email",
        },
        {
          title: this.$t("general.address"),
          key: "address",
          sortable: false,
          class: "col-address",
        },
        {
          title: this.$t("drivers.documents"),
          key: "documents",
          sortable: false,
          class: "col-docs",
        },
      ];
      if (this.canEdit) {
        headers.push({
          title: "Actions",
          key: "actions",
          align: "left",
          class: "col-actions",
          sortable: false,
        });
      }

      return headers;
    },
  },
  watch: {
    async searchParams(newParams, oldParams) {
      if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
        this.params.page = 1;
        await this.refresh();
      }
    },
    archived() {
      this.refresh();
    },
  },
  async created() {
    if (this.searchParams) {
      this.params.page = 1;
      await this.refresh();
    }
  },
  methods: {
    getItemClass(item) {
      return item.driver_status === "h" ? "status-hold" : "";
    },
    async refresh() {
      const params = { ...this.searchParams };
      params.page = this.params.page;
      params.page_size = this.params.page_size;
      params.archived = this.archived;

      this.loading = true;

      const resp = await this.$api.users.findDrivers(params);
      if (resp.success) {
        this.data = resp.data;
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <omni-table
    v-model:archived="archived"
    :compact="compact"
    :footer-props="{ 'items-per-page-options': pagination.items_per_page }"
    :headers="headers"
    :items="data.items"
    :items-length="data.total"
    :items-per-page="params.page_size"
    :loading="loading"
    :mobile-breakpoint="0"
    :page="params.page"
    show-archive
    @update:page="updatePage"
    @update:items-per-page="updatePageSize"
  >
    <template #[`item.name`]="{ item }">
      <div :class="getItemClass(item)">
        <user-role :user="item" />
        <router-link
          :to="{ name: 'user-view', params: { id: item.id } }"
          class="text-decoration-none text-primary d-flex align-center driver-name"
        >
          <mobile-status :user="item" class="mr-2"></mobile-status>
          <span v-if="item.id === item.contact_person_id" class="currency-icon mr-2 small" />
          {{ item.name }}
        </router-link>
      </div>
    </template>
    <template #[`item.status`]="{ item }">
      <driver-notes :user="item" />
      <span :class="getStatusClass(item.driver_status)" class="caption pr-2 pl-2 table-status text-white rounded-lg">
        {{ $t("drivers.status-" + item.driver_status) }}
      </span>
    </template>
    <template #[`item.phone`]="{ item }">
      <div class="d-flex align-center">
        <phone-number :number="item.phone" class="text-decoration-none text-primary" />
        <span class="ml-2 text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language">
          {{ $filters.upper(item.language) }}
        </span>
      </div>
    </template>
    <template #[`item.contacts`]="{ item }">
      <div v-if="!item.is_owner && item.owner" class="d-flex align-center">
        <div class="bg-grey-lighten-4 text-green-lighten-1 pa-0 status-item text-uppercase mr-2">
          {{ $t("users.ow") }}
        </div>
        <span v-if="item.owner_id === item.contact_person_id" class="currency-icon mr-2 small" />
        <router-link
          :to="{ name: 'user-view', params: { id: item.owner.id } }"
          class="mr-2 text-decoration-none text-primary"
        >
          {{ item.owner.name }}
        </router-link>
        <div
          :class="getStatusClass(item.owner.owner_status)"
          class="caption pr-2 pl-2 table-status text-white rounded-lg"
        >
          {{ $t("drivers.status-" + item.owner.owner_status) }}
        </div>
      </div>

      <driver-coordinator :user="item" />
    </template>
    <template #[`item.truck`]="{ item }">
      <router-link
        v-if="item.driver_truck"
        :to="{ name: 'truck-view', params: { id: item.driver_truck.id } }"
        class="text-primary text-decoration-none"
      >
        {{ item.driver_truck.number }}
      </router-link>
    </template>
    <template #[`item.email`]="{ item }">
      <span v-clipboard="item.email" class="text-grey-darken-3">{{ item.email }}</span>
    </template>
    <template #[`item.address`]="{ item }">
      <span class="text-grey-darken-3">
        {{ item.address_city }}, {{ item.address_zip_code }}, {{ item.address_state }}
      </span>
    </template>
    <template #[`item.documents`]="{ item }">
      <div v-if="getUserDocuments(item).length > 0" class="d-flex align-center justify-space-between">
        <div class="text-grey-darken-3 text-body-2 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-sm-5 pa-0 mr-2">
          {{ $t("drivers.citizenship-" + item.citizenship) }}
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-sm-7 pa-0">
          <driver-certificates :user="item" />
        </div>
      </div>
      <span v-else class="text-grey-darken-3 text-body-2">{{ $t("drivers.citizenship-" + item.citizenship) }}</span>
    </template>
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center">
        <v-btn
          :to="{ name: 'user-view', params: { id: item.id } }"
          class="pa-1 owners-table-action"
          size="small"
          variant="flat"
        >
          <v-icon class="text-primary">mdi-eye-outline</v-icon>
        </v-btn>
        <open-chat-button :user="item"></open-chat-button>
      </div>
    </template>
  </omni-table>
</template>

<style lang="scss" scoped>
.driver-name {
  margin-right: 50px;
}

.table-status {
  padding: 0;
  white-space: nowrap;
  line-height: 16px !important;
}

.owners-table-action {
  width: auto !important;
  min-width: auto !important;
  padding: 0 2px !important;
  background: transparent !important;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.type-language {
  line-height: 16px;
}

.status-item {
  font-size: 9px;
  line-height: 16px !important;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}

td:has(.status-hold) {
  border-left: 3px solid #ff5252 !important;
}
</style>

<style lang="scss">
.col-name {
  // min-width: 190px;
  min-width: 150px;
  width: 14%;
  @media (max-width: 1300px) {
    min-width: 190px;
  }
}

.col-status {
  min-width: 95px;
  width: 6%;
}

.col-phone {
  min-width: 200px;
  width: 13%;
}

.col-contacts {
  min-width: 250px;
  width: 15%;
}

.col-trucks {
  // min-width: 150px;
  width: 4%;
  min-width: 100px;
  @media (max-width: 1300px) {
    min-width: 120px;
  }
}

.col-email {
  // min-width: 220px;
  width: 10%;
  @media (max-width: 1300px) {
    min-width: 220px;
  }
}

.col-address {
  // min-width: 220px;
  width: 13%;
  @media (max-width: 1300px) {
    min-width: 220px;
  }
}

.col-docs {
  // min-width: 220px;
  width: 13%;
  @media (max-width: 1300px) {
    min-width: 200px;
  }
}

.col-actions {
  width: 1%;
  min-width: 110px;
}
</style>
