<script>
import BaseView from "@/views/BaseView.vue";
import UserStatus from "@/components/status/UserStatus.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";

export default {
  name: "UserCard",
  components: { OpenChatButton, UserStatus, PhoneNumber },
  mixins: [BaseView],
  props: {
    role: String,
    roleColor: String,
    status: String,
    user: Object,
    fullAccess: Boolean,
    isFinanciallyResponsible: Boolean,
  },
  emits: ["chat"],
};
</script>

<template>
  <div>
    <div class="pa-2 bg-white rounded content mb-3">
      <div class="d-flex align-center justify-space-between mb-2">
        <div class="col-md-9 pa-0">
          <div class="">
            <router-link
              :to="{ name: 'user-view', params: { id: user.id } }"
              class="text-decoration-none d-flex align-center"
              target="_blank"
            >
              <div class="font-size-12 text-primary mr-2">
                {{ user.name }}
              </div>
              <v-icon v-if="fullAccess" class="text-green mr-2" size="small">mdi-check</v-icon>
              <span v-if="isFinanciallyResponsible" class="currency-icon mr-2"></span>
            </router-link>
          </div>
        </div>
        <user-status v-if="status" :status="status" />
      </div>
      <div class="d-flex mb-2 align-center justify-space-between">
        <div class="d-flex">
          <a v-if="user.phone" :href="`callto: ${user.phone}`" class="d-flex align-center text-decoration-none">
            <span class="user-contact__icon mr-2 d-flex align-center justify-center">
              <v-icon class="text-primary" size="14">mdi-phone-outline</v-icon>
            </span>
          </a>
          <a
            v-if="user.mobile_phone_1"
            :href="`callto: ${user.mobile_phone_1}`"
            class="d-flex align-center text-decoration-none"
          >
            <span class="user-contact__icon mr-2 d-flex align-center justify-center">
              <v-icon class="text-primary" size="14">mdi-phone-outline</v-icon>
            </span>
          </a>
          <div v-if="user.phone" class="d-flex align-center">
            <a :href="`callto: ${user.phone}`" class="text-decoration-none">
              <phone-number :number="user.phone" class="text-primary font-size-12" />
            </a>
            <span class="caption pa-1 text-grey-darken-3 bg-grey-lighten-4 ml-3 rounded">
              {{ $filters.upper(user.language) }}
            </span>
          </div>
          <div v-if="user.mobile_phone_1" class="d-flex align-center">
            <a :href="`callto: ${user.mobile_phone_1}`" class="text-decoration-none">
              <phone-number :number="user.mobile_phone_1" class="text-primary font-size-12" />
            </a>
            <span class="caption pa-1 text-grey-darken-3 bg-grey-lighten-4 ml-3 rounded">
              {{ $filters.upper(user.language) }}
            </span>
          </div>
        </div>

        <div class="d-flex align-center text-decoration-none" @click="$emit('chat', user)">
          <open-chat-button :user="user" class="mr-3"></open-chat-button>
        </div>
      </div>
      <div class="d-flex align-center justify-space-between">
        <span :class="roleColor" class="status-label text-uppercase caption bg-grey-lighten-4 pl-2 pr-2 rounded-lg">
          {{ role }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.status-label {
  line-height: 14px !important;
}

.user-contact__icon {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
}

.content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);
}

.currency-icon {
  width: 18px;
  flex: 0 0 18px;
  height: 18px;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: url(@/assets/images/currency-icon.svg) no-repeat;
  background-size: 18px;
}
</style>
