import { BaseApi } from "@/api/base";

export class TrucksApi extends BaseApi {
  findTrucks(options = {}, abortable = true) {
    if (abortable && this.trucksListController) {
      this.trucksListController.abort();
    }
    this.trucksListController = new AbortController();

    return this.client
      .get("trucks", options, { signal: abortable ? this.trucksListController.signal : null })
      .then((response) => {
        this.trucksListController = null;
        return response;
      });
  }

  findTruckById(truckId) {
    return this.client.get("trucks/" + truckId);
  }

  reserveTruck(truckId, dispatcher_id, minutes) {
    return this.client.post("trucks/" + truckId + "/reserve", { dispatcher_id, minutes });
  }

  async upsertTruck(truck) {
    return this.client.save("/trucks", truck);
  }

  updateTruck(truckId, data) {
    return this.client.patch("trucks/" + truckId, data);
  }

  updateTruckStatus(truckId, data) {
    return this.client.patch("trucks/" + truckId + "/status", data);
  }

  getTruckLocations(truckId, dateFrom = null, dateTo = null) {
    return this.client.get("trucks/" + truckId + "/locations", {
      time_from: dateFrom,
      time_to: dateTo,
    });
  }

  archiveTruck(truckId) {
    return this.client.post("/trucks/" + truckId + "/archive");
  }

  unarchiveTruck(truckId) {
    return this.client.post("/trucks/" + truckId + "/unarchive");
  }
}
