<script>
export default {
  name: "ShowMore",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemClass: String,
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      popover: false,
    };
  },
  computed: {
    itemClasses() {
      return this.itemClass || "text-grey-darken-2 font-size-14";
    },
  },
  methods: {
    closePopover() {
      this.popover = false;
    },
  },
};
</script>

<template>
  <div v-if="items.length > 0" class="show-more__items">
    <v-tooltip
      v-for="(item, index) in items.slice(0, limit).join(', %').split('%')"
      :key="index"
      :disabled="item.length <= 12"
      location="top start"
    >
      <template #activator="{ props }">
        <span v-clipboard.uncolored="item" :class="itemClasses" class="item" v-bind="props">
          {{ $filters.ellipsis(item, 15) }}
        </span>
      </template>
      {{ item }}
    </v-tooltip>
    <v-menu
      v-if="items.length > limit"
      v-model="popover"
      :close-on-content-click="false"
      :nudge-width="200"
      class="quote-menu"
      offset="10"
    >
      <template #activator="{ props }">
        <span
          v-if="items.length > limit"
          class="text-primary font-size-12 font-weight-600 cursor-pointer ml-2"
          v-bind="props"
        >
          ({{ items.length - limit }} more)
        </span>
      </template>
      <v-card class="show-more__items">
        <v-icon class="close-btn" size="small" @click="closePopover">mdi-close</v-icon>
        <v-card-text>
          <div class="font-size-16 font-weight-500 mb-3 pr-4">Ref numbers</div>
          <p v-for="(item, i) in items" :key="i" v-clipboard.uncolored="item" class="item">
            {{ item }}
          </p>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.close-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}

.show-more__items {
  display: flex;
  align-items: center;
  .item {
    transition: all ease-in-out 200ms;
    cursor: pointer;

    &:hover {
      color: $primary !important;
    }
  }
}
</style>
