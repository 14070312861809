<script>
import BaseView from "@/views/BaseView.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import countries from "@/data/countries";
import { mapMutations } from "vuex";
import FmcsaNumberInput from "@/views/companies/components/FmcsaNumberInput.vue";
import AddressField from "@/components/AddressField.vue";

export default {
  name: "CustomerEditForm",
  components: { AddressField, FmcsaNumberInput },
  mixins: [BaseView, MasksMixin, ValidationMixin],
  props: {
    customer: Object,
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      item: {
        status: "a",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        address_country: "US",
        billing_address_city: "",
        billing_address_state: "",
        billing_address_zip_code: "",
        billing_address_country: "US",
      },
      sameAddress: false,
      fmcsaTypes: ["MC", "FF", "MX"],
      statuses: [
        { id: "a", name: this.$t("company.status-active") },
        { id: "h", name: this.$t("company.status-hold") },
        { id: "n", name: this.$t("company.status-hidden") },
      ],
    };
  },
  watch: {
    customer() {
      this.item = { ...this.customer };
    },
    item: {
      deep: true,
      handler() {
        if (this.sameAddress) {
          this.syncAddress();
        }
      },
    },
    sameAddress() {
      if (this.sameAddress) {
        this.syncAddress();
      }
    },
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    countries() {
      return countries;
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }

      const resp = await this.$api.company.upsertCustomer(this.item);
      if (resp.success) {
        this.$emit("close");
      } else {
        this.showSnackbarError(this.$t("general.save-error"));
      }
    },
    syncAddress() {
      this.item.billing_address_line_1 = this.item.address_line_1;
      this.item.billing_address_line_2 = this.item.address_line_2;
      this.item.billing_address_city = this.item.address_city;
      this.item.billing_address_state = this.item.address_state;
      this.item.billing_address_zip_code = this.item.address_zip_code;
      this.item.billing_address_country = this.item.address_country;
    },
  },
};
</script>

<template>
  <v-form id="edit-customer" ref="form" lazy-validation>
    <div class="about-user">
      <div class="mb-6">
        <div class="user-header d-flex align-center justify-space-between mb-3 flex-wrap">
          <div class="user-header__left d-flex align-center">
            <div class="subheading font-weight-medium">Customer information</div>
          </div>
        </div>
        <div class="pa-5 mb-6 bg-white rounded personal-block">
          <div class="caption text-grey-darken-1 mb-3">Company information</div>
          <v-row>
            <v-col cols="3">
              <v-text-field
                id="name"
                v-model="item.name"
                :label="$t('general.name')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                name="name"
                variant="outlined"
              />
            </v-col>
            <v-col>
              <v-text-field
                id="branch_name"
                v-model="item.branch_name"
                :label="$t('company.branch_name')"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                name="branch_name"
                variant="outlined"
              />
            </v-col>
            <v-col>
              <v-select
                id="fmcsa_type"
                v-model="item.fmcsa_type"
                :items="fmcsaTypes"
                :label="$t('company.fmcsa_type')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                name="fmcsa_type"
                variant="outlined"
              />
            </v-col>
            <v-col>
              <fmcsa-number-input
                id="fmcsa_number"
                v-model="item.fmcsa_number"
                :rules="[requiredValidator]"
                class="required"
                name="fmcsa_number"
              />
            </v-col>
            <v-col>
              <v-select
                id="status"
                v-model="item.status"
                :items="statuses"
                :label="$t('general.status')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                color="primary"
                density="compact"
                hide-details="auto"
                item-title="name"
                item-value="id"
                name="status"
                variant="outlined"
              />
            </v-col>
            <v-col>
              <v-text-field
                id="unpaid_loads_limit"
                v-model="item.unpaid_loads_limit"
                :label="$t('company.unpaid_loads_limit')"
                :rules="[requiredValidator]"
                autocomplete="off1"
                class="required"
                color="primary"
                density="compact"
                hide-details="auto"
                name="unpaid_loads_limit"
                variant="outlined"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="pa-5 mb-6 bg-white rounded personal-block">
        <address-field v-model="item" hide-location label="Physical Address"></address-field>
      </div>

      <div class="pa-5 mb-6 bg-white rounded personal-block">
        <address-field
          v-model="item"
          :disabled="sameAddress"
          hide-location
          label="Billing Address"
          model-prefix="billing_"
        >
          <template #label:suffix>
            <v-checkbox
              v-model="sameAddress"
              class="text-grey-darken-2 mt-0 pt-0 billing-checkbox"
              color="primary"
              hide-details=""
              label="Billing address same as physical"
            />
          </template>
        </address-field>
      </div>
    </div>
    <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
      <v-btn
        id="cancel-btn"
        :to="{ name: 'customers' }"
        class="mr-3 text-primary text-uppercase font-weight-600"
        height="36"
        variant="flat"
      >
        {{ $t("general.cancel") }}
      </v-btn>
      <v-btn
        id="save-btn"
        class="pr-6 pl-6 font-weight-600 text-uppercase"
        color="primary"
        height="36"
        variant="flat"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </div>
  </v-form>
</template>
