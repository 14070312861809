<script>
export default {
  name: "OrderTemperatureRange",
  props: {
    modelValue: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      temperatureFrom: null,
      temperatureTo: null,
      hasFromError: false,
      hasToError: false,
      errorMessage: "null",
    };
  },
  computed: {
    temperatureFromNotEmpty() {
      return this.temperatureFrom !== null && this.temperatureFrom !== "";
    },
    temperatureToNotEmpty() {
      return this.temperatureTo !== null && this.temperatureTo !== "";
    },
    hasError() {
      return (!this.hasFromError && !this.hasToError) || "Error";
    },
  },
  watch: {
    temperatureFrom() {
      this.validateAndEmit();
    },
    temperatureTo() {
      this.validateAndEmit();
    },
    modelValue() {
      this.setFromModelValue();
    },
    disabled(value) {
      if (value) {
        this.resetErrors();
        this.$emit("update:modelValue", []);
      }
    },
  },
  mounted() {
    this.setFromModelValue();
  },
  methods: {
    setFromModelValue() {
      if (this.modelValue && Array.isArray(this.modelValue) && this.modelValue.length === 2) {
        this.temperatureFrom = this.modelValue[0];
        this.temperatureTo = this.modelValue[1];
      } else {
        this.temperatureFrom = null;
        this.temperatureTo = null;
      }
    },
    validateAndEmit() {
      this.resetErrors();
      if (this.temperatureFromNotEmpty && !this.temperatureToNotEmpty) {
        this.hasToError = true;
        this.errorMessage = "Please specify max temperature";
        return;
      }
      if (this.temperatureToNotEmpty && !this.temperatureFromNotEmpty) {
        this.hasFromError = true;
        this.errorMessage = "Please specify min temperature";
        return;
      }
      if (this.temperatureFromNotEmpty && this.temperatureToNotEmpty) {
        if (this.temperatureFrom >= this.temperatureTo) {
          this.hasFromError = true;
          this.hasToError = true;
          this.errorMessage = 'Temperature "From" must be lower than temperature "To"';
          return;
        }
        this.$emit("update:modelValue", [this.temperatureFrom, this.temperatureTo]);
      }
    },
    resetErrors() {
      this.hasFromError = false;
      this.hasToError = false;
      this.errorMessage = null;
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <span class="text-grey-darken-1">From</span>
      <div class="pr-2 pl-2">
        <v-text-field
          v-model.number="temperatureFrom"
          :disabled="disabled"
          :error="hasFromError"
          :rules="[hasError]"
          append-inner-icon="mdi-temperature-fahrenheit"
          color="primary"
          data-qa="temperature-from"
          density="compact"
          hide-details
          required
          type="number"
          variant="outlined"
        />
      </div>
      <span class="text-grey-darken-1">to</span>
      <div class="pl-2">
        <v-text-field
          v-model.number="temperatureTo"
          :disabled="disabled"
          :error="hasToError"
          :rules="[hasError]"
          append-inner-icon="mdi-temperature-fahrenheit"
          color="primary"
          data-qa="temperature-to"
          density="compact"
          hide-details
          required
          type="number"
          variant="outlined"
        />
      </div>
    </div>
    <div v-if="hasFromError || hasToError" class="mt-2 text-danger">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
