<script>
import { mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";
import OmniTable from "@/components/Table.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import FacilitiesSearchForm from "@/views/companies/facilities/FacilitiesSearchForm.vue";

export default {
  name: "FacilitiesPage",
  components: { FacilitiesSearchForm, PageHeader, LinkButton, OmniTable },
  mixins: [BaseView],
  data() {
    return {
      perm: "facilities",
      headers: [
        { title: "Name", key: "name" },
        {
          title: "Type",
          key: "type",
          value: (item) => item.type.charAt(0).toUpperCase() + item.type.slice(1),
        },
        { title: "Location", key: "address" },
        {
          title: "",
          key: "actions",
          align: "end",
          width: 58,
          sortable: false,
        },
      ],
      filter: { search: "" },
      data: { items: [] },
      loading: false,
    };
  },
  computed: {
    ...mapState(["mobile"]),
    canEdit() {
      return this.hasAccess(this.perm, "edit");
    },
  },
  async created() {
    this.setTitle(this.$t("company.facilities"));
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const filter = { ...this.filter, ...this.params };

      try {
        const resp = await this.$api.company.findFacilities(filter);
        this.data = resp.data;
      } catch (e) {
        console.error("Error load facilities list: ", e);
      }

      this.loading = false;
    },
    async search(params) {
      this.params.page = 1;
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 height-100">
    <page-header :header="$t('company.facilities')">
      <template #suffix>
        <link-button :to="{ name: 'facilities-create' }" color="success">
          {{ $t("general.create-new") }}
        </link-button>
      </template>
    </page-header>
    <facilities-search-form @search="search" />

    <omni-table
      :footer-props="{ 'items-per-page-options': pagination.items_per_page }"
      :headers="headers"
      :items="data.items"
      :items-length="data.total || 0"
      :items-per-page="params.page_size"
      :show-all-option="false"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="params.page"
      @update:page="updatePage"
      @update:items-per-page="updatePageSize"
    >
      <template #[`item.address`]="{ item }"> {{ item.address }} {{ item.address_line }}</template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="canEdit"
          color="primary"
          size="small"
          variant="flat"
          @click="$router.push({ name: 'facilities-edit', params: { id: item.id } })"
        >
          <v-icon size="small">mdi-pencil</v-icon>
        </v-btn>
      </template>
    </omni-table>
  </v-container>
</template>
