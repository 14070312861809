<script>
export default {
  name: "DeviceInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deviceInfo() {
      const infoChunks = [];
      if (this.user.mobile_user_device_os) {
        infoChunks.push(this.user.mobile_user_device_os);
      }
      if (this.user.mobile_user_device_model) {
        infoChunks.push(this.user.mobile_user_device_model);
      }
      if (this.user.mobile_user_app_version) {
        infoChunks.push(this.user.mobile_user_app_version);
      }
      return infoChunks.length > 0 ? infoChunks.join(", ") : "—";
    },
  },
};
</script>

<template>
  {{ deviceInfo }}
</template>

<style lang="scss" scoped></style>
