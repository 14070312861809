<script>
export default {
  name: "OmniSelect",
  props: {
    id: String,
    modelValue: null,
    items: Array,
    multiple: Boolean,
    label: String,
    itemTitle: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
    clearable: Boolean,
    required: Boolean,
    rules: Array,
  },
  emits: ["update:modelValue", "change"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("change", value);
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<template>
  <v-select
    :id="id"
    v-model="model"
    :class="{ required }"
    :clearable="clearable"
    :item-title="itemTitle"
    :item-value="itemValue"
    :items="items"
    :label="label"
    :multiple="multiple"
    :rules="rules"
    color="primary"
    density="compact"
    hide-details="auto"
    variant="outlined"
  >
    <template #item="{ item, props }">
      <v-divider v-if="typeof item.raw === 'object' && 'divider' in item.raw" />
      <v-list-subheader v-else-if="typeof item.raw === 'object' && 'header' in item.raw" :title="item.raw.header" />
      <v-list-item v-else :disabled="item.raw.disabled" :label="item.title" :value="item.value" v-bind="props">
        <template v-if="multiple" #prepend="status">
          <v-checkbox-btn
            :key="item.value"
            :model-value="status.isSelected"
            :ripple="false"
            color="primary"
            tabindex="-1"
          />
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>

<style lang="scss">
@import "@/assets/style/color";

.v-list-subheader {
  font-size: 11px;
  color: $grey;
}
</style>
