<script>
export default {
  name: "UserViewCoordinatorInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    coordinator() {
      const coordinator = { ...this.user };
      coordinator.owners = this.user.owners || [];
      return coordinator;
    },
  },
};
</script>

<template>
  <div v-if="user.is_coordinator" class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4 mb-2">
    <div class="text-grey-darken-3 mb-6 font-weight-medium">Coordinator info</div>
    <v-row>
      <div class="label">
        <span class="text-grey-darken-1">Owners</span>
      </div>
      <div class="description">
        <div v-for="owner in coordinator.owners" :key="owner.owner_id" class="d-flex mb-2">
          <router-link
            :to="{ name: 'user-view', params: { id: owner.owner_id } }"
            class="text-primary text-decoration-none"
          >
            {{ owner.owner?.name }}
          </router-link>
          <div v-if="owner.full_access" class="text-green ml-3">
            <v-icon size="small">mdi-check</v-icon>
            Full access
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
