<script>
import { decode } from "@googlemaps/polyline-codec";
import {
  EndPointMarker,
  PathArrowReverse,
  PointMarker,
  SelectedPointMarker,
  StartPointMarker,
} from "@/views/trucks/history/markers";
import BaseView from "@/views/BaseView.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import { GoogleMap, Marker as GMarker, Polyline as GPolyline } from "vue3-google-map";
import TruckLocationTime from "@/views/trucks/components/TruckLocationTime.vue";

export default {
  name: "TruckLocationsHistory",
  components: { TruckLocationTime, GoogleMap, GPolyline, GMarker },
  mixins: [BaseView, GoogleMapsMixin],
  props: {
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      center: null,
      zoom: 4,
      markers: [],
      selectedLocationIndex: null,
      listCollapsed: false,
      lineOptions: {
        icons: [
          {
            icon: PathArrowReverse,
            offset: 0,
            repeat: "50px",
          },
        ],
        strokeWeight: 6,
        strokeColor: "#304FFE",
        path: null,
      },
    };
  },
  computed: {
    duration() {
      const start = this.$dayjs(this.data.locations[0].date);
      const end = this.$dayjs(this.data.locations[this.data.locations.length - 1].date);

      return start.isValid() && end.isValid() ? this.$dayjs.duration(end.diff(start)).asHours().toFixed(2) : 0;
    },
    locations() {
      return [...this.data.locations].reverse();
    },
  },
  watch: {
    data() {
      this.resetMap();
      if (this.data.locations.length > 0) {
        this.setCenterAuto();
        this.drawPath();
        this.pinMarkers();
      }
    },
  },
  methods: {
    addMarker(position, icon = null, prefix = "", label = null) {
      this.markers.push({
        position: this.toLatLng(position.location),
        id: prefix + position.id,
        icon: icon,
        label: label,
      });
    },
    removeMarker(id, prefix = "") {
      const index = this.markers.findIndex((m) => m.id === prefix + id);
      if (index > -1) {
        this.markers.splice(index, 1);
      }
    },
    toLatLng(tuple, reverse = true) {
      return { lat: reverse ? tuple[1] : tuple[0], lng: reverse ? tuple[0] : tuple[1] };
    },
    setCenterAuto() {
      const positions = this.data.locations;
      let latSum = 0;
      let lonSum = 0;
      positions.forEach((position) => {
        latSum += position.location[1];
        lonSum += position.location[0];
      });
      this.center = { lat: latSum / positions.length, lng: lonSum / positions.length };
      this.zoom = 4;
    },
    drawPath() {
      if (!this.data.routes || this.data.routes.length === 0) return;
      const positions = this.data.locations;
      const decoded = decode(this.data.routes[0].geometry, 5).map((tuple) => this.toLatLng(tuple, false));
      this.lineOptions.path = this.data.routes[0] ? decoded : positions.map((p) => this.toLatLng(p.location));
    },
    pinMarkers() {
      const positions = this.data.locations;
      this.markers = [];
      for (let i = 0; i < positions.length; i++) {
        this.addMarker(positions[i], PointMarker, "point");
      }
      const positionStart = positions.length > 1 ? positions[0] : null;
      const positionEnd = positions[positions.length - 1];
      this.addMarker(positionStart, StartPointMarker, "", {
        text: "start",
        className: "map__label start",
      });
      if (positionEnd) {
        this.addMarker(positionEnd, EndPointMarker, "", {
          text: "finish",
          className: "map__label end",
        });
      }
    },
    selectPosition(position, index) {
      const map = this.$refs.map.map;
      const minPanZoom = 10;
      this.pinMarkers();
      if (this.selectedLocationIndex === index) {
        this.selectedLocationIndex = null;
        this.setCenterAuto();
        return;
      }
      this.selectedLocationIndex = index;
      map.panTo(this.toLatLng(position.location));
      if (map.getZoom() < minPanZoom) {
        map.setZoom(minPanZoom);
      }
      this.removeMarker(position.id);
      this.addMarker(position, SelectedPointMarker);
    },
    resetMap() {
      this.selectedLocationIndex = null;
      this.markers = [];
      this.lineOptions.path = null;
    },
  },
};
</script>

<template>
  <v-row v-if="data != null && data.locations.length > 0" class="viewport-container mt-4">
    <v-col v-if="!listCollapsed" :cols="4" class="locations-container fill-height pa-0">
      <div class="viewport overflow-auto d-block pt-4 mb-4 pr-3 pl-1">
        <div
          v-for="(position, index) in locations"
          :key="position.id"
          :class="{
            'location__end labeled': index === 0,
            'location__start labeled': index === locations.length - 1,
            active: selectedLocationIndex === index,
          }"
          class="pa-4 rounded bg-white mb-5 location"
          @click="selectPosition(position, index)"
        >
          <v-row>
            <v-col>
              <div class="location__address mb-2">{{ position.address }}</div>
              <a :href="getPlaceUrl(position.location)" target="_blank">
                <div class="location__coordinates">
                  Lat:&nbsp;{{ $filters.format(position.location[1]) }} Lon:&nbsp;{{
                    $filters.format(position.location[0])
                  }}
                </div>
              </a>
            </v-col>
            <v-col>
              <div class="location__date text-right">
                <truck-location-time :time="position.date" :timezone="position.timezone" />
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="data.routes[0]" class="route-info elevation-2 mr-3">
        <v-row>
          <v-col>
            Total distance:
            <span class="route-info__value">{{ $filters.miles(data.routes[0].distance) }} miles</span>
          </v-col>
          <v-col>
            Total time: <span class="route-info__value">{{ duration }} hours</span>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col v-if="center !== null" :cols="listCollapsed ? 12 : 8" class="pa-0 pt-3 position-relative">
      <div class="list-collapse" @click="listCollapsed = !listCollapsed">
        <v-icon v-if="!listCollapsed">mdi-menu-left</v-icon>
        <v-icon v-else>mdi-menu-right</v-icon>
      </div>
      <google-map
        ref="map"
        :center="center"
        :fullscreen-control="false"
        :map-type-control="false"
        :street-view-control="false"
        :zoom="zoom"
        api-key="AIzaSyAjrfoG-Y8AE_-Vf8DIbhz_6ZwI3Z5ZzeU"
        style="width: 100%; height: 100%"
      >
        <g-polyline :options="lineOptions"></g-polyline>
        <g-marker
          v-for="m in markers"
          :key="m.id"
          :options="{ icon: m.icon, label: m.label, position: m.position }"
        ></g-marker>
      </google-map>
    </v-col>
  </v-row>
  <v-row v-else-if="data !== null && data.locations.length === 0">
    <div class="intro-block">
      <div class="intro-block__icon">
        <v-icon size="50">mdi-emoticon-sad-outline</v-icon>
      </div>
      <div class="intro-block__text">We didn't find any locations</div>
    </div>
  </v-row>
  <v-row v-else>
    <div class="intro-block">
      <div class="intro-block__icon">
        <v-icon size="50">mdi-magnify</v-icon>
      </div>
      <div class="intro-block__text">Start your search by entering truck number in field</div>
    </div>
  </v-row>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color.scss";

.intro-block {
  margin: 42px auto;
  width: 300px;
  color: $grey;

  &__icon {
    margin: 24px;
    text-align: center;
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

.viewport-container {
  height: calc(100% - 80px);
}

.locations-container {
  .location {
    position: relative;
    cursor: pointer;
    border-left: 4px solid transparent;
    outline-color: transparent;
    transition: outline-color 200ms ease-in-out;

    &.labeled:before {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      padding: 2px 4px;
      border-radius: 4px;
      color: $white;
      position: absolute;
      top: -7px;
      left: 16px;
    }

    &__start {
      border-left-color: $green !important;

      &:before {
        content: "start";
        background-color: $green;
      }
    }

    &__end {
      border-left-color: $danger !important;

      &:before {
        content: "finish";
        background-color: $danger;
      }
    }

    &__address {
      font-size: 14px;
      line-height: 16px;
    }

    &__coordinates {
      display: inline-block;
      font-size: 11px;
      line-height: 16px;
      color: $green;
      text-decoration: underline;
    }

    &__date {
      font-size: 12px;
      line-height: 16px;
    }

    &:not(:last-child):after {
      border-left: 2px dotted $grey-light;
      height: 16px;
      content: "";
      position: absolute;
      left: 50%;
      bottom: -18px;
    }

    &.active {
      outline: 2px solid $primary !important;
      border-left-color: transparent !important;
    }
  }

  .viewport {
    height: calc(100% - 88px);
  }

  .route-info {
    background-color: $white;
    padding: 24px;
    font-size: 16px;
    color: $dark-grey;

    &__value {
      font-weight: 500;
      white-space: nowrap;
    }
  }
}

.list-collapse {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 6px 0;
  cursor: pointer;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
</style>
