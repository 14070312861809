import { mapGetters, mapMutations } from "vuex";

const MessageDraftMixin = {
  computed: {
    ...mapGetters(["getDraftMessage"]),
  },
  methods: {
    ...mapMutations(["saveDraftMessage", "removeDraftMessage"]),
    draft() {
      return this.getDraftMessage(this.thread.id);
    },
    saveDraft(threadId, message, file) {
      this.saveDraftMessage({ id: threadId, message, file });
    },
    removeDraft(threadId) {
      this.removeDraftMessage(threadId);
    },
  },
  props: {
    thread: Object,
  },
};

export default MessageDraftMixin;
