<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "EditStopBlock",
  components: { TimePicker, DatePicker },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin],
  props: {
    modelValue: { type: Object, required: true },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    timezone: {
      get() {
        return this.modelValue.timezone;
      },
      set(value) {
        this.update("timezone", value);
      },
    },
    time_type: {
      get() {
        return this.modelValue.time_type;
      },
      set(value) {
        this.update("time_type", value);
      },
    },
    date_from: {
      get() {
        return this.modelValue.time_from ? this.$dayjs(this.modelValue.time_from).format("YYYY-MM-DD") : "";
      },
      set(value) {
        this.update("time_from", value + " " + this.time_from);
      },
    },
    time_from: {
      get() {
        return this.modelValue.time_from ? this.$dayjs(this.modelValue.time_from).format("HH:MM") : "";
      },
      set(value) {
        this.update("time_from", this.date_from + " " + value);
      },
    },
    date_to: {
      get() {
        return this.modelValue.time_to ? this.$dayjs(this.modelValue.time_to).format("YYYY-MM-DD") : "";
      },
      set(value) {
        this.update("time_to", value + " " + this.time_to);
      },
    },
    time_to: {
      get() {
        return this.modelValue.time_to ? this.$dayjs(this.modelValue.time_to).format("HH:MM") : "";
      },
      set(value) {
        this.update("time_to", this.date_to + " " + value);
      },
    },
  },
  methods: {
    update(key, value) {
      const stop = { ...this.modelValue, [key]: value };
      this.$emit("update:modelValue", stop);
      this.$emit("change", stop);
    },
  },
};
</script>

<template>
  <div class="py-4">
    <div class="pb-2">
      <span>Facility: </span>
      <span class="font-weight-500">{{ modelValue.facility.name }}</span>
    </div>

    <div class="mb-6 mt-4">
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="timezone"
            :items="timezonesShortList"
            :rules="[requiredValidator]"
            class="required"
            density="compact"
            hide-details="auto"
            item-title="name"
            item-value="id"
            label="Time zone"
            required
            variant="outlined"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="time_type"
            :items="getTimeTypes(modelValue.type)"
            :rules="[requiredValidator]"
            class="required"
            density="compact"
            hide-details="auto"
            item-title="name"
            item-value="id"
            label="Time type"
            required
            variant="outlined"
          />
        </v-col>
        <v-col v-if="time_type === 'f'" cols="3">
          <date-picker v-model="date_from" label="Date from" />
        </v-col>
        <v-col v-if="time_type === 'f'" cols="3">
          <time-picker v-model="time_from" placeholder="Time from" />
        </v-col>
        <v-col v-if="time_type === 'f'" cols="6" />
        <v-col cols="3">
          <date-picker v-model="date_to" :rules="[requiredValidator]" label="Date to" required />
        </v-col>
        <v-col cols="3">
          <time-picker
            v-model="time_to"
            :rules="[requiredValidator]"
            class="required"
            label="Time to"
            placeholder="Time to"
            required
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
