<script>
import FacilitySelect from "@/views/orders/components/FacilitySelect.vue";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";

export default {
  name: "AddReloadStopDialog",
  components: { FacilitySelect },
  mixins: [OrdersMixin],
  emits: ["update", "close"],
  data() {
    return {
      facility_id: null, // don't use it here
      facility: { timezone: null },
      note: "",
    };
  },
  methods: {
    add() {
      const stop = {
        type: this.STOP_RELOAD,
        status: "n",
        is_reload: true,
        editable: true,
        name: "Reload stop",
        use: true,
        time_type: "d",
        // date_time_to: this.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
        facility: this.facility,
        facility_id: this.facility.id,
        timezone: this.facility.timezone,
        note: this.note,
      };
      this.$emit("update", stop);
      this.$emit("close");
    },
  },
};
</script>
<template>
  <div class="py-4">
    <facility-select v-model="facility_id" class="mb-3" editable @update="(f) => (facility = f)" />
    <v-textarea v-model="note" class="mt-3" hide-details label="Reload stop note" />
  </div>
</template>
