<script>
import FormBlock from "@/components/form/FormBlock.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { mapMutations } from "vuex";
import OrderFile from "@/views/orders/components/edit/OrderFile.vue";

const FileFolder = "orders";
const OrderFileType = {
  typeBol: "bol",
  typeRateConfirmation: "rateConfirmation",
  typeOther: "other",
  typeBols: "bols",
  typeRateConfirmations: "rateConfirmations",
};

export default {
  name: "OrderEditFiles",
  components: { OrderFile, FormBlock },
  mixins: [ValidationMixin],
  props: {
    order: Object,
  },
  data() {
    return {
      files: {
        rateConfirmations: [],
        bols: [],
        other: [],
      },
      rateConfirmationNotAvailable: false,
    };
  },
  watch: {
    order() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapMutations(["showSnackbarError"]),
    async load() {
      this.files = {
        rateConfirmations: [],
        bols: [],
        other: [],
      };
      if (this.order.id) {
        const response = await this.$api.orders.findOrderFiles(this.$route.params.id);
        if (response.success) {
          // process rate confirmation files
          for (const file of response.data) {
            if (file.tags.includes(OrderFileType.typeRateConfirmation)) {
              this.addFile(OrderFileType.typeRateConfirmations, file);
            }
            if (file.tags.includes(OrderFileType.typeBol)) {
              this.addFile(OrderFileType.typeBols, file);
            }
            if (file.tags.includes(OrderFileType.typeOther)) {
              this.addFile(OrderFileType.typeOther, file);
            }
          }

          if (this.files.rateConfirmations.length === 0) {
            this.rateConfirmationNotAvailable = true;
            this.addEmptyFile(OrderFileType.typeRateConfirmations);
          }
          if (this.files.bols.length === 0) {
            this.addEmptyFile(OrderFileType.typeBols);
          }
          if (this.files.other.length === 0) {
            this.addEmptyFile(OrderFileType.typeOther);
          }
        } else {
          this.showSnackbarError("Error loading order data");
        }
      } else {
        // add empty files
        this.addEmptyFile(OrderFileType.typeRateConfirmations);
        this.addEmptyFile(OrderFileType.typeBols);
        this.addEmptyFile(OrderFileType.typeOther);
      }
    },
    async saveFiles(orderId) {
      const orderTag = "order:" + orderId;

      // save rate confirmation files
      for (const file of this.files.rateConfirmations) {
        if (file.file && file.file instanceof File) {
          const resp = await this.$api.files.uploadFile(FileFolder, file.file, [
            orderTag,
            OrderFileType.typeRateConfirmation,
          ]);
          if (resp.success) {
            file.file = resp.data;
          }
        }
      }

      // save bill of landing files
      for (const file of this.files.bols) {
        if (file.file && file.file instanceof File) {
          const resp = await this.$api.files.uploadFile(FileFolder, file.file, [orderTag, OrderFileType.typeBol]);
          if (resp.success) {
            file.file = resp.data;
          }
        }
      }

      // save other files
      for (const file of this.files.other) {
        if (file.file && file.file instanceof File) {
          const resp = await this.$api.files.uploadFile(FileFolder, file.file, [orderTag, OrderFileType.typeOther]);
          if (resp.success) {
            file.file = resp.data;
          }
        }
      }
    },
    canDeleteFile(type) {
      return this.files[type]?.length > 1;
    },
    addFile(type, file) {
      this.files[type].push({ file: file });
    },
    addEmptyFile(type) {
      this.addFile(type, { name: "" });
    },
    clearFile(type, index) {
      this.files[type][index] = { name: "" };
    },
    async removeFile(type, index) {
      const file = this.files[type][index];
      const fileId = file.file?.id;
      if (fileId) {
        // delete file in API storage
        await this.$api.files.deleteFile(fileId);
      }

      if (this.files[type].length > 1) {
        // remove file field
        this.files[type].splice(index, 1);
      } else {
        // clear file field
        this.files[type][index].file = [{ name: "" }];
      }
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="font-size-16 text-grey-darken-3 mb-4 font-weight-medium">Files</div>
    <v-row>
      <v-col lg="4" sm="12">
        <form-block class="fill">
          <div v-for="(file, i) in files.rateConfirmations" :key="i" :class="{ 'mt-4': i > 0 || !file.file?.id }">
            <order-file
              v-model="file.file"
              :deletable="canDeleteFile('rateConfirmations')"
              :disabled="rateConfirmationNotAvailable"
              :required="!rateConfirmationNotAvailable"
              label="Rate confirmation"
              @clear="clearFile('rateConfirmations', i)"
              @delete="removeFile('rateConfirmations', i)"
            ></order-file>
          </div>
          <div class="d-flex align-center justify-space-between mb-2 mt-4 flex-wrap">
            <v-btn
              v-if="!rateConfirmationNotAvailable"
              class="text-uppercase text-primary text-caption font-weight-600 pr-2 pl-1"
              height="20"
              size="small"
              variant="text"
              @click="addEmptyFile('rateConfirmations')"
            >
              <v-icon class="mr-2" color="primary" small>mdi-plus</v-icon>
              ADD RATE CONFIRMATION
            </v-btn>
            <v-checkbox
              v-if="files.rateConfirmations?.length === 1"
              v-model="rateConfirmationNotAvailable"
              class="mt-0 pt-0 rate-input"
              color="primary"
              density="compact"
              hide-details
              label="Not available"
            />
          </div>
        </form-block>
      </v-col>
      <v-col lg="8" sm="12">
        <form-block class="fill">
          <v-row>
            <v-col cols="6">
              <div v-for="(file, i) in files.bols" :key="i" :class="{ 'mt-4': i > 0 || !file.file?.id }">
                <order-file
                  v-model="file.file"
                  :deletable="canDeleteFile('bols')"
                  label="Bill of lading"
                  @clear="clearFile('bols', i)"
                  @delete="removeFile('bols', i)"
                ></order-file>
              </div>
              <v-btn
                class="text-uppercase text-primary text-caption font-weight-600 pr-2 pl-1 mt-4 text-uppercase"
                height="20"
                size="small"
                variant="text"
                @click="addEmptyFile('bols')"
              >
                <v-icon class="mr-2" color="primary" small>mdi-plus</v-icon>
                ADD BILL OF LADING
              </v-btn>
            </v-col>
            <v-col cols="6">
              <div v-for="(file, i) in files.other" :key="i" :class="{ 'mt-4': i > 0 || !file.file?.id }">
                <order-file
                  v-model="file.file"
                  :deletable="canDeleteFile('other')"
                  label="Other"
                  @clear="clearFile('other', i)"
                  @delete="removeFile('other', i)"
                ></order-file>
              </div>
              <v-btn
                class="text-uppercase text-primary text-caption font-weight-600 pr-2 pl-1 mt-4 text-uppercase"
                height="20"
                size="small"
                variant="text"
                @click="addEmptyFile('other')"
              >
                <v-icon color="primary mr-2" small>mdi-plus</v-icon>
                ADD FILE
              </v-btn>
            </v-col>

            <v-col lg="12">
              <div class="text-red text--accent-2">These files could be shared with a driver</div>
            </v-col>
          </v-row>
        </form-block>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.fill {
  height: calc(100% - 24px);
}
</style>
