<script>
export default {
  name: "PhoneNumber",
  props: {
    number: String,
    isCallable: { type: Boolean, default: false },
  },
  computed: {
    country() {
      if (!this.number) return "";
      if (this.formattedNumber.indexOf("+1") === 0) return "US";
      return "";
    },
    formattedNumber() {
      const cleaned = ("" + this.number).replace(/\D/g, "");
      const match = cleaned.match(/(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4];
      }
      return cleaned;
    },
  },
};
</script>

<template>
  <span class="phone-number cursor-text">
    <a v-if="isCallable" :href="`callto:${number}`" class="text-decoration-none text-primary">{{ formattedNumber }}</a>
    <span v-if="!isCallable">{{ formattedNumber }}</span>
  </span>
</template>

<style>
.phone-number {
  color: #616161;
}
</style>
