<script>
import { mapState } from "vuex";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import OmniDialog from "@/components/Dialog.vue";

export default {
  name: "ReserveTruckAction",
  components: { OmniDialog },
  mixins: [DateTimeMixin, TrucksMixin],
  props: {
    truck: Object,
  },
  emits: ["success", "error"],
  data() {
    return {
      dialogVisible: false,
      dispatcher_id: null,
      time: 15,
      timeInMinutes: [5, 10, 15, 20, 30, 45, 60],
    };
  },
  computed: {
    ...mapState(["dispatcher", "dispatchers"]),
    reservedByMe() {
      return this.isReservedByDispatcher(this.truck, this.dispatcher.id) && !this.reservationExpired;
    },
    reservationExpired() {
      return this.getReservationTime(this.truck) < 0;
    },
    minutes() {
      return this.timeInMinutes.map((t) => {
        return {
          id: t,
          name: t + " minutes",
        };
      });
    },
    sortedDispatchers() {
      return this.dispatchers.filter((d) => d.id !== this.dispatcher.id);
    },
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async save() {
      if (this.truck.reserved_by_id === null || this.reservedByMe || this.reservationExpired) {
        await this.reserveTruck(this.dispatcher_id ? this.dispatcher_id : this.dispatcher.id, this.time);
      } else {
        this.closeDialog();
      }
    },
    async releaseTruck() {
      if (this.reservedByMe) {
        await this.reserveTruck(null);
      } else {
        this.closeDialog();
      }
    },
    async reserveTruck(dispatcherId, minutes = null) {
      try {
        const resp = await this.$api.trucks.reserveTruck(this.truck.id, dispatcherId, minutes);
        if (resp.success) {
          this.$emit("success", resp.data);
        }
      } catch (e) {
        console.error("Error reserving truck: " + e);
        this.$emit("error", e);
      } finally {
        this.closeDialog();
      }
    },
  },
};
</script>

<template>
  <div @click="showDialog">
    <slot></slot>
  </div>

  <omni-dialog
    v-model="dialogVisible"
    :secondary-action-label="reservedByMe ? 'Release' : null"
    :subtitle="
      truck.reserved_by_id && !reservationExpired
        ? 'Reserved by ' + getReservedBy(truck) + ' until ' + dateTZ(truck.reserved_till, dispatcher.timezone)
        : null
    "
    :title="'Truck #' + truck.number + ' reservation management'"
    primary-action-label="Save"
    size="small"
    @primary-action:click="save"
    @secondary-action:click="releaseTruck"
  >
    <div class="my-5">
      <div v-if="truck.reserved_by_id && !reservationExpired" class="mb-4"></div>

      <v-select
        v-if="reservedByMe"
        v-model="dispatcher_id"
        :items="sortedDispatchers"
        class="mb-7"
        color="primary"
        density="compact"
        hide-details
        item-title="name"
        item-value="id"
        label="Transfer reservation to"
        variant="outlined"
      />

      <v-select
        v-model="time"
        :items="minutes"
        :label="reservedByMe ? 'Extend reservation for' : 'Reserve for'"
        color="primary"
        density="compact"
        hide-details
        item-title="name"
        item-value="id"
        variant="outlined"
      />
    </div>
  </omni-dialog>
</template>
