const locationRegexp =
  /(?<city> ?(?:[a-zA-Z]|\b-\b|\.|'| [a-zA-Z]){3,} ?)\s*,?\s*(?<state>\bAB\b|\bBC\b|\bMB\b|\bNB\b|\bNF\b|\bNT\b|\bNS\b|\bNU\b|\bON\b|\bPE\b|\bQC\b|\bPQ\b|\bSK\b|\bYT\b|\bAL\b|\bAK\b|\bAZ\b|\bAR\b|\bCA\b|\bCO\b|\bCT\b|\bDE\b|\bDC\b|\bFL\b|\bGA\b|\bHI\b|\bID\b|\bIL\b|\bIN\b|\bIA\b|\bKS\b|\bKY\b|\bLA\b|\bME\b|\bMD\b|\bMA\b|\bMI\b|\bMN\b|\bMS\b|\bMO\b|\bMT\b|\bNE\b|\bNV\b|\bNH\b|\bNJ\b|\bNM\b|\bNY\b|\bNC\b|\bND\b|\bOH\b|\bOK\b|\bOR\b|\bPA\b|\bRI\b|\bSC\b|\bSD\b|\bTN\b|\bTX\b|\bUT\b|\bVT\b|\bVA\b|\bWA\b|\bWV\b|\bWI\b|\bWY\b)\s?(?<zip_code>\b\d{5}\b|\b[a-z\d]{3} [a-z\d]{3}\b)?,?\s*/;

const LocationMixin = {
  methods: {
    locationValidator(value) {
      return locationRegexp.test(value) || "Invalid location";
    },
    expandLocation(location) {
      const found = location.match(locationRegexp);
      return found?.groups;
    },
  },
};

export default LocationMixin;
