<script>
export default {
  name: "FormBlock",
  inject: ["formSymbol"],
  props: {
    title: null,
    flat: Boolean,
  },
  mounted() {
    if (!this.formSymbol) {
      throw new Error("FormBlock must be a child of OmniForm");
    }
  },
};
</script>

<template>
  <div :class="{ 'pa-5 bg-white rounded block': !flat }" class="mb-6">
    <div v-if="title" class="caption text-grey-darken-1 mb-4">{{ title }}</div>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.block {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.16);

  fieldset {
    border: 1px solid $grey-light;
  }

  .v-text-field__slot .v-label {
    color: $grey;

    &--active {
      color: $grey-light;
    }
  }
}
</style>
