<script>
export default {
  name: "StopTimer",
  props: {
    startTime: String,
    endTime: String,
    withSeconds: Boolean,
  },
  data() {
    return {
      time: "0h:0m" + (this.withSeconds ? ":0s" : ""),
      intervalId: null,
    };
  },
  mounted() {
    this.updateTime();
    this.start();
  },
  unmounted() {
    this.stop();
  },
  methods: {
    updateTime() {
      const endTime = this.endTime ? this.$dayjs(this.endTime) : this.$dayjs();
      const secondsDiff = endTime.diff(this.startTime, "second");
      const hours = String(Math.floor(secondsDiff / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((secondsDiff - hours * 3600) / 60)).padStart(2, "0");
      const seconds = String(secondsDiff - hours * 3600 - minutes * 60).padStart(2, "0");

      this.time = hours + "h:" + minutes + "m" + (this.withSeconds ? ":" + seconds + "s" : "");
    },
    start() {
      if (this.endTime) {
        return;
      }
      if (this.intervalId) {
        this.stop();
      }
      this.intervalId = setInterval(this.updateTime, 1000);
    },
    stop() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
  },
};
</script>

<template>
  <div class="text-blue-grey-darken-2 font-size-12 d-flex align-center" data-qa="route-stops-stop-timer">
    <v-icon class="mr-1" icon="mdi-timer-outline" size="16"></v-icon>
    <div>{{ time }}</div>
  </div>
</template>

<style lang="scss" scoped></style>
