import { BaseApi } from "@/api/base";

export class AuthApi extends BaseApi {
  login(email, password) {
    return this.client.post("sign-in", {
      email: email,
      password: password,
    });
  }

  resetPassword(email, token) {
    return this.client.post("/dispatchers/reset-password", { email, token });
  }

  changePassword(password, new_password, new_password2) {
    return this.client.post("/dispatchers/change-password", {
      password,
      new_password,
      new_password2,
    });
  }

  setPassword(dispatcherId, new_password, new_password2, token) {
    return this.client.post("/dispatchers/" + dispatcherId + "/set-password", {
      new_password,
      new_password2,
      token,
    });
  }
}
