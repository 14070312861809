<script>
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";

export default {
  name: "FacilitiesSearchForm",
  components: { TableFilterRow, TableFilter, SearchField },
  mixins: [BaseView],
  emits: ["search"],
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params = this.getSearchParams("facilities");
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams("facilities", this.params);
      this.$emit("search", this.params);
    },
  },
};
</script>

<template>
  <table-filter :expandable="false" @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col cols="4">
          <search-field v-model="params.search" label="Name" name="name" @search="search" />
        </v-col>
        <!-- TODO: Dunno why but without second field in search html form is submitted independently -->
        <v-col class="d-none" cols="4">
          <search-field v-model="params.search1" label="Search" name="search" @search="search" />
        </v-col>
      </table-filter-row>
    </template>
  </table-filter>
</template>
