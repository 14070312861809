export const MasksMixin = {
  data() {
    return {
      einMask: {
        mask: "##-#######",
      },
      ssnMask: {
        mask: "###-##-####",
      },
      phoneMask: {
        mask: "1 ### ### ####",
        eager: true,
      },
      zipCodeMask: {
        mask: "#####",
      },
      vinCodeMask: {
        mask: "*****************",
      },
      stateShortMask: {
        mask: "@@",
      },
      yearMask: {
        mask: "####",
      },
    };
  },
};
