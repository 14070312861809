<script>
import BaseView from "@/views/BaseView.vue";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import { GoogleMap, Marker as GMarker, Polyline as GPolyline } from "vue3-google-map";
import OmniDialog from "@/components/Dialog.vue";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { decode } from "@googlemaps/polyline-codec";
import { EndPointMarker, PathArrowReverse, StartPointMarker } from "@/views/trucks/history/markers";

export default {
  name: "OrderStopsRoute",
  components: { OmniDialog, GoogleMap, GPolyline, GMarker },
  mixins: [BaseView, GoogleMapsMixin, OrdersMixin],
  props: {
    order: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
      center: null,
      zoom: 4,
      markers: [],
      selectedLocationIndex: null,
      listCollapsed: false,
      lineOptions: {
        icons: [
          {
            icon: PathArrowReverse,
            offset: 0,
            repeat: "50px",
          },
        ],
        strokeWeight: 6,
        strokeColor: "#4CAF50",
        path: null,
      },
    };
  },
  computed: {
    totalDistance() {
      return Object.values(this.order.distances).reduce((a, b) => a + b, 0);
    },
  },
  watch: {
    order() {
      this.resetMap();
      if (this.order && this.order.order_stops) {
        const locations = this.order.order_stops.map((stop) => stop.facility.address_coordinates);
        this.setCenterAuto(locations);

        let decoded = null;
        if (this.order.polyline) {
          decoded = decode(this.order.polyline, 5).map((tuple) => this.toLatLng(tuple, false));
        }
        this.lineOptions.path = decoded ? decoded : locations.map((location) => this.toLatLng(location));
      }
    },
  },
  methods: {
    getFacilityMarker(stop) {
      return {
        label:
          stop.type === this.STOP_PICK_UP
            ? {
                text: "Pick up",
                className: "map__label start",
              }
            : {
                text: "Delivery",
                className: "map__label end",
              },
        position: this.toLatLng(stop.facility.address_coordinates),
        icon: stop.type === this.STOP_PICK_UP ? StartPointMarker : EndPointMarker,
      };
    },
    toLatLng(tuple, reverse = true) {
      return { lat: reverse ? tuple[1] : tuple[0], lng: reverse ? tuple[0] : tuple[1] };
    },
    setCenterAuto(locations) {
      let latSum = 0;
      let lonSum = 0;
      locations.forEach((location) => {
        if (location) {
          latSum += location[1];
          lonSum += location[0];
        }
      });
      this.center = { lat: latSum / locations.length, lng: lonSum / locations.length };
      this.zoom = 6;
    },
    resetMap() {
      this.selectedLocationIndex = null;
      this.markers = [];
      this.lineOptions.path = null;
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="dialogVisible"
    :hide-actions="true"
    :title="'Order ' + order.number + ' route'"
    height="700"
    primary-action-label="Save"
    width="1200"
  >
    <template v-if="listCollapsed" #title:append>
      <div class="font-weight-500 font-size-14 text-grey-darken-1">
        Total distance:
        <span class="text-grey-darken-2">{{ $filters.miles(totalDistance) }} miles</span>
      </div>
    </template>
    <v-row class="viewport-container mt-4">
      <v-col v-if="!listCollapsed" :cols="4" class="locations-container pa-0">
        <div class="viewport overflow-auto d-block pt-4 mb-4 pr-3 pl-1">
          <template v-for="(stop, index) in order.order_stops" :key="stop.id">
            <div
              :class="{
                'location__pickup labeled': stop.type === STOP_PICK_UP,
                'location__delivery labeled': stop.type === STOP_DELIVERY,
              }"
              class="pa-4 rounded bg-grey-lighten-4 location"
            >
              <div class="float-end text-right font-size-12 font-weight-500 text-grey-darken-1">Not started</div>
              <div class="mb-2">
                <router-link
                  :to="{ name: 'facilities-edit', params: { id: stop.facility.id } }"
                  class="text-primary font-weight-500 font-size-16 text-decoration-none"
                  data-qa="route-facility-name"
                >
                  {{ stop.facility.name }}
                </router-link>
              </div>
              <div class="mb-5 text-grey-darken-2" data-qa="route-facility-address">{{ stop.facility.address }}</div>
              <div class="d-flex align-center text-grey-darken-1 font-weight-500" data-qa="route-facility-time">
                <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
                <span>{{ getTimeType(stop.time_type) }} {{ getStopTime(stop) }}</span>
              </div>
            </div>
            <div v-if="index < order.order_stops.length - 1" class="distance-gap">
              <div class="distance-gap--distance text-grey-darken-1">
                {{ getStopDistance(order.order_stops, order.distances, index) }} miles
              </div>
              <div class="distance-gap--line"></div>
            </div>
          </template>
        </div>
        <div class="pa-6 rounded bg-grey-lighten-4 text-grey-darken-2 mr-3 font-size-16">
          Total distance:
          <span class="route-info__value">{{ $filters.miles(totalDistance) }} miles</span>
        </div>
      </v-col>
      <v-col :cols="listCollapsed ? 12 : 8" class="pa-0 pt-3 position-relative">
        <div class="list-collapse" @click="listCollapsed = !listCollapsed">
          <v-icon v-if="!listCollapsed">mdi-menu-left</v-icon>
          <v-icon v-else>mdi-menu-right</v-icon>
        </div>
        <google-map
          ref="map"
          :center="center"
          :fullscreen-control="false"
          :map-type-control="false"
          :street-view-control="false"
          :zoom="zoom"
          api-key="AIzaSyAjrfoG-Y8AE_-Vf8DIbhz_6ZwI3Z5ZzeU"
          style="width: 100%; height: 608px"
        >
          <g-polyline :options="lineOptions"></g-polyline>
          <g-marker v-for="stop in order.order_stops" :key="stop.id" :options="getFacilityMarker(stop)"></g-marker>
        </google-map>
      </v-col>
    </v-row>
  </omni-dialog>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color.scss";

.intro-block {
  margin: 42px auto;
  width: 300px;
  color: $grey;

  &__icon {
    margin: 24px;
    text-align: center;
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

.viewport-container {
  height: calc(100% - 80px);
}

.locations-container {
  .location {
    position: relative;
    cursor: pointer;
    border-left: 4px solid transparent;
    outline-color: transparent;
    transition: outline-color 200ms ease-in-out;
    background: #e0e0e0;

    &.labeled:before {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      padding: 2px 4px;
      border-radius: 4px;
      color: $white;
      position: absolute;
      top: -7px;
      left: 16px;
    }

    &__delivery {
      border-left-color: $danger !important;

      &:before {
        content: "Delivery";
        background-color: $danger;
      }
    }

    &__pickup {
      border-left-color: $green !important;

      &:before {
        content: "Pick up";
        background-color: $green;
      }
    }

    &__address {
      font-size: 14px;
      line-height: 16px;
    }

    &__coordinates {
      display: inline-block;
      font-size: 11px;
      line-height: 16px;
      color: $green;
      text-decoration: underline;
    }

    &__date {
      font-size: 12px;
      line-height: 16px;
    }

    &.active {
      outline: 2px solid $primary !important;
      border-left-color: transparent !important;
    }
  }

  .viewport {
    height: calc(100% - 110px);
  }

  .route-info {
    background-color: $white;
    padding: 24px;
    font-size: 16px;
    color: $dark-grey;

    &__value {
      font-weight: 500;
      white-space: nowrap;
    }
  }
}

.distance-gap {
  position: relative;
  margin: 5px 0;
  height: 12px;

  .distance-gap--distance {
    position: absolute;
    background-color: #ffffff;
    padding: 0 12px;
    top: -5px;
    left: 40%;
    font-size: 10px;
    font-weight: 500;
    z-index: 10;
  }

  .distance-gap--line {
    position: absolute;
    top: 5px;
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
  }
}

.list-collapse {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: $white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 6px 0;
  cursor: pointer;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14), 0 1px 8px rgba(0, 0, 0, 0.12);
}
</style>
