<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpandButton",
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<template>
  <v-btn :class="{ expanded: expanded }" class="expandable-btn" icon="mdi-chevron-down" size="small" variant="plain" />
</template>

<style lang="scss" scoped>
.expandable-btn {
  transition: 0.25s ease-in-out;
  z-index: 2;

  &.expanded {
    transform: rotate(180deg);
  }
}
</style>
