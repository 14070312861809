<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DoorTypeSelect",
  props: {
    id: String,
    modelValue: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      items: [
        { value: "s", text: "Swing" },
        { value: "r", text: "Rollup" },
      ],
    };
  },
  methods: {
    changed(checked, value) {
      this.$emit("update:modelValue", checked ? value : null);
    },
  },
});
</script>

<template>
  <div class="d-flex align-center">
    <span class="mr-3 text-grey-darken-1">{{ $t("trucks.door-type") }}</span>
    <div :id="id" class="d-flex align-center">
      <v-checkbox
        v-for="item in items"
        :id="`${id}-${item.text.toLowerCase()}`"
        :key="item.value"
        :label="item.text"
        :model-value="item.value === modelValue"
        class="ma-0 pa-0 mr-3 text-grey-darken-2 type-door"
        color="primary"
        hide-details
        @change="changed($event, item.value)"
      />
    </div>
  </div>
</template>

<style scoped></style>
