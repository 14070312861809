<script>
import { TrailerType, TruckAdditionalEquipment, TruckDoorType, TruckEquipment, TruckType } from "@/data/truck";

export default {
  name: "TrucksMixin",
  data() {
    const data = {
      truckStatusAvailable: "a",
      truckStatusWillBeAvailable: "w",
      truckStatusOnHold: "h",
      truckStatusInRoute: "r",
      truckStatusNotAvailable: "n",
    };
    return {
      ...data,
      doorTypes: TruckDoorType,
      equipment: TruckEquipment,
      additionalEquipment: TruckAdditionalEquipment,
      truckStatuses: [
        {
          id: data.truckStatusNotAvailable,
          name: this.$t("trucks.status-n"),
          forTrip: true,
        },
        {
          id: data.truckStatusOnHold,
          name: this.$t("trucks.status-h"),
        },
        {
          id: data.truckStatusAvailable,
          name: this.$t("trucks.status-a"),
          disabled: true,
          forTrip: true,
        },
        {
          id: data.truckStatusWillBeAvailable,
          name: this.$t("trucks.status-w"),
          disabled: true,
        },
        {
          id: data.truckStatusInRoute,
          name: this.$t("trucks.status-r"),
          disabled: true,
        },
      ],
      trucksWithTrailer: TruckType.filter((t) => t.hasTrailer).map((t) => t.id),
      truckTypes: TruckType,
      trailerTypes: TrailerType,
    };
  },
  methods: {
    getEquipments(arr, value) {
      const map = {};
      for (const item of arr) {
        map[item.id] = item.name;
      }

      return value.map((key) => map[key] || "");
    },
    getTruckSize(truck) {
      return `${truck.trailer.length}″ x ${truck.trailer.width}″ x ${truck.trailer.height}″`;
    },
    getTruckType(type) {
      for (const t of this.truckTypes) {
        if (t.id === type) return t.name;
      }
      return "";
    },

    getReservationTime(truck) {
      if (!truck.reserved_till) return -1;
      return this.$dayjs(truck.reserved_till).diff(this.$dayjs(), "minutes");
    },
    isReservedByDispatcher(truck, dispatcherId) {
      if (!truck.reserved_by_id) return false;
      return truck.reserved_by_id === dispatcherId;
    },
    getReservedBy(truck) {
      if (truck.reserved_by_id === this.$store.state.dispatcher.id) return "(you)";
      return (this.$store.getters.getDispatcher(truck.reserved_by_id) || {}).name;
    },
  },
};
</script>
