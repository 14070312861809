const DATE_FORMAT = "MM/DD/YYYY";

const Chat = {
  New: "n",
  My: "m",
  Colleagues: "c",
  Archive: "a",
};

const Role = {
    Admin: "ad",
    TeamLead: "tl",
    Booking: "bk",
    HR: "hr",
    Operation: "op",
    Accounting: "ac",
  },
  Sections = {
    dispatchers: "dispatchers",
    drivers: "drivers",
    owners: "owners",
    trucks: "trucks",
  },
  Actions = {
    view: "view",
    edit: "edit",
    delete: "delete",
  };

const PERMISSIONS = {
  account: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
  },
  dashboard: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
  },
  chats: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
  },
  coordinators: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  dispatchers: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.TeamLead],
  },
  drivers: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  carriers: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  company: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  customers: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  facilities: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  orders: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  owners: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.Booking],
  },
  trips: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.Booking],
  },
  trucks: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
  logs: {
    view: [Role.Admin, Role.TeamLead, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
  },
  users: {
    view: [Role.Admin, Role.Booking, Role.HR, Role.Operation, Role.Accounting],
    edit: [Role.Admin, Role.HR],
  },
};

export { Actions, Chat, Role, Sections, PERMISSIONS, DATE_FORMAT };
