import { createStore } from "vuex";
import ChatStore from "@/store/chat.store";
import SnackbarStore from "@/store/snackbar.store";
import GalleryStore from "@/store/gallery.store";

export default createStore({
  state: {
    dispatcher: { perms: {}, company: { name: "Company name" } },
    dispatchers: [],
    mobile: false,
    title: "",
    ...ChatStore.state,
    ...SnackbarStore.state,
    ...GalleryStore.state,
  },
  getters: {
    ...ChatStore.getters,
    getDispatcher: (state) => (id) => {
      return state.dispatchers.find((d) => d.id === id) || null;
    },
  },
  mutations: {
    setMobile(state, mobile) {
      state.mobile = mobile;
    },
    setDispatchers(state, dispatchers) {
      state.dispatchers = dispatchers;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setDispatcher(state, dispatcher) {
      if (dispatcher.company) {
        dispatcher.company_name = dispatcher.company.name;
      }
      state.dispatcher = dispatcher;
    },
    ...ChatStore.mutations,
    ...SnackbarStore.mutations,
    ...GalleryStore.mutations,
  },
  actions: {
    init({ commit }) {
      commit("initChat");
    },
  },
});
