<script>
import UsersMixin from "@/views/users/UsersMixin.vue";
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";
import UserSelect from "@/views/users/components/UserSelect.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import CitizenshipSelect from "@/components/inputs/CitizenshipSelect.vue";
import OmniSelect from "@/components/controls/Select.vue";

const searchParamsKey = "trucks_search";

export default {
  name: "TrucksListSearchForm",
  components: { OmniSelect, CitizenshipSelect, TableFilterRow, TableFilter, DatePicker, SearchField, UserSelect },
  mixins: [BaseView, UsersMixin, TrucksMixin, ValidationMixin],
  emits: ["search"],
  data() {
    return {
      params: { availability_date: [], location: "", show_not_available: false, type: [] },
      showAdditionalFilters: false,
    };
  },
  computed: {
    statuses() {
      return this.truckStatuses.map((status) => {
        status.disabled = false;
        return status;
      });
    },
  },
  created() {
    this.params = this.getSearchParams(searchParamsKey);
    if (!this.params.radius) this.params.radius = 150;
    if (this.params.location) {
      this.search();
    }
  },
  methods: {
    clear() {
      this.params = { availability_date: [], location: "", radius: 150, show_not_available: false, type: [] };
      this.search();
    },
    async search() {
      if (!this.params.radius || !this.params.location) return;

      this.params.status = this.params.show_not_available
        ? []
        : [this.truckStatusAvailable, this.truckStatusWillBeAvailable, this.truckStatusInRoute];

      if (this.params.phone) {
        this.params.phone = this.params.phone.replaceAll(/\D/g, "").replaceAll(" ", "");
      }
      if (this.params.year && this.params.year.length === 0) {
        delete this.params.year;
      }

      this.setSearchParams(searchParamsKey, this.params);
      this.$emit("search", this.params);
    },
  },
};
</script>

<template>
  <table-filter @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col cols="3">
          <v-text-field
            v-model="params.location"
            :label="$t('trucks.location')"
            :rules="[requiredValidator]"
            color="primary"
            density="compact"
            hide-details
            required
            variant="outlined"
            @search="search"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="params.radius"
            :label="$t('trucks.radius')"
            :rules="[requiredValidator]"
            color="primary"
            density="compact"
            hide-details
            required
            suffix="mi"
            variant="outlined"
            @search="search"
          />
        </v-col>
        <v-col cols="2">
          <omni-select
            v-model="params.type"
            :clearable="true"
            :items="truckTypes"
            :label="$t('general.type')"
            :multiple="true"
            item-title="name"
            item-value="id"
            @blur="search"
            @change="search"
          />
        </v-col>
      </table-filter-row>
    </template>
    <table-filter-row :label="$t('trucks.details')" icon="mdi-truck-outline">
      <v-col cols="4">
        <date-picker
          v-model="params.availability_date"
          :label="$t('trucks.availability-date')"
          range
          @search="search"
        />
      </v-col>
      <v-col :style="{ flex: '0 0 100px' }">
        <v-checkbox v-model="params.team" :label="$t('trucks.team')" color="primary" density="compact" hide-details />
      </v-col>
      <v-col :style="{ flex: '0 0 100px' }">
        <v-checkbox v-model="params.signs" :label="$t('trucks.signs')" color="primary" density="compact" hide-details />
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="params.show_not_available"
          :label="$t('trucks.show-not-available')"
          color="primary"
          density="compact"
          hide-details
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :label="$t('trucks.trailer-details')" icon="mdi-truck-trailer">
      <v-col class="dimension-part">
        <search-field v-model="params.length" :label="$t('trucks.length')" type="number" @search="search" />
      </v-col>
      <v-col class="dimension-part">
        <search-field v-model="params.width" :label="$t('trucks.width')" type="number" @search="search" />
      </v-col>
      <v-col>
        <search-field v-model="params.height" :label="$t('trucks.height')" type="number" @search="search" />
      </v-col>
      <v-col>
        <search-field v-model="params.year" label="Year from" type="number" @search="search" />
      </v-col>
      <v-col>
        <search-field v-model="params.payload" :label="$t('trucks.payload')" type="number" @search="search" />
      </v-col>
      <v-col>
        <omni-select
          v-model="params.equipment"
          :clearable="true"
          :items="equipment"
          :label="$t('trucks.equipment')"
          :multiple="true"
          item-title="name"
          item-value="id"
          @change="search"
        />
      </v-col>
      <v-col>
        <omni-select
          v-model="params.additional_equipment"
          :clearable="true"
          :items="additionalEquipment"
          :label="$t('trucks.equipment-extra')"
          :multiple="true"
          item-title="name"
          item-value="id"
          @change="search"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :divider="false" :label="$t('trucks.owner-driver')" icon="mdi-account-multiple-outline">
      <v-col>
        <user-select v-model="params.owner" :label="$t('users.owner')" owners @change="search" />
      </v-col>
      <v-col>
        <user-select v-model="params.driver" :label="$t('users.driver')" drivers @change="search" />
      </v-col>
      <v-col>
        <v-select
          v-model="params.driver_certificate"
          :clearable="true"
          :items="driverCertificates"
          :label="$t('drivers.certificates')"
          color="primary"
          density="compact"
          hide-details
          item-title="name"
          item-value="id"
          variant="outlined"
          @change="search"
        />
      </v-col>
      <v-col>
        <citizenship-select v-model="params.driver_citizenship" :clearable="true" @change="search" />
      </v-col>
      <v-col>
        <v-select
          v-model="params.willing_cross_border"
          :clearable="true"
          :items="driverWillingsToCrossBorder"
          :label="$t('drivers.willing-cross-border')"
          color="primary"
          density="compact"
          hide-details
          item-title="name"
          item-value="id"
          variant="outlined"
          @change="search"
        />
      </v-col>
      <v-col>
        <v-checkbox
          v-model="params.search_by_home_location"
          :label="$t('drivers.search_by_home_location')"
          color="primary"
          density="compact"
          hide-details
          variant="outlined"
          @change="search"
        />
      </v-col>
    </table-filter-row>
  </table-filter>
</template>

<style lang="scss" scoped>
.dimension-part {
  position: relative;

  &:after {
    content: "х";
    position: absolute;
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
  }
}
</style>
