<script>
import TruckContactInfo from "@/views/trucks/blocks/ContactInfo.vue";

export default {
  name: "TruckContactBlock",
  components: { TruckContactInfo },
  props: {
    label: String,
    user: Object,
    fullAccess: Boolean,
    showCertificates: { type: Boolean, default: false },
    contactPerson: { type: Boolean, default: false },
    showStatus: { type: Boolean, default: true },
  },
};
</script>

<template>
  <div class="block-item pr-2 pl-2 mb-3">
    <div class="block-item__inner pa-4 bg-white rounded">
      <truck-contact-info
        :full-access="fullAccess"
        :is-contact-person="contactPerson"
        :label="label"
        :show-certificates="showCertificates"
        :show-status="showStatus"
        :user="user"
      ></truck-contact-info>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-item {
  position: relative;
  max-width: 20%;
  flex: 0 0 20%;
  min-width: 300px;
}

.block-item__inner {
  height: 100%;
}
</style>
