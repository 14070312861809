<script>
import { mapMutations, mapState } from "vuex";

import BaseView from "@/views/BaseView.vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import { MasksMixin } from "@/mixins/masks.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import UserFormInfo from "@/views/users/form/UserFormInfo.vue";
import { User } from "@/models/user";
import UserFormDriver from "@/views/users/form/UserFormDriver.vue";
import UserFormOwner from "@/views/users/form/UserFormOwner.vue";
import UserFormCoordinator from "@/views/users/form/UserFormCoordinator.vue";

export default {
  name: "UserEditForm",
  components: {
    UserFormCoordinator,
    UserFormOwner,
    UserFormDriver,
    UserFormInfo,
  },
  mixins: [BaseView, UsersMixin, MasksMixin, ValidationMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      isNewCompany: false,
      item: User.empty(),
      tab: 0,
      emailError: "",
    };
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
  watch: {
    user() {
      this.processUser();
    },
    dispatcher() {
      this.processUser();
    },
  },
  beforeMount() {
    this.processUser();
  },
  methods: {
    ...mapMutations(["showSnackbarError", "showSnackbarSuccess"]),
    processUser() {
      this.item = { ...this.user };
      if (!this.item.id) {
        this.item.hired_by_id = this.dispatcher.id;
        this.item.score = 0;
      }

      if (this.item.companies) {
        this.item.companies = this.item.companies.map((c) => {
          c.changed = false;
          return c;
        });
      }
    },
    async save() {
      const result = await this.$refs.form.validate();
      if (!result.valid) {
        this.scrollToError();
        return;
      }
      if (!this.item.is_owner && !this.item.is_driver && !this.item.is_coordinator) {
        this.showSnackbarError("User doesn't have any roles. Please select at least one role");
        return;
      }
      const item = { ...this.item };

      item.name = item.name?.trim();
      item.address_line_1 = item.address_line_1?.trim();
      item.address_city = item.address_city?.trim();
      item.license_class = item.license_class?.trim();
      item.license_number = item.license_number?.trim();

      if (!this.item.is_owner) item.owner_status = "u"; // non-owner status
      if (!this.item.is_driver) item.driver_id = null;
      if (item.mobile_phone_1) item.mobile_phone_1 = this.normalizePhone(item.mobile_phone_1);
      if (item.mobile_phone_2) item.mobile_phone_2 = this.normalizePhone(item.mobile_phone_2);
      if (item.home_phone) item.home_phone = this.normalizePhone(item.home_phone);
      if (item.emergency_contact_phone) {
        item.emergency_contact_phone = this.normalizePhone(item.emergency_contact_phone);
      }

      const resp = this.item.is_driver
        ? await this.$api.users.upsertDriver(item)
        : await this.$api.users.upsertUser(item);
      if (resp.success) {
        const userId = resp.data.id;

        // save owner companies
        if (item.companies && item.companies.length > 0) {
          for (const company of item.companies) {
            company.owner_id = userId;
            await this.$api.users.upsertCompany(userId, company);
          }
        }

        this.showSnackbarSuccess(this.$t("general.saved"));
        return this.$router.push({
          name: "user-view",
          params: { id: userId },
        });
      } else {
        this.showSnackbarError(resp.data.detail);
      }
      this.item.companies.forEach((company) => this.saveCompany(company, resp.data.id));
    },
    async saveCompany(company, userId) {
      company.owner_id = userId;
      const resp = await this.$api.users.upsertCompany(userId, company);
      if (!resp.success) {
        this.showSnackbarError(resp.data.detail);
      }
    },
  },
};
</script>

<template>
  <v-form ref="form" lazy-validation>
    <div class="about-user">
      <user-form-info v-model="item"></user-form-info>
      <user-form-driver v-model="item"></user-form-driver>
      <user-form-owner v-model="item"></user-form-owner>
      <user-form-coordinator v-model="item"></user-form-coordinator>
    </div>

    <div class="d-flex justify-end pa-5 bg-white user-info__buttons">
      <v-btn
        id="cancel-btn"
        class="mr-3 text-primary text-uppercase font-weight-600"
        height="36"
        variant="flat"
        @click="$router.back()"
      >
        {{ $t("general.cancel") }}
      </v-btn>
      <v-btn
        id="save-btn"
        class="pr-6 pl-6 font-weight-600 text-uppercase"
        color="primary"
        height="36"
        variant="flat"
        @click="save"
      >
        {{ $t("general.save") }}
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.about-user {
  padding-bottom: 43px;
}

.user-info__buttons {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  box-shadow: 0px -8px 20px -7px rgba(0, 0, 0, 0.1);
}

.vertical_collpsed {
  .user-info__buttons {
    width: 100%;
  }
}
</style>
