<script>
export default {
  name: "NotFound",
  props: {
    icon: String,
    text: String,
  },
};
</script>

<template>
  <v-container :fluid="true" class="d-flex justify-center">
    <div class="mt-8">
      <div class="text-center">
        <v-icon :icon="icon || 'mdi-web-remove'" color="grey" size="96"></v-icon>
      </div>
      <div class="not-found--header text-center text-grey">404</div>
      <div class="not-found--text text-center text-grey-lighten-1">{{ text || "Page not found" }}</div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.not-found--header {
  font-size: 80px;
  font-weight: bold;
  line-height: 96px;
}

.not-found--text {
  font-size: 24px;
  line-height: 36px;
}
</style>
