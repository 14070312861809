import { BaseApi } from "@/api/base";

export class OrdersApi extends BaseApi {
  findOrderFiles(orderId) {
    return this.client.get("orders/" + orderId + "/files");
  }

  findOrders(options = {}, abortable = true) {
    if (abortable && this.ordersListController) {
      this.ordersListController.abort();
    }
    this.ordersListController = new AbortController();

    return this.client
      .get("orders", options, { signal: abortable ? this.ordersListController.signal : null })
      .then((response) => {
        this.ordersListController = null;
        return response;
      });
  }

  findOrderById(orderId) {
    return this.client.get("orders/" + orderId);
  }

  upsertOrder(order) {
    return this.client.save("orders", order);
  }
}
