<script>
import TrucksMixin from "@/views/trucks/TrucksMixin.vue";

export default {
  name: "TruckEquipmentBlock",
  mixins: [TrucksMixin],
  props: {
    truck: Object,
  },
  computed: {
    additionalNames() {
      if (!this.truck.trailer || !this.truck.trailer.additional_equipment) return "";

      return this.getEquipments(this.additionalEquipment, this.truck.trailer.additional_equipment).join(", ");
    },
    equipmentNames() {
      if (!this.truck.trailer || !this.truck.trailer.equipment) return "";

      return this.getEquipments(this.equipment, this.truck.trailer.equipment).join(", ");
    },
  },
};
</script>

<template>
  <div class="block-item pr-2 pl-2 mb-3">
    <div class="block-item__inner pa-4 bg-white rounded">
      <div class="block-item__labels">
        <span
          v-if="truck.signs"
          class="block-item__label bg-grey-lighten-4 text-grey-darken-1 rounded pr-1 pl-1 caption"
        >
          {{ $t("trucks.signs") }}
        </span>
      </div>
      <div class="text-grey-darken-3 mb-3">{{ $t("trucks.truck") }}</div>
      <div class="mb-4">
        <div class="text-grey-darken-2 font-size-12 mb-1">Equipment</div>
        <div class="text-grey-darken-3">{{ equipmentNames }}</div>
      </div>
      <div class="">
        <div class="text-grey-darken-2 font-size-12 mb-1">Additional equipment</div>
        <div class="text-grey-darken-3">{{ additionalNames }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block-item {
  position: relative;
  max-width: 20%;
  flex: 0 0 20%;
  min-width: 300px;
}

.block-item__inner {
  height: 100%;
}

.block-item__labels {
  position: absolute;
  top: 8px;
  right: 8px;
  width: calc(100% - 16px);
  padding: 0 8px;
  text-align: right;
}

.block-item__label {
  min-width: auto !important;
  height: auto !important;
  line-height: 16px !important;

  &:not(:last-child) {
    margin-right: 6px;
  }
}
</style>
