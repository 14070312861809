export const OrderStatus = {
  planned: "planned",
  unassigned: "new",
  inProgress: "in-progress",
  cancelled: "cancelled",
  completed: "completed",
  tonu: "tonu",
  waitingTonu: "wait-tonu",
  noTonu: "no-tonu",
};

export const OrderStatusMap = [
  {
    id: OrderStatus.planned,
    name: "Planned",
  },
  {
    id: OrderStatus.unassigned,
    name: "Unassigned",
  },
  {
    id: OrderStatus.completed,
    name: "Completed",
  },
  {
    id: OrderStatus.waitingTonu,
    name: "Waiting for TONU",
    forCancellation: true,
  },
  {
    id: OrderStatus.tonu,
    name: "TONU",
    forCancellation: true,
  },
  {
    id: OrderStatus.cancelled,
    name: "Cancelled",
    forCancellation: true,
  },
  {
    id: OrderStatus.inProgress,
    name: "In progress",
  },
  {
    id: OrderStatus.noTonu,
    name: "No TONU",
  },
];

export const OrderCancellationStatusMap = OrderStatusMap.filter((s) => s.forCancellation);

export const OrderStopTimeType = {
  fcfs: "f",
  asap: "as",
  direct: "d",
  appointment: "ap",
};

export const OrderStopTimeTypeMap = [
  {
    id: OrderStopTimeType.fcfs,
    name: "FCFS",
  },
  {
    id: OrderStopTimeType.asap,
    name: "ASAP",
  },
  {
    id: OrderStopTimeType.direct,
    name: "Direct",
  },
  {
    id: OrderStopTimeType.appointment,
    name: "Appointment",
  },
];

export const OrderStopType = {
  pickup: "p",
  delivery: "d",
  reload: "r",
};

export const OrderStopTypeMap = [
  {
    id: OrderStopType.pickup,
    name: "Pick up",
  },
  {
    id: OrderStopType.delivery,
    name: "Delivery",
  },
];

export const OrderFreightType = {
  pallets: "pl",
  pieces: "pc",
};

export const OrderFreightTypeMap = [
  {
    id: OrderFreightType.pallets,
    name: "Pallets",
  },
  {
    id: OrderFreightType.pieces,
    name: "Pieces",
  },
];

export const OrderStopStatus = {
  notStarted: "new",
  inProgress: "in-progress",
  partiallyCompleted: "part-completed",
  completed: "completed",
};

export const OrderStopStatusMap = [
  {
    id: OrderStopStatus.notStarted,
    name: "Not started",
  },
  {
    id: OrderStopStatus.inProgress,
    name: "In progress",
  },
  {
    id: OrderStopStatus.partiallyCompleted,
    name: "Partially completed",
  },
  {
    id: OrderStopStatus.completed,
    name: "Completed",
  },
];

export const OrderStopState = {
  normal: "normal",
  new: "new",
  updated: "updated",
  deleted: "deleted",
};

export const OrderStopStateMap = [
  {
    id: OrderStopState.normal,
    name: "Normal",
  },
  {
    id: OrderStopState.new,
    name: "New stop",
  },
  {
    id: OrderStopState.updated,
    name: "Updated",
  },
  {
    id: OrderStopState.deleted,
    name: "Deleted",
  },
];
