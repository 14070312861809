<script>
import { mapState } from "vuex";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "LoginPage",
  mixins: [BaseView],
  data() {
    return {
      error: "",
      email: "",
      password: "",
      show_pass: false,
    };
  },
  computed: {
    ...mapState(["mobile"]),
  },
  created() {
    this.setTitle(this.$t("auth.title"));
  },
  methods: {
    async signIn() {
      this.error = "";
      this.$api.client.setToken("");

      const resp = await this.$api.auth.login(this.email, this.password);
      if (resp.success && resp.data.token) {
        this.$api.client.saveToken(resp.data.token);
        this.$store.commit("setDispatcher", resp.data.dispatcher);

        return this.$router.push({ name: "chats" });
      } else {
        if (resp.status === 400) {
          this.error = "Wrong Email or password";
          return;
        }
        this.error = `Server error (code: ${resp.error.response.status})`;
      }
    },
  },
};
</script>

<template>
  <v-layout class="login-form pa-0 align-center justify-center" full-height>
    <div class="pa-7 login-form__inner">
      <div class="login-form__top pt-10 pr-6 pb-8 pl-6">
        <div class="mb-8">
          <div class="login-form__logo mb-4">
            <img alt="Logo" src="@/assets/images/logo.svg" width="50" />
          </div>
          <div class="login-form__title text-h5 text-center font-weight-medium mb-2">
            {{ $t("auth.title") }}
          </div>
          <div class="login-form__subtitle text-grey-darken-1 text-body-2 text-center mb-0">Log in to your account</div>
        </div>

        <v-form>
          <v-card variant="flat">
            <v-card-text class="px-0 mb-6">
              <v-text-field
                v-model="email"
                :error-messages="error"
                :label="$t('auth.email')"
                class="mb-5"
                color="primary"
                density="compact"
                hide-details
                required
                type="email"
                variant="outlined"
              />
              <v-text-field
                v-model="password"
                :append-inner-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :error-messages="error"
                :label="$t('auth.password')"
                :type="show_pass ? 'text' : 'password'"
                color="primary"
                density="compact"
                hide-details="auto"
                required
                variant="outlined"
                @click:append-inner="show_pass = !show_pass"
              />
            </v-card-text>
            <v-card-actions class="pa-0">
              <v-btn
                class="mb-4 width-100 text-uppercase font-weight-600"
                color="primary"
                height="45"
                variant="flat"
                @click="signIn"
                >{{ $t("auth.log-in") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <!--div class="subtitle mb-6">{{ $t('auth.not-registered') }}? <router-link to="register">{{ $t('auth.register') }}</router-link></div-->
        <div class="subtitle">
          <router-link
            class="pa-1 text-uppercase text-primery text-decoration-none font-weight-600 text-center d-block font-size-12"
            to="reset-password"
          >
            {{ $t("auth.forgot-password") }}
          </router-link>
        </div>
      </div>

      <div class="login-form__bottom">
        <div class="d-flex align-center font-weight-medium">
          <span class="mr-4 text-grey-darken-3">{{ $t("auth.support") }}:</span>
          <a class="text-primary" href="mailto:info@omni-dispatch.com">info@omni-dispatch.com</a>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<style lang="scss" scoped>
.form {
  background-color: #f5f5ff;
}

.login-form__inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .login-form__top {
    width: 430px;
    margin: 0 auto;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background: #fff;
  }
}

.login-form__logo {
  max-width: 50px;
  margin: 0 auto;
}

.login-form__bottom {
  position: absolute;
  bottom: 35px;
}
</style>

<style lang="scss">
.login-form__inner {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
  }
}

.login-form__inner {
  .v-text-field--outlined.v-input--dense .v-label {
    top: 50%;
    transform: translateY(-50%);
  }

  .v-text-field--outlined.v-input--dense .v-label--active {
    top: 0;
    transform: translateY(-8px) scale(0.75);
  }

  .v-input__append-inner {
    align-self: center;
    margin-top: 0 !important;
  }
}
</style>
