export const StartPointMarker = {
  path: "M 9.7685 23.0866 C 9.7296 23.1333 9.6866 23.1763 9.6399 23.2152 C 9.2154 23.5686 8.5849 23.511 8.2315 23.0866 C 2.74384 16.4959 0 11.6798 0 8.63811 C 0 3.86741 4.2293 0 9 0 C 13.7707 0 18 3.86741 18 8.63811 C 18 11.6798 15.2562 16.4959 9.7685 23.0866 Z M 9 12 C 10.6569 12 12 10.6569 12 9 C 12 7.34315 10.6569 6 9 6 C 7.3431 6 6 7.34315 6 9 C 6 10.6569 7.3431 12 9 12 Z",
  fillColor: "#4CAF50",
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: "white",
  scale: 1.5,
  anchor: {
    x: 8,
    y: 22,
  },
};

export const EndPointMarker = {
  path: "M 9.7685 23.0866 C 9.7296 23.1333 9.6866 23.1763 9.6399 23.2152 C 9.2154 23.5686 8.5849 23.511 8.2315 23.0866 C 2.74384 16.4959 0 11.6798 0 8.63811 C 0 3.86741 4.2293 0 9 0 C 13.7707 0 18 3.86741 18 8.63811 C 18 11.6798 15.2562 16.4959 9.7685 23.0866 Z M 9 12 C 10.6569 12 12 10.6569 12 9 C 12 7.34315 10.6569 6 9 6 C 7.3431 6 6 7.34315 6 9 C 6 10.6569 7.3431 12 9 12 Z",
  fillColor: "#F44336",
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: "white",
  scale: 1.5,
  anchor: {
    x: 8,
    y: 22,
  },
};

export const SelectedPointMarker = {
  path: "M 9.7685 23.0866 C 9.7296 23.1333 9.6866 23.1763 9.6399 23.2152 C 9.2154 23.5686 8.5849 23.511 8.2315 23.0866 C 2.74384 16.4959 0 11.6798 0 8.63811 C 0 3.86741 4.2293 0 9 0 C 13.7707 0 18 3.86741 18 8.63811 C 18 11.6798 15.2562 16.4959 9.7685 23.0866 Z M 9 12 C 10.6569 12 12 10.6569 12 9 C 12 7.34315 10.6569 6 9 6 C 7.3431 6 6 7.34315 6 9 C 6 10.6569 7.3431 12 9 12 Z",
  fillColor: "#304FFE",
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: "white",
  scale: 1.5,
  anchor: {
    x: 8,
    y: 22,
  },
};

export const PointMarker = {
  path: "M 24 12 C 24 18.6274 18.6274 24 12 24 C 5.37258 24 0 18.6274 0 12 C 0 5.37258 5.37258 0 12 0 C 18.6274 0 24 5.37258 24 12 Z",
  fillColor: "white",
  fillOpacity: 1,
  strokeWeight: 3,
  strokeColor: "#304FFE",
  scale: 0.4,
  anchor: {
    x: 8,
    y: 6,
  },
};

export const PathArrow = {
  path: "M -0.5,0 0,0.5 0.5,0",
  strokeColor: "white",
  strokeWeight: 2,
  scale: 4.5,
};

export const PathArrowReverse = {
  path: "M 0.5,0 0,-0.5 -0.5,0",
  strokeColor: "white",
  strokeWeight: 2,
  scale: 4.5,
};
