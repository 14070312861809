<script>
import { RouteStopStatusMap } from "@/data/trip";

export default {
  name: "RouteStopStatusLabel",
  props: {
    stop: Object,
  },
  computed: {
    status() {
      const statuses = RouteStopStatusMap.filter((s) => s.id === this.stop.status);
      if (statuses.length === 1) {
        return statuses[0].name;
      } else if (statuses.length > 1) {
        return statuses.find((s) => s.type === this.stop.type)?.name;
      }
      return null;
    },
  },
};
</script>

<template>
  <div class="font-size-12 font-weight-500 text-grey-darken-1" v-bind="$props">
    {{ status }}
  </div>
</template>

<style scoped></style>
