import { Chat } from "@/const";

const DRAFT_MESSAGES_STORAGE_KEY = "_draft_messages";
const CHAT_TABS_STORAGE_KEY = "_chat_tabs";

const getThreadGroup = (dispatcher, thread) => {
  if (thread.resolved) {
    return Chat.Archive;
  }

  if (thread.dispatcher_id === dispatcher.id && !thread.resolved) {
    return Chat.My;
  }
  if (thread.dispatcher_id && thread.dispatcher_id !== dispatcher.id) {
    return Chat.Colleagues;
  }
  if (!thread.dispatcher_id) {
    return Chat.New;
  }

  return null;
};

const ChatStore = {
  state: {
    chatUser: {},
    threads: [],
    threadsIds: {},
    threadsCount: { new: 0, my: 0, colleagues: 0, archive: 0 },
    threadsMessages: { new: 0, my: 0, colleagues: 0, archive: 0 },
    draftMessages: {},
    activeTabs: [],
    offline: false,
  },
  getters: {
    getThread: (state) => (id) => {
      const thread = state.threads.find((t) => t.id === id);
      return thread ? thread : null;
    },
    getDraftMessage: (state) => (id) => {
      let message = state.draftMessages[id];
      if (!message || typeof message !== "object") {
        message = {};
      }
      return message;
    },
  },
  mutations: {
    initChat(state) {
      const storedMessages = localStorage.getItem(DRAFT_MESSAGES_STORAGE_KEY);
      if (storedMessages) {
        state.draftMessages = JSON.parse(storedMessages);
      }
      const storedTabs = localStorage.getItem(CHAT_TABS_STORAGE_KEY);
      if (storedTabs) {
        state.activeTabs = JSON.parse(storedTabs);
      }
    },
    handleWSMessage(state, data) {
      if (!data.success) {
        console.error("Websocket receives error", data.error);
        return;
      }

      let threadsLastIndex = -1;
      switch (data.command) {
        case "pong":
          break;

        case "message":
          delete data.command;
          delete data.success;
          this.commit("updateThreadMessage", data);
          break;

        case "messages":
          if (!data.messages) break;
          for (const msg of data.messages) {
            this.commit("updateThreadMessage", msg);
          }
          break;

        case "thread":
          delete data.command;
          delete data.success;
          this.commit("updateThread", data);
          this.commit("sortThreads");
          this.commit("recalcThreadsCount");
          break;

        case "threads":
          if (!data.threads) break;
          threadsLastIndex = data.threads.length - 1;
          for (const i in data.threads) {
            this.commit("updateThread", data.threads[i], i === threadsLastIndex);
          }
          if (data.total > state.threads.length) {
            console.log("Need to load more chat threads", data.total, state.threads.length);
          }
          break;

        case "token":
          // nothing to do
          break;

        default:
          console.error("Unknown command", data);
          break;
      }
    },
    setChatUser(state, user) {
      state.chatUser = user;
    },
    setOffline(state, offline) {
      if (state.offline !== offline) {
        console.log("Change offline state", offline);
        state.offline = offline;
      }
    },
    addThreads(state, newThreads) {
      const threads = [...state.threads];

      for (const thread of newThreads) {
        thread.counter = 0;
        thread.messages = [];
        thread.user = thread.user || {};
        thread.name = thread.user.name;
        thread.last_message = thread.last_message || "";
        thread.group = getThreadGroup(state.dispatcher, thread);
        threads.push(thread);
      }

      state.threads = threads;
    },
    sortThreads(state) {
      const threads = [...state.threads];

      threads.sort(function (a, b) {
        if (a.last_message_dt < b.last_message_dt) return 1; // desc
        if (a.last_message_dt > b.last_message_dt) return -1; // desc
        return 0;
      });

      state.threads = threads;
    },
    recalcThreadsCount(state) {
      const counts = { n: 0, m: 0, c: 0, a: 0 },
        newMessages = { n: 0, m: 0, c: 0, a: 0 };

      state.threads.forEach((thread) => {
        counts[thread.group]++;
        newMessages[thread.group] += thread.new_messages_count > 0 ? 1 : 0;
      });

      // update threads count in state
      state.threadsCount.new = counts.n;
      state.threadsCount.my = counts.m;
      state.threadsCount.colleagues = counts.c;
      state.threadsMessages.new = newMessages.n;
      state.threadsMessages.my = newMessages.m;
      state.threadsMessages.colleagues = newMessages.c;
    },
    updateThread(state, thread) {
      thread.group = getThreadGroup(state.dispatcher, thread);

      let existsThread = state.threads.find((t) => t.id === thread.id);
      if (existsThread === undefined) {
        thread.counter = 0;
        thread.messages = [];
        thread.user = thread.user || {};
        thread.name = thread.user.name;
        thread.last_message = thread.last_message || "";

        state.threads.push({ ...thread });
      } else {
        existsThread.group = thread.group;
        existsThread.resolved = thread.resolved;
        existsThread.updated_dt = thread.updated_dt;
        existsThread.dispatcher = thread.dispatcher;
        existsThread.dispatcher_id = thread.dispatcher_id;
        existsThread.last_message = thread.last_message || "";
        existsThread.last_message_dt = thread.last_message_dt;
        existsThread.new_messages_count = thread.new_messages_count;
        if (thread.user) {
          existsThread.user = thread.user;
          existsThread.name = thread.user.name;
          existsThread.user_id = thread.user.id;
        }
      }

      // sort threads when we explicitly denied it
      if (thread.sorting === undefined || thread.sorting === true) {
        this.commit("sortThreads");
        this.commit("recalcThreadsCount");
      }
    },
    updateThreadMessage(state, message) {
      let threadIndex = state.threads.findIndex((thread) => thread.id === message.thread_id);

      if (threadIndex === -1) {
        state.threads.push({
          id: message.thread_id,
          name: "",
          messages: [],
          counter: 0,
          user: {},
          last_message: "",
        });
        threadIndex = state.threads.length - 1;
      }

      // find message to update or add new
      let existingMessage = state.threads[threadIndex].messages.find((m) => m.id === message.id);
      if (existingMessage === undefined) {
        state.threads[threadIndex].messages.push(message);
      } else {
        existingMessage.type = message.type;
        existingMessage.text = message.text;
        existingMessage.read = message.read;
      }
      state.threads[threadIndex].messages.sort(function (a, b) {
        if (a.created < b.created) return -1;
        if (a.created > b.created) return 1;
        return 0;
      });

      // rebuild thread in UI
      state.threads[threadIndex].counter++;
    },
    addOlderMessages(state, payload) {
      const threadIndex = state.threads.findIndex((thread) => thread.id === payload.threadId);
      if (threadIndex === -1) return;
      const thread = state.threads[threadIndex];
      for (let message of payload.messages.reverse()) {
        if (thread.messages.findIndex((m) => m.id === message.id) === -1) {
          thread.messages.unshift(message);
        }
      }
    },
    saveDraftMessage(state, payload) {
      state.draftMessages[payload.id] = { message: payload.message, file: payload.file };
      localStorage.setItem(DRAFT_MESSAGES_STORAGE_KEY, JSON.stringify(state.draftMessages));
    },
    removeDraftMessage(state, id) {
      delete state.draftMessages[id];
      localStorage.setItem(DRAFT_MESSAGES_STORAGE_KEY, JSON.stringify(state.draftMessages));
    },
    saveTabsState(state, payload) {
      state.activeTabs = payload;
      localStorage.setItem(CHAT_TABS_STORAGE_KEY, JSON.stringify(state.activeTabs));
    },
  },
};

export default ChatStore;
