<script>
export default {
  name: "LanguageTag",
  props: {
    language: { type: String, required: true },
    light: Boolean,
  },
  computed: {
    classes() {
      return this.light ? "bg-white" : "bg-grey-lighten-4";
    },
  },
};
</script>

<template>
  <span
    :class="classes"
    class="text-uppercase rounded text-grey-darken-1 pa-1 caption d-flex align-center"
    data-qa="language-tag"
  >
    {{ language }}
  </span>
</template>

<style lang="scss" scoped></style>
