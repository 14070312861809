<script>
import { mapState } from "vuex";
import { DateTimeMixin } from "@/mixins/date-time.mixin";

export default {
  name: "DashboardPage",
  mixins: [DateTimeMixin],
  data() {
    return {
      perms: "dashboard",
    };
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
};
</script>

<template>
  <v-container :fluid="true" class="fill-height">
    <v-row align="center" justify="center">
      Dashboard
      {{ dateTZ($dayjs(), dispatcher.timezone) }}
    </v-row>
  </v-container>
</template>
