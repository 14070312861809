<script>
const FORM_SYMBOL = Symbol.for("OmniForm");

export default {
  name: "OmniForm",
  provide: {
    formSymbol: FORM_SYMBOL,
  },
  inheritAttrs: false,
  props: {
    flat: Boolean,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<template>
  <v-container :class="{ 'px-6': !flat, 'px-0': flat }" :fluid="true" class="pt-8 pb-14">
    <v-form ref="form" aria-autocomplete="none" role="presentation" validate-on="lazy">
      <slot></slot>
    </v-form>
  </v-container>
</template>

<style lang="scss" scoped></style>
