import { BaseApi } from "@/api/base";

export class UsersApi extends BaseApi {
  findDrivers(options = {}) {
    if (this.driversListController) {
      this.driversListController.abort();
    }
    this.driversListController = new AbortController();
    return this.client.get("drivers", options, { signal: this.driversListController.signal }).then((response) => {
      this.driversListController = null;
      return response;
    });
  }

  findOwners(options = {}) {
    if (this.ownersListController) {
      this.ownersListController.abort();
    }
    this.ownersListController = new AbortController();
    return this.client.get("owners", options, { signal: this.ownersListController.signal }).then((response) => {
      this.ownersListController = null;
      return response;
    });
  }

  findCoordinators(options = {}) {
    if (this.coordinatorsListController) {
      this.coordinatorsListController.abort();
    }
    this.coordinatorsListController = new AbortController();
    return this.client
      .get("coordinators", options, { signal: this.coordinatorsListController.signal })
      .then((response) => {
        this.coordinatorsListController = null;
        return response;
      });
  }

  findCoordinatorOwners(coordinatorId, options = {}) {
    return this.client.get("/coordinators/" + coordinatorId + "/owners", options);
  }

  findOwnerCoordinators(ownerId, options = {}) {
    return this.client.get("/owners/" + ownerId + "/coordinators", options);
  }

  createOwnerCoordinator(ownerId, userId, fullAccess) {
    return this.client.post("/owners/" + ownerId + "/coordinators", { full_access: fullAccess, user_id: userId });
  }

  removeOwnerCoordinator(ownerId, userId) {
    return this.client.delete("/owners/" + ownerId + "/coordinators/" + userId);
  }

  updateOwnerCoordinator(ownerId, userId, fullAccess) {
    return this.client.patch("/owners/" + ownerId + "/coordinators/" + userId, { full_access: fullAccess });
  }

  findUsers(options = {}) {
    return this.client.get("users", options);
  }

  findUserById(userId) {
    return this.client.get("users/" + userId);
  }

  findDriverById(driverId) {
    return this.client.get("drivers/" + driverId);
  }

  findOwnerById(ownerId) {
    return this.client.get("owners/" + ownerId);
  }

  upsertUser(user) {
    return this.client.save("/users", user);
  }

  async upsertDriver(driver) {
    return this.client.save("/drivers", driver);
  }

  updateDriver(driverId, data) {
    return this.client.patch("drivers/" + driverId, data);
  }

  findCompanies(userId) {
    return this.client.get("owners/" + userId + "/companies");
  }

  upsertCompany(userId, company) {
    return this.client.save("/owners/" + userId + "/companies", company);
  }

  sendInvite(userId) {
    return this.client.post("/users/" + userId + "/send-invite");
  }

  archiveUser(userId) {
    return this.client.post("/users/" + userId + "/archive");
  }

  unarchiveUser(userId) {
    return this.client.post("/users/" + userId + "/unarchive");
  }
}
