const FileMixin = {
  methods: {
    getObjectUrl(file) {
      return URL.createObjectURL(file);
    },
    isPdf(file) {
      return file.type === "application/pdf";
    },
    isImage(file) {
      const imageFileTypes = ["image/jpeg", "image/png"];
      return imageFileTypes.indexOf(file.type) > -1;
    },
    isImageUrl(url) {
      const imageExtensions = ["jpg", "jpeg", "gif", "png", "bmp"];
      return this.isUrlOfExtensions(url, imageExtensions);
    },
    isPdfUrl(url) {
      const pdfExtensions = ["pdf"];
      return this.isUrlOfExtensions(url, pdfExtensions);
    },
    isUrlOfExtensions(url, extensions) {
      const regexp = new RegExp("^.+\\.(" + extensions.join("|") + ")$");
      return regexp.test(url);
    },
    downloadUrl(url) {
      const link = document.createElement("a");
      link.href = url + "?download=true";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // Converts base64 string to File object
    dataURLtoFile(data, filename) {
      let arr = data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    detectMimeType(base64) {
      const chunks = base64.split(",");
      base64 = chunks[1] || chunks[0];
      const signatures = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpg",
      };
      for (const s in signatures) {
        if (base64.indexOf(s) === 0) {
          return signatures[s];
        }
      }
    },
  },
};

export default FileMixin;
