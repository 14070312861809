<script>
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import { mapState } from "vuex";

export default {
  name: "OnlineStatus",
  mixins: [DateTimeMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["dispatcher"]),
  },
};
</script>

<template>
  <span v-if="user.mobile_user_last_online">
    {{ dateTZ(user.mobile_user_last_online, dispatcher.timezone) }}
  </span>
  <span v-else>The user hasn’t yet logged in the app.</span>
</template>

<style lang="scss" scoped></style>
