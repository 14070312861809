<script>
import PhoneNumber from "@/components/PhoneNumber.vue";
import OpenChatButton from "@/components/controls/OpenChatButton.vue";

export default {
  name: "TruckDriverInfo",
  components: { OpenChatButton, PhoneNumber },
  props: {
    driver: Object,
  },
};
</script>

<template>
  <div class="d-flex justify-space-between align-center">
    <div>
      <v-tooltip top>
        <template #activator="{ props }">
          <v-btn
            class="status-item text-uppercase text-indigo-lighten-2 pa-0 mr-2 font-weight-600"
            color="bg-grey-lighten-4"
            v-bind="props"
            variant="tonal"
          >
            {{ $t("users.dr") }}
          </v-btn>
        </template>
        <span class="text-body-2">{{ $t("users.driver") }}</span>
      </v-tooltip>
      <router-link
        :to="{ name: 'user-view', params: { id: driver.id } }"
        class="font-size-12 font-weight-500 text-primary text-decoration-none mr-2"
        target="_blank"
      >
        {{ driver.name }}
      </router-link>
      <phone-number :number="driver.phone" class="font-size-12 mr-2" />
      <span class="text-uppercase bg-grey-lighten-4 rounded text-grey-darken-1 pr-1 pl-1 caption type-language">
        {{ $filters.upper(driver.language) }}
      </span>
    </div>
    <open-chat-button :user="driver"></open-chat-button>
  </div>
</template>

<style scoped>
.status-item {
  font-size: 9px;
  line-height: 16px;
  height: 16px !important;
  border-radius: 100px;
  padding: 0 2px !important;
  min-width: auto !important;
}
</style>
