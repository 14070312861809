<script>
import { defineComponent } from "vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import UserSelect from "@/views/users/components/UserSelect.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";

export default defineComponent({
  name: "OwnersSearchForm",
  components: { TableFilterRow, TableFilter, SearchField, UserSelect },
  mixins: [BaseView, UsersMixin],
  emits: ["search"],
  data() {
    return {
      params: {},
      showAdditionalFilters: false,
    };
  },
  created() {
    this.params = this.getSearchParams("owners");
    this.search();
  },
  methods: {
    clear() {
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams("owners", this.params);
      this.$emit("search", this.params);
    },
  },
});
</script>

<template>
  <table-filter @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col>
          <search-field
            id="search--name-field"
            v-model="params.name"
            :label="$t('general.full-name')"
            @search="search"
          />
        </v-col>
        <v-col>
          <v-select
            id="search--status-select"
            v-model="params.status"
            :clearable="true"
            :items="ownerStatuses"
            :label="$t('general.status')"
            color="primary"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            variant="outlined"
            @change="search"
          />
        </v-col>
        <v-col>
          <search-field id="search--truck-field" v-model="params.truck" :label="$t('drivers.truck')" @search="search" />
        </v-col>
        <v-col>
          <search-field
            id="search--phone-field"
            v-model="params.phone"
            :label="$t('general.phone')"
            mask="1 ### ### ####"
            @search="search"
          />
        </v-col>
        <v-col>
          <search-field
            id="search--email-field"
            v-model="params.email"
            :label="$t('general.email')"
            type="email"
            @search="search"
          />
        </v-col>
      </table-filter-row>
    </template>
    <table-filter-row :label="$t('general.address')" icon="mdi-map-marker-radius-outline">
      <v-col>
        <search-field id="search--city-field" v-model="params.city" :label="$t('general.city')" @search="search" />
      </v-col>
      <v-col>
        <search-field id="search--state-field" v-model="params.state" :label="$t('general.state')" @search="search" />
      </v-col>
      <v-col>
        <search-field
          id="search--zip-field"
          v-model="params.zip"
          :label="$t('general.zip')"
          type="number"
          @search="search"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :label="$t('general.workers')" icon="mdi-account-multiple-outline">
      <v-col>
        <user-select
          id="search--driver-select"
          v-model="params.driver"
          :label="$t('users.driver')"
          drivers
          @change="search"
        />
      </v-col>
      <v-col>
        <user-select
          id="search--coordinator-select"
          v-model="params.coordinator"
          :label="$t('users.coordinator')"
          coordinators
          @change="search"
        />
      </v-col>
    </table-filter-row>
  </table-filter>
</template>
