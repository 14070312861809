<script>
import BaseView from "@/views/BaseView.vue";
import ChatMixin from "@/views/chats/ChatMixin.vue";
import MessagesList from "@/views/chats/MessagesList.vue";
import NewMessageManager from "@/views/chats/NewMessageManager.vue";
import { mapState } from "vuex";
import ReclaimChatAction from "@/views/chats/actions/ClaimChatAction.vue";
import TransferChatAction from "@/views/chats/actions/TransferChatAction.vue";
import ResolveChatAction from "@/views/chats/actions/ResolveChatAction.vue";

export default {
  name: "ChatDialog",
  components: { ResolveChatAction, TransferChatAction, ReclaimChatAction, NewMessageManager, MessagesList },
  mixins: [BaseView, ChatMixin],
  props: {
    user: Object,
  },
  emits: ["close"],
  data() {
    return {
      thread: {
        user: {},
      },
    };
  },
  computed: {
    ...mapState(["mobile", "dispatcher"]),
  },
  watch: {
    async user() {
      await this.loadThread();
    },
  },
  async mounted() {
    await this.loadThread();
  },
  methods: {
    async loadThread() {
      if (!this.user.id) return;
      try {
        const resp = await this.$api.chat.findThreadByUserId(this.user.id);
        if (resp.success && resp.data.id) {
          const thread = resp.data;
          const respUser = await this.$api.users.findUserById(this.user.id);
          if (respUser.success) {
            thread.user = { ...thread.user, ...respUser.data };
          }
          this.$store.commit("updateThread", thread);
          this.thread = this.$store.getters.getThread(resp.data.id);
          await this.$ws.sendCommand("messages", {
            thread_id: this.thread.id,
            limit: 25,
          });
        }
      } catch (e) {
        console.error("Error loading user thread", e);
      }
    },
  },
};
</script>

<template>
  <v-card class="claim-modal light">
    <div class="header pe-6 pt-7 pb-7 pa-6 bg-white">
      <div class="header__inner d-flex justify-space-between position-relative">
        <div class="header__left">
          <div class="d-flex align-md-center">
            <v-avatar :color="avatarColor(thread)" class="me-2" size="44">
              <span class="text-white">{{ $filters.initials(user.name) }}</span>
            </v-avatar>
            <div>
              <h4 class="driver__name mb-1 d-flex align-center">
                {{ user.name }}
              </h4>
              <div class="user-labels d-flex flex-wrap align-center">
                <router-link
                  v-if="thread.user.driver_truck"
                  :to="{ name: 'truck-view', params: { id: thread.user.driver_truck.id } }"
                  class="mr-2 d-flex align-center caption text-decoration-none"
                >
                  <v-icon class="mr-1" size="14">mdi-truck-outline</v-icon>
                  <span class="text-primary font-weight-600 caption text-uppercase">
                    {{ thread.user.driver_truck.number }}
                  </span>
                </router-link>
                <span
                  v-if="user.is_driver"
                  :class="user.archived ? 'bg-grey-darken-2' : 'bg-indigo-lighten-2'"
                  class="user-label mr-2 bg-indigo-lighten-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                >
                  Driver
                </span>
                <span
                  v-if="user.is_owner"
                  :class="user.archived ? 'bg-grey-darken-2' : 'bg-green-lighten-1'"
                  class="user-label mr-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                >
                  Owner
                </span>
                <span
                  v-if="user.is_coordinator"
                  :class="user.archived ? 'bg-grey-darken-2' : 'bg-blue-lighten-2'"
                  class="user-label mr-2 text-uppercase font-weight-600 text-white rounded-pill pl-2 pr-2 mt-1 mb-1"
                >
                  Coordinator
                </span>
              </div>
            </div>
          </div>
          <div v-if="thread.dispatcher" class="mt-2 claimed-block">
            <span v-if="thread.resolved" class="text-grey-darken-3">
              {{ $t("chats.resolved") }}
            </span>
            <span v-else class="text-grey-darken-3">
              {{ $t("chats.claimed") }}
            </span>
            <span class="ml-1 rounded claim-user bg-indigo-lighten-5 text-grey-darken-3">
              {{ thread.dispatcher.name }}
            </span>
          </div>
        </div>

        <div v-if="thread.id && !thread.user.archived" class="buttons-actions">
          <reclaim-chat-action :thread="thread" />
          <resolve-chat-action :thread="thread" />
          <transfer-chat-action :thread="thread" />
        </div>
        <v-btn
          class="claim-modal--close"
          density="comfortable"
          icon="mdi-close"
          size="small"
          variant="flat"
          @click="$emit('close')"
        >
        </v-btn>
      </div>
    </div>

    <div class="scroll-style light overflow-hidden" style="height: 350px">
      <messages-list v-if="thread.id" :thread="thread" />
    </div>

    <new-message-manager v-if="thread.id" :thread="thread" />
  </v-card>
</template>

<style lang="scss" scoped>
.claim-modal {
  .header__inner {
    padding-right: 50px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  &--close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.square-btn {
  width: 28px;
  height: 28px !important;
}
</style>
