<script>
import DeadheadStop from "@/views/trips/components/DeadheadStop.vue";
import RouteStop from "@/views/trips/components/RouteStop.vue";
import { RouteStopStatus } from "@/data/trip";

export default {
  name: "RouteStops",
  components: { RouteStop, DeadheadStop },
  props: {
    route: Object,
    trip: Object,
  },
  emits: ["change"],
  computed: {
    emptyMiles() {
      return this.route.empty_miles;
    },
    loadedMiles() {
      return Object.values(this.route.distances).reduce((sum, distance) => distance + sum, 0);
    },
    totalDistance() {
      return this.emptyMiles + this.loadedMiles;
    },
    lastActionPerformed() {
      return this.route.route_stops.findLast(
        (s) => s.status !== RouteStopStatus.notStarted && s.status !== RouteStopStatus.inRoute,
      );
    },
    canUndoDeadhead() {
      return this.route.dispatch_confirmed && !this.lastActionPerformed;
    },
  },
  methods: {
    canUndoStop(stop) {
      return this.lastActionPerformed?.id === stop.id;
    },
    onChange() {
      this.$emit("change");
    },
  },
};
</script>

<template>
  <div class="rounded bg-white pa-4 mb-4">
    <div class="font-weight-500 text-grey-darken-2 mb-3">Route stops</div>
    <deadhead-stop :route="route" :trip="trip" :undoable="canUndoDeadhead" @change="onChange"></deadhead-stop>
    <route-stop
      v-for="(stop, index) in route.route_stops"
      :key="stop.id"
      :index="index + 1"
      :last-stop="index === route.route_stops.length - 1"
      :route="route"
      :stop="stop"
      :trip="trip"
      :undoable="canUndoStop(stop)"
      @change="onChange"
    ></route-stop>
    <div class="text-grey-darken-1 font-size-12 mt-1">
      <div class="text-right">
        Empty miles: <span class="font-weight-500">{{ $filters.miles(emptyMiles) }} miles</span>
      </div>
      <div class="text-right">
        Loaded miles: <span class="font-weight-500">{{ $filters.miles(loadedMiles) }} miles</span>
      </div>
      <div class="text-right">
        Total distance: <span class="font-weight-500">{{ $filters.miles(totalDistance) }} miles</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
