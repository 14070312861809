<script>
import TimePicker from "@/components/pickers/TimePicker.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";

export default {
  name: "OrderStopDateTime",
  components: { DatePicker, TimePicker },
  props: {
    modelValue: String,
    timezone: String,
    dateLabel: String,
    timeLabel: String,
    dateQaAttr: String,
    timeQaAttr: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      date: null,
      time: null,
    };
  },
  computed: {
    fullDate() {
      if (this.isValid) {
        return this.date + " " + this.time;
      }
      return null;
    },
    isValid() {
      if (!this.date || !this.time) {
        return false;
      }
      const datetime = this.date + " " + this.time;
      return (
        /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(datetime) && this.$dayjs(datetime, "YYYY-MM-DD HH:mm", true).isValid()
      );
    },
  },
  watch: {
    modelValue() {
      this.setFromModelValue();
    },
    time() {
      this.validateAndEmit();
    },
    date() {
      this.validateAndEmit();
    },
    timezone() {
      this.validateAndEmit();
    },
  },
  mounted() {
    this.setFromModelValue();
  },
  methods: {
    setFromModelValue() {
      if (this.modelValue) {
        const date = this.$dayjs(this.modelValue).tz(this.timezone);
        this.date = date.format("YYYY-MM-DD");
        this.time = date.format("HH:mm");
      }
    },
    validateAndEmit() {
      if (this.fullDate) {
        const emittedDate = this.$dayjs.tz(this.fullDate, this.timezone).utc().toISOString();
        this.$emit("update:modelValue", emittedDate);
      }
    },
  },
};
</script>

<template>
  <v-row>
    <v-col>
      <date-picker
        v-model="date"
        :clearable="false"
        :data-qa="dateQaAttr"
        :disabled="disabled"
        :label="dateLabel || 'Date'"
        :required="required"
      />
    </v-col>
    <v-col>
      <time-picker
        v-model="time"
        :data-qa="timeQaAttr"
        :disabled="!date || disabled"
        :label="timeLabel || 'Time'"
        :required="required"
      />
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>
