<script>
import BaseView from "@/views/BaseView.vue";
import { mapMutations } from "vuex";

export default {
  name: "DispatcherSetPassword",
  mixins: [BaseView],
  data: () => ({
    error: "",
    form: { new_password: "", new_password2: "", token: "" },
    errorPassword: "",
    show_pass: false,
  }),
  computed: {
    email: {
      get() {
        return this.$route.query.email;
      },
    },
  },
  methods: {
    ...mapMutations(["showSnackbarSuccess"]),
    async setPassword() {
      this.form.token = this.$route.query.token;
      if (this.form.new_password.length < 8) {
        this.errorPassword = "Passwords must be not less than 8 characters";
        return;
      }
      if (this.form.new_password !== this.form.new_password2) {
        this.errorPassword = "Passwords must be the same";
        return;
      } else {
        this.errorPassword = "";
      }
      try {
        const resp = await this.$api.auth.setPassword(
          this.$route.params.id,
          this.form.new_password,
          this.form.new_password2,
          this.form.token,
        );
        if (resp.success) {
          this.showSnackbarSuccess(this.$t("auth.password-changed"));
          return this.$router.push({ name: "login" });
        }
      } catch (e) {
        console.error("Error setting a new password", e);
        if (e.data) {
          this.errorPassword = e.data.detail;
        }
      }
    },
  },
};
</script>
<template>
  <v-layout class="setPass-form pa-0 align-center justify-center" full-height>
    <div class="pa-7 setPass-form__inner">
      <div class="setPass-form__top pt-10 pr-6 pb-8 pl-6">
        <v-card variant="flat">
          <div class="mb-8">
            <div class="setPass-form__logo mb-4">
              <img alt="Logo" src="@/assets/images/logo.svg" width="50" />
            </div>
            <div class="setPass-form__title text-h5 text-center font-weight-medium mb-2">
              {{ $t("auth.set-password") }}
            </div>
            <div class="setPass-form__subtitle text-grey-darken-1 text-body-2 text-center mb-0">
              Password must contain at least 8 letters
            </div>
          </div>

          <v-card-text class="pa-0 mb-6">
            <v-text-field
              v-model="email"
              :disabled="true"
              :label="$t('general.email')"
              :readonly="true"
              color="primary"
              density="compact"
              type="email"
              variant="outlined"
            />
            <v-text-field
              v-model="form.new_password"
              :append-inner-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :error-messages="errorPassword"
              :label="$t('auth.new-password')"
              :type="show_pass ? 'text' : 'password'"
              color="primary"
              density="compact"
              height="55"
              hide-details="auto"
              variant="outlined"
              @click:append-inner="show_pass = !show_pass"
            />
          </v-card-text>

          <v-card-text class="pa-0 mb-6">
            <v-text-field
              v-model="form.new_password2"
              :append-inner-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :error-messages="errorPassword"
              :label="$t('auth.new-password')"
              :type="show_pass ? 'text' : 'password'"
              color="primary"
              density="compact"
              height="55"
              hide-details="auto"
              variant="outlined"
              @click:append-inner="show_pass = !show_pass"
            />
          </v-card-text>
          <v-card-actions class="pa-0 d-block">
            <div class="text-center">
              <v-btn
                class="mb-4 width-100 text-uppercase font-weight-600"
                color="primary"
                height="45"
                @click="setPassword"
                >{{ $t("auth.set-password") }}
              </v-btn>
            </div>
            <router-link
              class="d-block pa-1 font-size-12 text-uppercase text-primery text-decoration-none font-weight-600 text-center d-block"
              to="setPass"
            >
              Back to log in
            </router-link>
          </v-card-actions>
        </v-card>

        <!-- Показывать когда успешно изменения -->
        <!--<div class="set-pass">
                          <div class="reset-pass__header mb-8">
                            <div class="reset-pass__icon text-center mb-5">
                              <img src="@/assets/images/check.svg">
                            </div>
                            <div class="headline mb-2 text-center text-grey-darken-3 font-weight-medium">Password changed</div>
                            <div class="text-center text-grey-darken-1">
                              Your password has been successfully changed
                            </div>
                          </div>
                          <v-btn to="login" height="45px" color="primary" class="rounded width-100 text-uppercase text-white text-decoration-none font-weight-600 text-center mb-3">
                            Log in
                          </v-btn>
                        </div> -->
      </div>
    </div>
  </v-layout>
</template>

<style lang="scss" scoped>
.setPass-form__inner {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .v-form > .v-card {
    box-shadow: none !important;
    background: transparent !important;
  }

  .setPass-form__top {
    width: 430px;
    margin: 0 auto;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background: #fff;
  }
}

.setPass-form__logo {
  max-width: 50px;
  margin: 0 auto;
}
</style>

<style lang="scss">
.setPass-form__inner {
  .v-text-field--outlined.v-input--dense .v-label {
    top: 50%;
    transform: translateY(-50%);
  }

  .v-text-field--outlined.v-input--dense .v-label--active {
    top: 0;
    transform: translateY(-8px) scale(0.75);
  }

  .v-input__append-inner {
    align-self: center;
    margin-top: 0 !important;
  }
}
</style>
