<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LinkButton",
  props: {
    id: String,
    color: {
      type: String,
      default: "primary",
    },
    to: {
      type: Object,
      require: true,
    },
  },
  methods: {
    click() {
      this.$router.push(this.to);
    },
  },
});
</script>

<template>
  <v-btn :id="id" :color="color" class="text-uppercase" variant="flat" @click="click">
    <slot></slot>
  </v-btn>
</template>

<style lang="scss" scoped></style>
