<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import EditStopBlock from "@/views/trips/edit/dialogs/EditStopBlock.vue";
import OmniDialog from "@/components/Dialog.vue";
import RouteStopStatusLabel from "../../components/RouteStopStatus.vue";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderStopState } from "@/data/order.js";

export default {
  name: "StopBlock",
  components: { OrderStopStatus, RouteStopStatusLabel, OmniDialog, EditStopBlock },
  mixins: [OrdersMixin],
  props: {
    orderFreights: {
      type: Array,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
    isOrderStop: Boolean,
  },
  emits: ["update:modelValue", "change", "delete"],
  data() {
    return {
      editStopDialog: false,
      item: {},
    };
  },
  computed: {
    freights() {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (this.item.freights.includes(freight.id)) {
          freights.push({ id: freight.id, name: this.getFreightName(freight, "", "", false) });
        }
      }

      return freights;
    },
    freightsList() {
      return this.freights.map((f) => f.name).join(", ");
    },
    isUpdated() {
      return this.item.state === OrderStopState.updated;
    },
  },
  watch: {
    modelValue(value) {
      this.item = { ...value };
    },
  },
  created() {
    this.item = { ...this.modelValue };
  },
  methods: {
    showEditForm() {
      if (this.item.use && !this.item.date_time_to) {
        this.editStopDialog = true;
      }
    },
    update() {
      const stop = { ...this.modelValue, ...this.item };
      this.$emit("update:modelValue", stop);
      this.$emit("change", stop);
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': item.type === STOP_RELOAD }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-checkbox
              v-if="item.editable"
              v-model="item.use"
              class="mr-1 ma-0 pa-0"
              color="primary"
              density="compact"
              hide-details
              @change="showEditForm"
            />
            <v-icon v-if="item.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="item.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="item.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ item.name }}
            </span>
            <router-link
              v-if="item.order"
              :to="{ name: 'order-view', params: { id: item.order.order_id || item.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ item.order.number }}
            </router-link>
            <v-menu v-if="isUpdated" :open-on-hover="true" location="top start" offset="4" width="430">
              <template #activator="{ props }">
                <v-icon class="ml-2" color="warning" icon="mdi-alert-circle-outline" size="20" v-bind="props"></v-icon>
              </template>
              <v-card>
                <v-card-text>
                  <div class="font-size-16 text-grey-darken-3 font-weight-500 mb-4">Changes</div>
                  <v-row v-if="item.$oldFacility">
                    <v-col cols="4">
                      <div class="text-grey-darken-2">Previous facility:</div>
                    </v-col>
                    <v-col cols="8">
                      <div class="mb-2">
                        <router-link
                          :to="{ name: 'facilities-edit', params: { id: item.facility?.id } }"
                          class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
                          data-qa="facility-name"
                          target="_blank"
                        >
                          {{ item.$oldFacility?.name }}
                        </router-link>
                      </div>
                      <div class="text-grey-darken-2 font-size-12">
                        {{ item.$oldFacility?.address_line }},
                        {{ item.$oldFacility?.address }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">Last stop editor:</v-col>
                    <v-col cols="8">
                      <div class="font-weight-medium text-primary">Esther Howard</div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div>
            <v-btn
              v-if="item.type === STOP_RELOAD"
              class="text-red text--accent-2 bg-transparent"
              elevation="0"
              icon="mdi-delete-outline"
              size="x-small"
              @click="$emit('delete')"
            />
            <v-btn
              v-if="item.editable && item.use"
              class="text-primary bg-transparent"
              elevation="0"
              icon="mdi-pencil-outline"
              size="x-small"
              @click="editStopDialog = true"
            />
          </div>
          <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
        </div>
        <div v-if="item.use">
          <order-stop-status v-if="isOrderStop" :stop="item"></order-stop-status>
          <route-stop-status-label v-else :stop="item"></route-stop-status-label>
        </div>
      </div>

      <div v-if="item.use" class="mb-6 trip-stops__block-body">
        <div class="mb-5">
          <div class="d-flex align-center mb-2">
            <router-link
              :to="{ name: 'facilities-edit', params: { id: item.facility?.id } }"
              class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
              data-qa="facility-name"
              target="_blank"
            >
              {{ item.facility?.name }}
            </router-link>
            <div v-if="item.old_facility_id" class="indicator ml-1"></div>
          </div>
          <div class="text-grey-darken-2" data-qa="facility-address">
            {{ item.facility?.address_line }}, {{ item.facility?.address }}
          </div>
        </div>
        <div v-if="item.time_type && !item.is_reload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[item.time_type] }}: {{ getStopTime(item) }}</span
          >
        </div>
      </div>
      <div v-if="item.use" class="trip-stops__block-footer">
        <div v-if="!item.editable" class="d-flex">
          <span class="text-grey-darken-1 mr-2">Freight:</span>
          <span
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            {{ freightsList }}
          </span>
        </div>
        <div v-else-if="freights.length > 0">
          <v-autocomplete
            v-if="item.type === STOP_PICK_UP"
            v-model="item.freights"
            :items="freights"
            :multiple="true"
            class="required"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            label="Freight"
            variant="outlined"
          />
          <v-select
            v-if="item.type === STOP_DELIVERY"
            v-model="item.freights"
            :items="freights"
            :multiple="true"
            class="required"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            label="Freight"
            variant="outlined"
          />
        </div>
      </div>

      <div v-else class="flex-1 trip-stops__block-empty">
        <div class="empty-content height-100 d-flex flex-column align-center justify-center">
          <div class="empty-content__img mb-3 line-height-1">
            <img alt="" src="../../../../assets/images/empty-stop.svg" />
          </div>
          <div class="empty-content__text text-grey subheading">Not used in this route</div>
        </div>
      </div>
    </div>
    <omni-dialog
      v-model="editStopDialog"
      :title="'Edit route stop - ' + modelValue.name"
      primary-action-label="Save"
      width="830"
      @primary-action:click="editStopDialog = false"
    >
      <edit-stop-block v-model="item" />
    </omni-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.trip-stops__block-inner {
  min-height: 290px;
}

.reload-stop .trip-stops__block-inner {
  min-height: 610px;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}

.indicator {
  position: relative;

  &:after {
    position: absolute;
    top: -6px;
    content: "·";
    color: $orange;
    font-size: 52px;
    height: 12px;
    line-height: 10px;
  }
}
</style>
