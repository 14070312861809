<script>
export default {
  name: "SearchField",
  props: {
    modelValue: String,
    label: String,
    mask: String,
    suffix: String,
    name: String,
    appendIcon: String,
    type: {
      type: String,
      default: "text",
    },
  },
  emits: ["update:modelValue", "search"],
  data() {
    return {
      model: null,
      options: {
        mask: this.mask,
        eager: true,
      },
    };
  },
  watch: {
    model() {
      this.$emit("update:modelValue", this.model);
    },
    modelValue() {
      this.model = this.modelValue;
    },
  },
  created() {
    this.model = this.modelValue;
  },
  methods: {
    search() {
      this.$emit("search");
    },
    cleared() {
      this.$emit("update:modelValue", null);
      this.search();
    },
  },
};
</script>

<template>
  <v-text-field
    v-model="model"
    v-maska:[options]
    :append-inner-icon="appendIcon"
    :clearable="true"
    :label="label"
    :name="name"
    :suffix="suffix"
    :type="type"
    autocomplete="off"
    color="primary"
    density="compact"
    hide-details
    role="presentation"
    variant="outlined"
    @keyup.enter="search"
    @click:clear="cleared"
  />
</template>

<style scoped></style>
