import { BaseApi } from "@/api/base";

export class CompanyApi extends BaseApi {
  getProfile() {
    return this.client.get("/company", {});
  }

  findCarriers(options = {}) {
    return this.client.get("/carriers", options);
  }

  findCarrierById(carrier_id) {
    return this.client.get("/carriers/" + carrier_id);
  }

  async upsertCarrier(carrier) {
    return this.client.save("/carriers", carrier);
  }

  findCustomers(options = {}) {
    return this.client.get("/customers", options);
  }

  findCustomerById(customer_id) {
    return this.client.get("/customers/" + customer_id);
  }

  async upsertCustomer(customer) {
    return this.client.save("/customers", customer);
  }

  findFacilities(options = {}) {
    return this.client.get("/facilities", options);
  }

  findFacilityById(facility_id) {
    return this.client.get("/facilities/" + facility_id);
  }

  async upsertFacility(facility) {
    return this.client.save("/facilities", facility);
  }
}
