<script>
import Zoomist from "zoomist";

const draggingClass = "dragging";

export default {
  name: "ImageViewer",
  props: {
    data: String,
  },
  data() {
    return {
      resizeObserver: null,
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries[0] && entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0) {
        let initialScale = 1;
        const imageInitialHeight = entries[0].contentRect.height;
        const containerHeight = this.$refs.zoomContainer.offsetHeight;
        if (imageInitialHeight > containerHeight) {
          initialScale = containerHeight / imageInitialHeight;
        }
        new Zoomist(".zoomist-container", {
          initScale: initialScale,
          maxScale: 4,
          minScale: 0.1,
          bounds: false,
          on: {
            dragStart: () => {
              this.$refs.zoomContainer.classList.add(draggingClass);
            },
            dragEnd: () => {
              this.$refs.zoomContainer.classList.remove(draggingClass);
            },
          },
        });
        this.resizeObserver.unobserve(this.$refs.zoomImage);
      }
    });

    this.resizeObserver.observe(this.$refs.zoomImage);
  },
};
</script>

<template>
  <div ref="zoomContainer" class="zoomist-container">
    <div class="zoomist-wrapper">
      <div ref="zoomImage" class="zoomist-image">
        <div class="zoomist-overlay"></div>
        <img :src="data" alt="big image" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.zoomist-container {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &.dragging {
    cursor: grabbing;
  }
}

.zoomist-image {
  position: relative;

  img {
    max-width: 1024px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .zoomist-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    user-select: none;
  }
}
</style>
