<script>
export default {
  name: "AlertMessage",
  props: {
    bgColor: String,
  },
  computed: {
    backgroundClass() {
      return `bg-${this.bgColor || "amber-lighten-5"}`;
    },
  },
};
</script>

<template>
  <div
    :class="backgroundClass"
    class="rounded text-grey-darken-3 border-warning pa-2 d-flex align-center font-size-12 font-weight-500"
  >
    <v-icon color="warning" icon="mdi-alert-circle-outline" size="20"></v-icon>
    <div class="ml-1">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
