<script>
import { defineComponent } from "vue";
import UsersMixin from "@/views/users/UsersMixin.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import BaseView from "@/views/BaseView.vue";
import SearchField from "@/components/inputs/SearchField.vue";
import UserSelect from "@/views/users/components/UserSelect.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import OmniSelect from "@/components/controls/Select.vue";

const DriverDocument = {
  license: "license",
  certificate: "certificate",
  citizenship: "citizenship",
};

export default defineComponent({
  name: "DriversSearchForm",
  components: { OmniSelect, TableFilterRow, TableFilter, SearchField, DatePicker, UserSelect },
  mixins: [BaseView, UsersMixin],
  emits: ["search"],
  data() {
    return {
      params: {},
      documentType: null,
      showAdditionalFilters: false,
    };
  },
  computed: {
    documentTypes() {
      return [
        {
          name: this.$t("drivers.driver-license"),
          type: DriverDocument.license,
          value: "driver_license",
        },
        { divider: true },
        { header: this.$t("drivers.certificates") },
        {
          name: this.$t("drivers.twic"),
          type: DriverDocument.certificate,
          value: "certificate_twic",
        },
        {
          name: this.$t("drivers.tsa"),
          type: DriverDocument.certificate,
          value: "certificate_tsa",
        },
        {
          name: this.$t("drivers.tanker"),
          type: DriverDocument.certificate,
          value: "certificate_tanker",
        },
        {
          name: this.$t("drivers.hazmat"),
          type: DriverDocument.certificate,
          value: "certificate_hazmat",
        },
        { header: this.$t("drivers.citizenship") },
        ...this.citizenships.map((c) => {
          c.type = DriverDocument.citizenship;
          c.value = c.id;
          return c;
        }),
      ];
    },
    isDocumentSelected() {
      return this.documentType !== null;
    },
  },
  created() {
    this.params = this.getSearchParams("drivers");
    this.search();
  },
  methods: {
    clear() {
      this.documentType = null;
      this.params = {};
      this.search();
    },
    search() {
      this.setSearchParams("drivers", this.params);
      this.$emit("search", this.params);
    },
    documentChanged(doc) {
      this.clearDocument();
      if (doc) {
        if (doc.type === DriverDocument.license || doc.type === DriverDocument.certificate) {
          this.params[doc.value] = true;
        }
        if (doc.type === DriverDocument.citizenship) {
          this.params[doc.type] = doc.value;
        }
      }
      this.search();
    },
    clearDocument() {
      this.documentTypes.forEach((doc) => {
        if (doc.type === DriverDocument.license || doc.type === DriverDocument.certificate) {
          delete this.params[doc.value];
        }
        if (doc.type === DriverDocument.citizenship) {
          delete this.params[doc.type];
        }
      });
    },
  },
});
</script>

<template>
  <table-filter @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col>
          <search-field
            id="search--name-field"
            v-model="params.name"
            :label="$t('general.full-name')"
            @search="search"
          />
        </v-col>
        <v-col>
          <v-select
            id="search--status-select"
            v-model="params.status"
            :clearable="true"
            :items="driverStatuses"
            :label="$t('general.status')"
            color="primary"
            density="compact"
            hide-details
            item-title="name"
            item-value="id"
            variant="outlined"
            @change="search"
          />
        </v-col>
        <v-col>
          <user-select
            id="search--owner-select"
            v-model="params.owner"
            :label="$t('users.owner')"
            owners
            @change="search"
          />
        </v-col>
        <v-col>
          <search-field id="search--truck-field" v-model="params.truck" :label="$t('drivers.truck')" @search="search" />
        </v-col>
        <v-col>
          <search-field
            id="search--phone-field"
            v-model="params.phone"
            :label="$t('general.phone')"
            mask="1 ### ### ####"
            @search="search"
          />
        </v-col>
        <v-col>
          <search-field
            id="search--email-field"
            v-model="params.email"
            :label="$t('general.email')"
            type="email"
            @search="search"
          />
        </v-col>
      </table-filter-row>
    </template>
    <table-filter-row :label="$t('general.address')" icon="mdi-map-marker-radius-outline">
      <v-col>
        <search-field id="search--city-field" v-model="params.city" :label="$t('general.city')" @search="search" />
      </v-col>
      <v-col>
        <search-field id="search--state-field" v-model="params.state" :label="$t('general.state')" @search="search" />
      </v-col>
      <v-col>
        <search-field
          id="search--zip-field"
          v-model="params.zip"
          :label="$t('general.zip')"
          type="number"
          @search="search"
        />
      </v-col>
    </table-filter-row>
    <table-filter-row :divider="false" :label="$t('general.documents')" icon="mdi-badge-account-horizontal-outline">
      <v-col>
        <omni-select
          id="search--document-select"
          v-model="documentType"
          :clearable="true"
          :items="documentTypes"
          :label="$t('general.type')"
          item-value="value"
          return-object
          @change="documentChanged"
        >
        </omni-select>
      </v-col>
      <v-col>
        <date-picker
          v-if="isDocumentSelected"
          id="search--document-expiration-date"
          v-model="params.document_expiration_date_to"
          :label="$t('general.expiration-date')"
        />
      </v-col>
    </table-filter-row>
  </table-filter>
</template>
