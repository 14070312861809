<script>
import UserStatus from "@/components/status/UserStatus.vue";
import OwnerCompany from "@/views/users/components/OwnerCompany.vue";

export default {
  name: "UserViewOwnerInfo",
  components: { OwnerCompany, UserStatus },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    owner() {
      const owner = { ...this.user };
      owner.coordinators = this.user.coordinators || [];
      owner.companies = this.user.companies || [];
      return owner;
    },
  },
};
</script>

<template>
  <div v-if="owner.is_owner" class="user-block pt-5 pr-5 pb-7 pl-5 bg-white rounded mt-4 mb-2">
    <div class="text-grey-darken-3 mb-6 font-weight-medium">Owner info</div>
    <v-row>
      <div class="label">
        <span class="text-grey-darken-1">Status</span>
      </div>
      <div class="description">
        <user-status :muted="owner.archived" :status="owner.owner_status"></user-status>
      </div>
    </v-row>
    <v-row v-if="owner.coordinators.length > 0">
      <div class="label">
        <span class="text-grey-darken-1">Coordinators</span>
      </div>
      <div class="description">
        <div v-for="coordinator in owner.coordinators" :key="coordinator.user_id" class="d-flex mb-2">
          <router-link
            :to="{ name: 'user-view', params: { id: coordinator.user?.id } }"
            class="text-primary text-decoration-none"
          >
            {{ coordinator.user?.name }}
          </router-link>
          <div v-if="coordinator.full_access" class="text-green ml-3">
            <v-icon size="small">mdi-check</v-icon>
            Full access
          </div>
        </div>
      </div>
    </v-row>
    <v-row v-if="owner.companies.length > 0">
      <div class="label">
        <span class="text-grey-darken-1">Company</span>
      </div>
      <div class="description">
        <v-row>
          <v-col v-for="company in owner.companies" :key="company.id" lg="4">
            <owner-company :company="company"></owner-company>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>
