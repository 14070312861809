<script>
export default {
  name: "RemoveOrderDialog",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <div class="my-6 text-grey-darken-1 pa-0">
    Are you sure want to remove <span class="text-primary">Order#{{ order.number }}</span> from the trip?
  </div>
</template>
