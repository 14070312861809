<script>
export default {
  name: "UserFiles",
};
</script>

<template>
  <div>
    <div class="user-file__header mb-4 d-flex align-center">
      <div class="title text-grey-darken-3 font-weight-medium mr-6">Files</div>
      <v-btn class="text-uppercase font-weight-600" color="primary" variant="flat"> Upload</v-btn>
    </div>

    <div class="user-file__blocks">
      <v-row>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/file.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-file-document-outline</v-icon>
              <span class="text-grey-darken-4">Sample.pdf</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/screen.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-image-outline</v-icon>
              <span class="text-grey-darken-4 user-file__name">Снимок экрана за 11-2022.png</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/file.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-file-document-outline</v-icon>
              <span class="text-grey-darken-4">Sample.pdf</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/screen.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-image-outline</v-icon>
              <span class="text-grey-darken-4 user-file__name">Снимок экрана за 11-2022.png</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/file.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-file-document-outline</v-icon>
              <span class="text-grey-darken-4">Sample.pdf</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/screen.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-image-outline</v-icon>
              <span class="text-grey-darken-4 user-file__name">Снимок экрана за 11-2022.png</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/file.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-file-document-outline</v-icon>
              <span class="text-grey-darken-4">Sample.pdf</span>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="6" xl="2">
          <div class="user-file__block rounded">
            <div class="user-file__block-header bg-white rounded-b-0">
              <img alt="" class="width-100 rounded rounded-b-0" src="@/assets/images/screen.png" />
            </div>
            <div class="user-file__block-desc d-flex align-center pt-4 pr-6 pb-4 pl-4 bg-white rounded rounded-t-0">
              <v-icon class="mr-2" color="primary" size="20"> mdi-image-outline</v-icon>
              <span class="text-grey-darken-4 user-file__name">Снимок экрана за 11-2022.png</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.user-file__block {
  border: 1px solid #e0e0e0;
}
</style>
