<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "OmniSnackbar",
  computed: {
    ...mapState(["snackbar"]),
  },
  methods: {
    ...mapMutations(["closeSnackBar"]),
  },
};
</script>

<template>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    location="bottom right"
    multi-line
    variant="flat"
  >
    {{ snackbar.text }}
    <template #actions>
      <v-icon class="cursor-pointer" @click="closeSnackBar()">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<style lang="scss" scoped>
:deep(.v-snackbar__actions) {
  align-self: start;
  margin-top: 8px;
}
</style>
