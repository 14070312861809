const sidebarCollapsedField = "sidebarCollapsed";

class Storage {
  getSidebarCollapsed() {
    return this.getBooleanItem(sidebarCollapsedField, null);
  }

  setSidebarCollapsed(value) {
    this.setBooleanItem(sidebarCollapsedField, value);
  }

  getBooleanItem(key, defaultValue) {
    let value = this.getItem(key, defaultValue);
    switch (value) {
      case "null":
        value = null;
        break;
      case "true":
        value = true;
        break;
      case "false":
        value = false;
        break;
      default:
        value = defaultValue;
        break;
    }
    return value;
  }

  setBooleanItem(key, value) {
    switch (value) {
      case null:
        value = "null";
        break;
      case true:
        value = "true";
        break;
      case false:
        value = "false";
        break;
    }
    this.setItem(key, value);
  }

  getItem(key, defaultValue = null) {
    return localStorage.getItem(key) || defaultValue;
  }

  setItem(key, value) {
    localStorage.setItem(key, value);
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }
}

export default {
  install(app) {
    app.config.globalProperties.$storage = new Storage();
  },
};
