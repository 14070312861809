export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const chatCodes = {
  TOKEN_EXPIRED: 40,
  WS_CLOSED_SUCCESS: 1000,
};

/**
 * Change value to "info" in order to switch on debug logging.
 *
 * @type {string}
 */
export const DEFAULT_LOG_LEVEL = "none";

/**
 * retryInterval - A retry interval which increases acc to number of failures
 *
 * @return {number} Duration to wait in milliseconds
 */
export function retryInterval(numberOfFailures) {
  // try to reconnect in 0.25-25 seconds (random to spread out the load from failures)
  const max = Math.min(500 + numberOfFailures * 2000, 25000);
  const min = Math.min(Math.max(250, (numberOfFailures - 1) * 2000), 25000);
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomId() {
  return generateUUIDv4();
}

function hex(bytes) {
  let s = "";
  for (let i = 0; i < bytes.length; i++) {
    s += bytes[i].toString(16).padStart(2, "0");
  }
  return s;
}

// https://tools.ietf.org/html/rfc4122
export function generateUUIDv4() {
  const bytes = getRandomBytes(16);
  bytes[6] = (bytes[6] & 0x0f) | 0x40; // version
  bytes[8] = (bytes[8] & 0xbf) | 0x80; // variant

  return (
    hex(bytes.subarray(0, 4)) +
    "-" +
    hex(bytes.subarray(4, 6)) +
    "-" +
    hex(bytes.subarray(6, 8)) +
    "-" +
    hex(bytes.subarray(8, 10)) +
    "-" +
    hex(bytes.subarray(10, 16))
  );
}

function getRandomValuesWithMathRandom(bytes) {
  const max = Math.pow(2, (8 * bytes.byteLength) / bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = Math.random() * max;
  }
}

const getRandomValues = (() => {
  if (typeof crypto !== "undefined" && typeof crypto?.getRandomValues !== "undefined") {
    return crypto.getRandomValues.bind(crypto);
  } else {
    return getRandomValuesWithMathRandom;
  }
})();

function getRandomBytes(length) {
  const bytes = new Uint8Array(length);
  getRandomValues(bytes);
  return bytes;
}

/**
 * isOnline safely return the navigator.online value for browser env
 * if navigator is not in global object, it always returns true
 */
export function isOnline() {
  const nav =
    typeof navigator !== "undefined"
      ? navigator
      : typeof window !== "undefined" && window.navigator
      ? window.navigator
      : undefined;

  if (!nav) {
    console.warn("isOnline failed to access window.navigator and assume browser is online");
    return true;
  }

  // RN navigator has undefined for onLine
  if (typeof nav.onLine !== "boolean") {
    return true;
  }

  return nav.onLine;
}

/**
 * listenForConnectionChanges - Adds an event listener fired on browser going online or offline
 */
export function addConnectionEventListeners(cb) {
  if (typeof window !== "undefined" && window.addEventListener) {
    window.addEventListener("offline", cb);
    window.addEventListener("online", cb);
  }
}

export function removeConnectionEventListeners(cb) {
  if (typeof window !== "undefined" && window.removeEventListener) {
    window.removeEventListener("offline", cb);
    window.removeEventListener("online", cb);
  }
}
